import React from 'react';

import { Link, LinkPlaceholder } from './LinkNodes';
import LinkTooltip from './LinkTooltip';
import { hasBlocks } from '../SlateUtils';
import AddLinkEditorHandler from './AddLinkEditorHandler';

export const AddLinkPluginContext = React.createContext();

export class AddLinkPluginProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      linkTooltipActive: false,
      prevSelection: null,
      showEditLinkView: true
    };
    this.getCurrentLinkData = this.getCurrentLinkData.bind(this);
    this.setLinkTooltipActive = this.setLinkTooltipActive.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.onLinkFormBlur = this.onLinkFormBlur.bind(this);
    this.onLinkFormSubmit = this.onLinkFormSubmit.bind(this);
    this.onClickEditButton = this.onClickEditButton.bind(this);
    this.onClickLinkButton = this.onClickLinkButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    const hasLink =
      this.props.value.inlines.filter(inline => inline.type === 'link').size ===
      1;
    const prevHasLink =
      prevProps.value.inlines.filter(inline => inline.type === 'link').size ===
      1;
    const differentSelection =
      this.props.value.selection !== prevProps.value.selection;

    // When we call focus after submitting a new link or an updated link, the editor jumps around.
    // Reset the focus if the editor is newly focused and this.state.scroll has a value (it will only
    // have a value if this.onLinkFormSubmit() is called)
    if (
      this.props.value.selection.isFocused &&
      !prevProps.value.selection.isFocused &&
      this.state.scroll
    ) {
      window.scrollTo(0, this.state.scroll);
      this.setState({ scroll: null });
      return;
    }

    // If the focus was on a link and is no longer, make sure the tooltip is not displayed
    if (!hasLink && prevHasLink) {
      this.setState({
        linkTooltipActive: false,
        prevSelection: {}
      });
      return;
    }

    // Show the tooltip on the tooltip actions view if the current selection has one
    // link and the selection is different than the previous selection
    if (hasLink && differentSelection && this.props.value.selection.isFocused) {
      this.setState({
        linkTooltipActive: true,
        prevSelection: this.props.value.selection,
        errorMessage: '',
        showEditLinkView: false
      });
      return;
    }
  }

  showErrorMessage(message) {
    this.setState({
      errorMessage: message
    });
    setTimeout(() => {
      this.setState({
        errorMessage: ''
      });
    }, 3000);
  }

  setLinkTooltipActive() {
    this.setState({
      linkTooltipActive: true,
      prevSelection: this.props.editor.value.selection,
      errorMessage: '',
      showEditLinkView: true
    });
    this.props.editorHandler.setLinkTooltipActive(this.props.editor);
  }

  unwrapLink() {
    this.props.editorHandler.unwrapLink(this.props.editor);
  }

  /**
   * Called when the link form is blurred. This can be from clicking outside of the form, hitting the
   * escape key. resetRange is can be set in case it makes sense for the particular case of the form being
   * blurred to have the focus reset.
   * @param {*} e
   * @param {*} resetRange
   */
  onLinkFormBlur(e, resetRange = true) {
    const { prevSelection } = this.state;
    this.setState({
      linkTooltipActive: false,
      prevSelection: {},
      scroll: window.scrollY
    });
    this.props.editorHandler.blurLinkForm(
      this.props.editor,
      resetRange,
      prevSelection
    );
  }

  /**
   * When a user submits the link form, remove the link form tooltip, remove the link-placeholder
   * wrapping, re-select the highlighted text, wrap the text in the link, and move the cursor to the end
   * of the text
   * @param {*} e
   * @param {*} linkData
   */
  onLinkFormSubmit(e, linkData) {
    const { prevSelection } = this.state;
    if (e) e.preventDefault();
    this.setState({
      linkTooltipActive: false,
      prevSelection: {},
      scroll: window.scrollY
    });
    this.props.editorHandler.submitLinkForm(
      this.props.editor,
      linkData,
      prevSelection
    );
  }

  getCurrentLinkData() {
    return AddLinkEditorHandler.getCurrentLinkData(this.props.editor);
  }

  /**
   * Click handler for any button that wants to activate the link form or unwrap text that's
   * surrounded by a link
   */
  onClickLinkButton = event => {
    event.preventDefault();
    const { value } = this.props.editor;

    if (hasBlocks(this.props.editor)) {
      this.showErrorMessage('Links cannot span blocks of text!');
    } else if (this.props.editorHandler.hasLink(this.props.editor)) {
      this.unwrapLink();
    } else if (this.state.linkTooltipActive) {
      this.props.editorHandler.blurLinkForm(this.props.editor, false);
    } else if (value.selection.isExpanded) {
      this.setLinkTooltipActive();
    } else {
      this.showErrorMessage('Highlight some text first!');
    }
  };

  /**
   * Handles the click event of the "Edit URL" button in the LinkTooltip
   */
  onClickEditButton = () => {
    this.setState({
      showEditLinkView: true,
      prevSelection: this.props.editor.value.selection
    });
  };

  render() {
    const {
      state,
      getCurrentLinkData,
      showErrorMessage,
      setLinkTooltipActive,
      onLinkFormBlur,
      onLinkFormSubmit,
      onClickEditButton,
      onClickLinkButton
    } = this;

    const addLinkPlugin = {
      ...state,
      formatLink: this.props.editorHandler.formatLink,
      getCurrentLinkData,
      showErrorMessage,
      setLinkTooltipActive,
      onLinkFormBlur,
      onLinkFormSubmit,
      onClickEditButton,
      onClickLinkButton,
      isButtonActive: this.props.editorHandler.hasLink(this.props.editor)
    };

    return (
      <AddLinkPluginContext.Provider value={{ addLinkPlugin }}>
        {this.props.children}
      </AddLinkPluginContext.Provider>
    );
  }
}

export default function AddLinkPlugin() {
  return {
    renderInline: (props, editor, next) => {
      switch (props.node.type) {
        case 'link':
          return <Link {...props} />;
        case 'link-placeholder':
          return <LinkPlaceholder {...props} />;
        default:
          return next();
      }
    },
    renderEditor: (props, editor, next) => {
      const children = next();
      return (
        <AddLinkPluginProvider
          value={props.value}
          editor={editor}
          editorHandler={AddLinkEditorHandler}
        >
          {children}
          <LinkTooltip selection={props.value.selection} />
        </AddLinkPluginProvider>
      );
    }
  };
}

export function AddLinkPluginReadonly() {
  return {
    renderInline: (props, editor, next) => {
      switch (props.node.type) {
        case 'link':
          return <Link {...props} />;
        case 'link-placeholder':
          return <LinkPlaceholder {...props} />;
        default:
          return next();
      }
    }
  };
}
