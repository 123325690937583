import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe
} from 'react-stripe-elements';
import { Transition, animated, config } from 'react-spring/renderprops.cjs';

import { Button } from 'components/common/buttons';
import { FlexRight } from 'components/layouts';

import './PaymentForm.css';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this._paymentFormSubmit = this._paymentFormSubmit.bind(this);
  }

  state = {
    errorMessage: ''
  };

  _paymentFormSubmit(e) {
    e.preventDefault();
    const { email, processPayment, stripe } = this.props;
    stripe
      .createToken({ email })
      .then(({ token, error }) => {
        if (error) {
          this.setState({ errorMessage: error.message });
          return;
        }
        processPayment({ token });
      })
      .catch(e => {
        this.setState({
          errorMessage: 'There was an issue processing your payment'
        });
      });
  }

  render() {
    const { goBack } = this.props;
    const { errorMessage } = this.state;
    return (
      <div className="sign-up-fields-container">
        <form
          className="sign-up-fields-form"
          onSubmit={this._paymentFormSubmit}
        >
          <h2>Payment information</h2>
          <div className="payment-form-row">
            <label>
              Card Number
              <CardNumberElement autoFocus />
            </label>
          </div>
          <div className="payment-form-row">
            <label>
              Exp date
              <CardExpiryElement />
            </label>
            <label>
              CVC
              <CardCVCElement />
            </label>
            <label>
              Zip Code
              <PostalCodeElement />
            </label>
          </div>
          <Transition
            native
            items={errorMessage}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
            config={config.slow}
          >
            {errorMessage =>
              errorMessage &&
              (styles => (
                <animated.h3
                  style={{ ...styles }}
                  className="payment-error-message"
                >
                  {errorMessage}
                </animated.h3>
              ))
            }
          </Transition>
          <FlexRight className="sign-up-fields-form-button-container">
            <Button className="sign-up-next" type="submit">
              Finish
            </Button>
            <Button
              btnStyle="secondary"
              className="sign-up-previous"
              onClick={e => goBack()}
            >
              Previous
            </Button>
          </FlexRight>
        </form>
      </div>
    );
  }
}

export default injectStripe(PaymentForm);
