import gql from 'graphql-tag';

export const UPDATE_SELECTED_SERVICE = gql`
  mutation updateSelectedService($index: Int!, $id: ID!) {
    updateSelectedService(index: $index, id: $id) @client
  }
`;

export const UPDATE_CREATE_BULLETIN_MODAL = gql`
  mutation updateCreateBulletinModal(
    $isOpen: Bool!
    $replicate: Bool
    $originalBulletinId: String
  ) {
    updateCreateBulletinModal(
      isOpen: $isOpen
      replicate: $replicate
      originalBulletinId: $originalBulletinId
    ) @client
  }
`;

export const UPDATE_SERVICE_SETTINGS_MODAL = gql`
  mutation updateServiceSettingsModal($isOpen: Bool!) {
    updateServiceSettingsModal(isOpen: $isOpen) @client
  }
`;

export const UPDATE_VIEW_BULLETIN_MODAL = gql`
  mutation updateViewBulletinModal($isOpen: Bool!, $bulletinId: String!) {
    updateViewBulletinModal(isOpen: $isOpen, bulletinId: $bulletinId) @client
  }
`;
