import React from 'react';

export default class Validator extends React.Component {
  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetForm = this.resetForm.bind(this);

    this.initializeFieldValidAttribute();

    this.state = {
      validateCalled: false, // If validate has been called, then we'll live update fields with validation
      valid: true,
      fields: this.props.formConfig.fields
    };
  }

  initializeFieldValidAttribute() {
    const fields = this.props.formConfig.fields;
    for (let field in fields) {
      if (fields[field].valid === undefined) {
        fields[field].valid = true;
      }
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    this.setState((prevState, props) => {
      let fields = Object.assign({}, prevState.fields);
      fields[name].value = value;
      if (this.state.validateCalled) {
        this.validateField(name);
      }
      return { fields };
    });
  }

  componentDidUpdate() {
    if (this.validating) {
      this.validating = false;
      let invalidFields = 0;
      for (let field in this.state.fields) {
        if (!this.validateField(field)) {
          invalidFields++;
        }
      }
      this.setState({
        validateCalled: true,
        valid: invalidFields === 0,
        fields: this.state.fields
      });
    }
  }

  validate() {
    // On call to validate, clean the form and then mark that we're validating
    // Then in the componentDidUpdate method, we'll actually validate and show the validations
    // This cleanup strategy allows for the errors to be re-read if a user submits a form with that already
    // had errors
    this.validating = true;
    let invalidFields = 0;

    for (let field in this.state.fields) {
      if (!this.validateField(field)) {
        invalidFields++;
      }
    }

    for (let field in this.state.fields) {
      this.markFieldValid(field);
    }

    this.setState({
      valid: true,
      fields: this.state.fields
    });

    return invalidFields === 0;
  }

  validateField(fieldName) {
    let currentField = this.state.fields[fieldName];
    this.markFieldValid(fieldName);
    currentField.validations.every(validation => {
      if (
        (validation.type === 'required' &&
          !this.checkRequired(currentField.value, validation)) ||
        (validation.type === 'min' &&
          !this.checkMin(currentField.value, validation)) ||
        (validation.type === 'match' &&
          !this.checkMatch(currentField.value, validation)) ||
        (validation.type === 'pattern' &&
          !this.checkPattern(currentField.value, validation)) ||
        (validation.validatorFn &&
          !validation.dynamicMessages &&
          !validation.validatorFn(currentField.value))
      ) {
        this.markFieldInvalid(fieldName, validation);
      } else if (validation.validatorFn && validation.dynamicMessages) {
        validation.message = validation.validatorFn(currentField.value);
        if (validation.message) {
          this.markFieldInvalid(fieldName, validation);
        }
      }
      return currentField.valid;
    });
    return currentField.valid;
  }

  markFieldValid(fieldName) {
    let currentField = this.state.fields[fieldName];
    currentField.valid = true;
    currentField.errorMessage = '';
  }

  markFieldInvalid(fieldName, validation) {
    let currentField = this.state.fields[fieldName];
    currentField.valid = false;
    currentField.errorMessage = validation.message;
  }

  resetForm() {
    this.setState((prevState, props) => {
      // resetForm() can be called after validate(), and React might not update Validator
      // after each method call. So, to make sure componentDidUpdate doesn't run our validation
      // code unnecessarily, explicitly set validating to false when we call resetForm()
      this.validating = false;
      let fields = Object.assign({}, prevState.fields);
      for (let field in fields) {
        fields[field].value = '';
      }
      return {
        validateCalled: false,
        valid: true,
        fields
      };
    });
  }

  checkRequired(value, validation) {
    return value !== '' && value !== undefined && value !== null;
  }

  checkMin(value, validation) {
    return value >= validation.value;
  }

  checkMatch(value, validation) {
    return value === this.state.fields[validation.value].value;
  }

  checkPattern(value, validation) {
    return value.match(validation.value);
  }

  render() {
    const { validate, handleChange, resetForm, state } = this;
    return this.props.children({
      validate,
      handleChange,
      resetForm,
      form: state
    });
  }
}
