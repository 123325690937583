import React from 'react';
import PieGraph from './PieGraph';
import './ViewsAndResponses.css';

class ViewsAndResponses extends React.Component {
  render() {
    return (
      <div className="tile viewsAndResponses">
        <h3>Views & Responses</h3>
        <div className="pie">
          <PieGraph views={230} responses={158} max={250} />
        </div>
      </div>
    );
  }
}

export default ViewsAndResponses;
