import React, { useState, useEffect } from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

const ListenForFeatureToggle = ({ children, featureToggleName, firebase }) => {
  const [loading, setLoading] = useState(true);
  const [featureEnabled, setFeatureEnabled] = useState(false);

  useEffect(() => {
    return firebase.db
      .collection('feature_toggles')
      .doc(featureToggleName)
      .onSnapshot(doc => {
        setFeatureEnabled(doc.data().enabled);

        if (loading) {
          setLoading(false);
        }
      });
  }, [loading, featureToggleName, firebase]);

  return featureEnabled && !loading ? children : null;
};

export const FeatureToggleListener = withFirebase(ListenForFeatureToggle);

export default FirebaseContext;
