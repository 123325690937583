import React from 'react';

import Image from './ImageNode';
import ImageReadonly from './ImageNodeReadonly';
import AddImagePluginProvider from './AddImagePluginProvider';
import { getSpacingClassname } from '../BulletinSpaceComputer';

export default function AddImagePlugin() {
  return {
    renderBlock: (props, editor, next) => {
      switch (props.node.type) {
        case 'image':
          const spacingClassname = getSpacingClassname(editor, props.node);
          return (
            <div className={spacingClassname} {...props.attributes}>
              <Image {...props} editor={editor} />
            </div>
          );
        default:
          return next();
      }
    },
    renderEditor: (props, editor, next) => {
      const children = next();
      return (
        <AddImagePluginProvider
          value={props.value}
          editor={editor}
          bulletinId={props.bulletinId}
        >
          {children}
        </AddImagePluginProvider>
      );
    }
  };
}

export function AddImagePluginReadonly() {
  return {
    renderBlock: (props, editor, next) => {
      switch (props.node.type) {
        case 'image':
          const spacingClassname = getSpacingClassname(editor, props.node);
          return (
            <div className={spacingClassname} {...props.attributes}>
              <ImageReadonly {...props} />
            </div>
          );
        default:
          return next();
      }
    }
  };
}
