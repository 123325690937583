import React from 'react';

import styles from './tile.module.css';

/**
 * Layout component for the inner page container
 * @param {*} props
 */
const Tile = props => {
  let { El, className, withPadding, refFn, ...otherProps } = props;
  let tileClass = withPadding ? 'tileWithPadding' : 'tile';

  return (
    <El
      className={`${styles[tileClass]} ${className}`}
      ref={refFn}
      {...otherProps}
    >
      {props.children}
    </El>
  );
};

Tile.defaultProps = {
  className: '',
  El: 'div',
  withPadding: false
};

export default Tile;
