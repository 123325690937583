import React from 'react';
import { Mutation } from 'react-apollo';

import { UPDATE_PLAN_TIER_MUTATION } from 'services/graphql/mutations';

import AccountForm from '../AccountForm';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import InputDynamic from 'components/common/form/inputs/dynamic/InputDynamic';
import Select from 'components/common/form/inputs/Select';
import { ToastContext } from 'components/common/ToastContainer';

import accountStyles from '../../account.module.css';

export class PlanTierForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    const { planTiers } = this.props;

    const planTierID = this.planTierInput.value;
    const {
      displayName,
      pricePerMonth,
      congregantsMin,
      congregantsMax
    } = planTiers.filter(option => option.id === planTierID)[0];
    const id = this.props.acctData.adminOf.id;
    let updateResponse;

    try {
      updateResponse = await this.props.updatePlanTierMutation({
        variables: { id, planTierId: planTierID },
        optimisticResponse: {
          updateChurchPlanTier: {
            __typename: 'Church',
            id,
            planTier: {
              __typename: 'PlanTier',
              id: planTierID,
              displayName,
              pricePerMonth,
              congregantsMin,
              congregantsMax
            }
          }
        }
      });

      this.props.showToast({
        type: 'success',
        content: 'Subscription Plan successfully updated!'
      });
    } catch (e) {
      updateResponse = e;
      this.props.showToast({
        type: 'error',
        content: 'There was an issue saving. Please try again later'
      });
    }

    return updateResponse;
  }

  render() {
    const planTier = this.props.acctData.adminOf.planTier;
    const { planTiers } = this.props;
    const selectedTier = planTiers.filter(
      option => option.id === planTier.id
    )[0];
    let seletedRange = `${selectedTier.congregantsMin} - ${selectedTier.congregantsMax}`;
    if (selectedTier.congregantsMax > 1000) {
      seletedRange = `${selectedTier.congregantsMin}+`;
    }
    return (
      <AccountForm
        handleSubmit={this.handleSubmit}
        render={({ editable, handleMount }) => (
          <FormControl labelTop={true} className={accountStyles.inputContainer}>
            <Label htmlFor="planTier">Subscription Plan</Label>
            <InputDynamic
              displayInput={editable}
              value={`${selectedTier.displayName} (${seletedRange} people weekly)`}
            >
              <Select
                defaultValue={planTier.id}
                id="planTier"
                refFn={input => {
                  this.planTierInput = input;
                }}
                mountFn={() => handleMount(this.planTierInput)}
              >
                {planTiers.map(option => {
                  let range = `${option.congregantsMin} - ${option.congregantsMax}`;
                  if (option.congregantsMax > 1000) {
                    range = `${option.congregantsMin}+`;
                  }
                  return (
                    <option key={option.id} value={option.id}>
                      {`${option.displayName} (${range} people weekly)`}
                    </option>
                  );
                })}
              </Select>
            </InputDynamic>
          </FormControl>
        )}
      />
    );
  }
}

export default class PlanTierFormWithData extends React.Component {
  render() {
    return (
      <ToastContext.Consumer>
        {({ showToast }) => (
          <Mutation mutation={UPDATE_PLAN_TIER_MUTATION}>
            {updatePlanTierMutation => (
              <PlanTierForm
                updatePlanTierMutation={updatePlanTierMutation}
                showToast={showToast}
                {...this.props}
              />
            )}
          </Mutation>
        )}
      </ToastContext.Consumer>
    );
  }
}
