import React from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import './FieldInput.css';

const FieldInput = ({
  title,
  validationState,
  onValueChange,
  value,
  type,
  controlId,
  popoverText,
  autoFocus
}) => {
  const popover = <Popover id={`${controlId}-popover`}>{popoverText}</Popover>;

  return (
    <FormGroup
      controlId={controlId}
      className="sign-up-fields-form-group"
      validationState={validationState()}
    >
      <ControlLabel>{title}</ControlLabel>
      {popoverText ? (
        <OverlayTrigger placement="right" trigger={['focus']} overlay={popover}>
          <FormControl type={type} value={value} onChange={onValueChange} />
        </OverlayTrigger>
      ) : (
        <FormControl
          type={type}
          value={value}
          onChange={onValueChange}
          autoFocus={autoFocus}
        />
      )}
      <FormControl.Feedback />
    </FormGroup>
  );
};

export default FieldInput;
