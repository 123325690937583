import React, { Component } from 'react';
import { withFirebase } from 'services/firebase';

import styles from './profilePicture.module.css';

/**
 * ProfilePicture Component
 * @param {*} props
 *  size:
 */
export class ProfilePicture extends Component {
  state = {
    src: null
  };

  async componentDidMount() {
    const src = await this.props.firebase.calculateImageUrl(this.props.logo);
    this.setState({ src });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.logo.url !== this.props.logo.url) {
      const src = await this.props.firebase.calculateImageUrl(this.props.logo);
      this.setState({ src });
    }
  }

  render() {
    const { size, alt, className } = this.props;
    const { src } = this.state;

    const imgTag = src ? <img src={src} alt={alt} /> : null;

    return (
      <div
        className={`${styles.profilePicture} ${className}`}
        style={{ height: size, width: size }}
      >
        {imgTag}
      </div>
    );
  }
}

ProfilePicture.defaultProps = {
  logo: {
    url: 'https://d2rdpmg2nfb82t.cloudfront.net/empty-state_1.jpg',
    urlSchema: 'HTTP_LINK'
  },
  alt: 'Profile',
  className: ''
};

export default withFirebase(ProfilePicture);
