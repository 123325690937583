import React from 'react';

import { Fieldset, Legend } from 'components/common/form/Fieldset';
import styles from './WeekDayPicker.module.css';

class WeekDayPicker extends React.Component {
  render() {
    return (
      <Fieldset>
        <Legend>Day</Legend>
        <div className={styles.weekDayPickerContainer}>
          <WeekDay
            day="SU"
            value={7}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
          <WeekDay
            day="MO"
            value={1}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
          <WeekDay
            day="TU"
            value={2}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
          <WeekDay
            day="WE"
            value={3}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
          <WeekDay
            day="TH"
            value={4}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
          <WeekDay
            day="FR"
            value={5}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
          <WeekDay
            day="SA"
            value={6}
            selected={this.props.dayIndex}
            onChange={this.props.onWeekdayChange}
          />
        </div>
      </Fieldset>
    );
  }
}

class WeekDay extends React.Component {
  render() {
    const { day, value, selected } = this.props;
    return (
      <label>
        <input
          type="radio"
          name="weekDay"
          value={value}
          className={styles.weekDayInput}
          onChange={e => {
            this.props.onChange(parseInt(e.target.value))
          }}
          checked={selected === value}
        />
        <div className={styles.weekDayDisplay}>{day}</div>
      </label>
    );
  }
}

export default WeekDayPicker;
