import React from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from './index';
import { IconX } from 'components/common/icons';
import { Button } from 'components/common/buttons';

import styles from './index.module.css';

const ModalHeader = props => {
  const { closeModal } = props.modal;
  const { children, className, icon } = props;
  return (
    <div className={`${styles.header} ${className}`}>
      <div className={styles.headerText}>
        {icon && <div className={styles.headerIcon}>{icon}</div>}
        {children}
      </div>
      <Button
        btnStyle="styleless"
        className={styles.dismiss}
        aria-label="close"
        onClick={closeModal}
      >
        <IconX fill="#fff" height={18} width={18} />
      </Button>
    </div>
  );
};

const ModalHeaderWithProvider = props => {
  return (
    <ModalContext.Consumer>
      {({ modal }) => {
        return <ModalHeader {...props} modal={modal} />;
      }}
    </ModalContext.Consumer>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired
};

ModalHeader.defaultProps = {
  className: ''
};

export default ModalHeaderWithProvider;
