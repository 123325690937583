import gql from 'graphql-tag';

export const fragments = {
  service: gql`
    fragment PastBulletinsTableService on Service {
      pastBulletins(skip: $skip, limit: $limit) {
        id
        title
        date
      }
    }
  `
};

export default {
  fragments
};
