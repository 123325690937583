import gql from 'graphql-tag';

export const fragments = {
  service: gql`
    fragment LiveBulletinService on Service {
      liveBulletin {
        id
        title
        date
      }
    }
  `
};

export default {
  fragments
};
