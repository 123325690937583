import React from 'react';
import { Spring } from 'react-spring/renderprops.cjs';

import {
  IconThreeCircleDropdown,
  IconArrowUp,
  IconArrowDown,
  IconX
} from 'components/common/icons';

import { EditorTooltip, EditorTooltipButtonContainer } from '../EditorTooltip';
import { EditorActionSVGButton } from '../EditorActionButton';

import styles from './BlockWrapperMenu.module.css';
import commonStyles from 'css/common.module.css';

class BlockWrapperMenu extends React.Component {
  constructor(props) {
    super(props);

    this.deleteBlock = this.deleteBlock.bind(this);
    this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
    this.handleHideMenuWrapper = this.handleHideMenuWrapper.bind(this);
    this.handleShowMenuWrapper = this.handleShowMenuWrapper.bind(this);
    this.moveBlockUp = this.moveBlockUp.bind(this);
    this.moveBlockDown = this.moveBlockDown.bind(this);

    this.menuButton = React.createRef(null);
    this.state = {
      displayRoot: false,
      displayMenu: false,
      confirmDelete: false
    };
  }

  handleShowMenuWrapper() {
    this.setState({
      displayRoot: true
    });
  }

  handleHideMenuWrapper() {
    this.setState({
      displayRoot: false,
      displayMenu: false,
      confirmDelete: false
    });
  }

  handleMenuIconClick() {
    this.setState({
      displayMenu: true
    });
  }

  deleteBlock() {
    const { node, editor } = this.props;
    if (this.state.confirmDelete) {
      if (editor.value.document.nodes.size === 1) {
        // if this is the last node, erase the content instead of the node itself
        editor
          .focus()
          .moveToRangeOfNode(node)
          .delete();
      } else {
        editor.removeNodeByKey(node.get('key')).focus();
      }
      this.handleHideMenuWrapper();
    } else {
      this.setState({
        confirmDelete: true
      });
    }
  }

  isDownDisabled() {
    const { node, editor } = this.props;
    const nodePath = editor.value.document.getPath(node.get('key'));
    return nodePath.get(0) + 1 === editor.value.document.nodes.size;
  }

  isUpDisabled() {
    const { node, editor } = this.props;
    const nodePath = editor.value.document.getPath(node.get('key'));
    return nodePath.get(0) === 0;
  }

  moveBlock(direction) {
    const { node, editor } = this.props;
    const nodePath = editor.value.document.getPath(node.get('key'));
    const docKey = editor.value.document.get('key');

    editor.moveNodeByKey(node.get('key'), docKey, nodePath.get(0) + direction);
    this.handleHideMenuWrapper();
  }

  moveBlockUp() {
    this.moveBlock(-1);
  }

  moveBlockDown() {
    this.moveBlock(1);
  }

  getTriggerRect() {
    let coords = this.menuButton.current.getBoundingClientRect();
    return {
      bottom: coords.bottom,
      height: coords.height,
      left: coords.left,
      right: coords.right,
      top: coords.top,
      width: coords.width,
      x: coords.x,
      y: coords.y
    };
  }

  render() {
    const { actions, children, className } = this.props;
    const { displayRoot, displayMenu, confirmDelete } = this.state;

    return (
      <div
        className={`${styles.root} ${
          displayRoot ? styles.show : ''
        } ${className}`}
        onClick={this.handleShowMenuWrapper}
        onMouseEnter={this.handleShowMenuWrapper}
        onMouseLeave={this.handleHideMenuWrapper}
      >
        {children}
        <div contentEditable={false}>
          <EditorActionSVGButton
            onClick={this.handleMenuIconClick}
            className={`${styles.menuIcon} ${
              displayRoot ? '' : commonStyles.visuallyHidden
            }`}
            ref={this.menuButton}
            aria-label="Configure block"
          >
            <IconThreeCircleDropdown viewBox="-2 -1 15 7" />
          </EditorActionSVGButton>
          {displayMenu && (
            <EditorTooltip
              triggerRect={this.getTriggerRect()}
              placement="bottom"
              horizontal="right"
              className={styles.menuTooltip}
            >
              {actions && (
                <EditorTooltipButtonContainer>
                  {actions}
                </EditorTooltipButtonContainer>
              )}
              <EditorTooltipButtonContainer>
                <EditorActionSVGButton
                  id="moveBlockUp"
                  aria-label="Move block up"
                  onClick={this.moveBlockUp}
                  disabled={this.isUpDisabled()}
                >
                  <IconArrowUp viewBox="1 1 16 16" />
                </EditorActionSVGButton>
                <EditorActionSVGButton
                  id="moveBlockDown"
                  aria-label="Move block down"
                  onClick={this.moveBlockDown}
                  disabled={this.isDownDisabled()}
                >
                  <IconArrowDown viewBox="1 1 16 16" />
                </EditorActionSVGButton>
                <Spring
                  config={{ tension: 300 }}
                  to={{
                    transform: confirmDelete ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                >
                  {props => (
                    <EditorActionSVGButton
                      style={props}
                      aria-label={
                        confirmDelete ? 'Confirm delete block' : 'Delete block'
                      }
                      onClick={this.deleteBlock}
                      className={`${
                        confirmDelete ? styles.confirmDelete : styles.delete
                      }`}
                    >
                      <IconX width="20" height="20" viewBox="-2 -2 20 20" />
                    </EditorActionSVGButton>
                  )}
                </Spring>
              </EditorTooltipButtonContainer>
            </EditorTooltip>
          )}
        </div>
      </div>
    );
  }
}

BlockWrapperMenu.defaultProps = {
  className: ''
};

export default BlockWrapperMenu;
