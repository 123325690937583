import React from 'react';

import { IconPastBulletin } from 'components/common/icons';
import {ConsoleHeader, ConsoleHeaderContainer} from 'components/bulletins/ConsoleHeader';

class ResponsesHeader extends React.Component {
  render() {
    return (
      <ConsoleHeaderContainer>
        <ConsoleHeader icon={IconPastBulletin}>
          <h2>Past Bulletins</h2>
        </ConsoleHeader>
      </ConsoleHeaderContainer>
    );
  }
}

export default ResponsesHeader;
