import React, { Component } from 'react';
import { Transition, animated } from 'react-spring/renderprops.cjs';
import FadeIn from 'react-fade-in';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { Mutation, ApolloConsumer } from 'react-apollo';
import { CREATE_CHURCH_WITH_ADMIN } from 'services/graphql/mutations';
import { ACTIVE_PLAN_TIERS } from './SignUpApi';

import SpecialMessage from './SpecialMessage';
import SignUpScreenIndicator from './SignUpScreenIndicator';
import AccountFields from './AccountFields';
import ChurchFields from './ChurchFields';
import PlanSelection from './PlanSelection';
import PaymentForm from './PaymentForm';
import SuccessScreen from './SuccessScreen';
import CONFIG from 'config';
import { FirebaseContext } from 'services/firebase';
import errorHandler from 'services/errorHandlerUtility';

import './SignUp.css';
export default class SignUpWithData extends Component {
  render() {
    return (
      <Mutation mutation={CREATE_CHURCH_WITH_ADMIN}>
        {createChurchWithAdmin => (
          <FirebaseContext.Consumer>
            {firebase => (
              <ApolloConsumer>
                {client => (
                  <SignUp
                    createChurchWithAdmin={createChurchWithAdmin}
                    firebase={firebase}
                    client={client}
                  />
                )}
              </ApolloConsumer>
            )}
          </FirebaseContext.Consumer>
        )}
      </Mutation>
    );
  }
}

export class SignUp extends Component {
  constructor(props) {
    super(props);

    this._accountFieldsSubmit = this._accountFieldsSubmit.bind(this);
    this._churchFieldsSubmit = this._churchFieldsSubmit.bind(this);
    this._planSelectionSubmit = this._planSelectionSubmit.bind(this);
    this._paymentFormSubmit = this._paymentFormSubmit.bind(this);
    this._goBack = this._goBack.bind(this);
    this._hideSpecialMessage = this._hideSpecialMessage.bind(this);
  }

  state = {
    stageIndex: 0,
    stripe: null,
    signupSuccessful: false,
    creditCardError: false,
    statusMessage: '',
    errorMessage: '',
    showSpecialMessage: true
  };

  componentDidMount() {
    const { client } = this.props;
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(CONFIG.STRIPE_KEY) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(CONFIG.STRIPE_KEY) });
      });
    }
    client.query({ query: ACTIVE_PLAN_TIERS });
  }

  _accountFieldsSubmit(fieldValues) {
    this.setState({ stageIndex: 1, ...fieldValues });
  }

  _churchFieldsSubmit(fieldValues) {
    this.setState({ stageIndex: 2, ...fieldValues });
  }

  _planSelectionSubmit(fieldValues) {
    this.setState({ stageIndex: 3, ...fieldValues });
  }

  async _paymentFormSubmit({ token }) {
    const { createChurchWithAdmin, firebase } = this.props;
    const { email, password, churchName, selectedPlan } = this.state;

    this.setState({
      stageIndex: 4,
      statusMessage: 'Setting up your account...'
    });
    try {
      const {
        data: {
          createChurchWithAdmin: { token: customToken }
        }
      } = await createChurchWithAdmin({
        variables: {
          name: churchName,
          stripeToken: token.id,
          email,
          planTierId: selectedPlan,
          password
        }
      });

      await firebase.signInWithCustomToken(customToken);

      firebase.getCurrentUser().sendEmailVerification();

      this.setState({ signupSuccessful: true });
    } catch (ex) {
      if (
        ex.code === 'auth/email-already-in-use' ||
        ex.code === 'auth/invalid-email'
      ) {
        this.setState({
          errorMessage: ex.message
        });
      } else {
        errorHandler.report(ex);
        this.setState({
          errorMessage: 'An unknown error occurred! Sorry about that!'
        });
      }
    }
  }

  _goBack() {
    this.setState(prevState => ({ stageIndex: prevState.stageIndex - 1 }));
  }

  _hideSpecialMessage() {
    this.setState({
      showSpecialMessage: false
    });
  }

  render() {
    const {
      stageIndex,
      stripe,
      email,
      statusMessage,
      errorMessage,
      churchName,
      signupSuccessful,
      creditCardError,
      showSpecialMessage
    } = this.state;

    const stages = [
      style => (
        <animated.div style={{ ...style }} className="sign-up-fields-container">
          <AccountFields successfulSubmit={this._accountFieldsSubmit} />
        </animated.div>
      ),
      style => (
        <animated.div style={{ ...style }} className="sign-up-fields-container">
          <ChurchFields
            goBack={this._goBack}
            successfulSubmit={this._churchFieldsSubmit}
          />
        </animated.div>
      ),
      style => (
        <animated.div style={{ ...style }} className="sign-up-fields-container">
          <PlanSelection
            goBack={this._goBack}
            successfulSubmit={this._planSelectionSubmit}
          />
        </animated.div>
      ),
      style => (
        <animated.div style={{ ...style }} className="sign-up-fields-container">
          <StripeProvider stripe={stripe}>
            <Elements>
              <PaymentForm
                email={email}
                goBack={this._goBack}
                processPayment={this._paymentFormSubmit}
              />
            </Elements>
          </StripeProvider>
        </animated.div>
      ),
      style => (
        <animated.div
          style={{ justifyContent: 'center', ...style }}
          className="sign-up-fields-container"
        >
          <SuccessScreen
            statusMessage={statusMessage}
            errorMessage={errorMessage}
            churchName={churchName}
            signupSuccessful={signupSuccessful}
            creditCardError={creditCardError}
            goBack={this._goBack}
          />
        </animated.div>
      )
    ];

    return (
      <React.Fragment>
        {showSpecialMessage && (
          <SpecialMessage handleClose={this._hideSpecialMessage} />
        )}
        <div
          className={
            showSpecialMessage
              ? 'sign-up-container-with-special-message'
              : 'sign-up-container'
          }
        >
          <FadeIn>
            <div className="sign-up-bounds">
              <img
                width="60"
                alt=""
                src="https://d2rdpmg2nfb82t.cloudfront.net/Bulletn-monogram.png"
              />
              <Transition
                native
                items={stageIndex}
                from={{ opacity: 0, transform: 'translate3d(0%,0,0)' }}
                enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
                leave={{ opacity: 0, transform: 'translate3d(-100%,0,0)' }}
              >
                {index => stages[index]}
              </Transition>
              <SignUpScreenIndicator currentStage={stageIndex} />
            </div>
          </FadeIn>
        </div>
      </React.Fragment>
    );
  }
}
