import React, { Component } from 'react';
import './SignUpScreenIndicator.css';

export default class SignUpScreenIndicator extends Component {
  _createIndicator(index) {
    const { currentStage } = this.props;
    const stageClass = 'sign-up-stage-indicator';
    const activeStageClass = 'sign-up-stage-indicator-active';

    return (
      <div
        key={index}
        className={
          currentStage === index
            ? `${stageClass} ${activeStageClass}`
            : `${stageClass}`
        }
      />
    );
  }

  render() {
    const { numberOfStages } = this.props;
    const iterationArray = Array.apply(null, { length: numberOfStages }).map(
      Number.call,
      Number
    );
    return (
      <div>
        {iterationArray
          .map(Number.call, Number)
          .map(index => this._createIndicator(index))}
      </div>
    );
  }
}

SignUpScreenIndicator.defaultProps = {
  numberOfStages: 4,
  currentStage: 0
};
