import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FadeIn from 'react-fade-in';

import TileButton from 'components/common/TileButton';
import ConnectCardEditor from './ConnectCardEditor';

class EditConnectCard extends React.Component {
  render() {
    const { id } = this.props.match.params;
    return (
      <Row>
        <Col xs={3} className="leftConsole">
          <FadeIn>
            <TileButton
              icon="leftArrow"
              label="Back"
              destination={`/dashboard/bulletins/edit/${id}`}
            />
          </FadeIn>
        </Col>
        <Col xs={6}>
          <FadeIn>
            <ConnectCardEditor />
          </FadeIn>
        </Col>
        <Col xs={3}>
          <FadeIn />
        </Col>
      </Row>
    );
  }
}

export default EditConnectCard;
