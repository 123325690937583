import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withApollo } from 'react-apollo';

import { Button } from 'components/common/buttons';
import { IconEdit } from 'components/common/icons';
import { Query, Mutation } from 'react-apollo';

import {
  CREATE_BULLETIN_MUTATION,
  REPLICATE_BULLETIN
} from 'services/graphql/mutations';
import { GET_CREATE_BULLETIN_MODAL_STATE } from './NewBulletinModalApi';
import NewBulletinModalDateInput from './NewBulletinModalDateInput';
import { UPDATE_CREATE_BULLETIN_MODAL } from 'services/graphql/local-mutations';

import Input from 'components/common/form/inputs/Input';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import Select from 'components/common/form/inputs/Select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'components/common/Modal';
import {
  BULLETINS_PAGE_QUERY,
  mergeBulletinList
} from 'scenes/Console/ConsoleApi';

import styles from './NewBulletinModal.module.css';

export class NewBulletinModalWithData extends Component {
  render() {
    const { church, client, selectedService } = this.props;
    return (
      <Query query={GET_CREATE_BULLETIN_MODAL_STATE}>
        {({
          data: {
            createBulletinModal: { isOpen, replicate, originalBulletinId }
          }
        }) => (
          <Mutation mutation={UPDATE_CREATE_BULLETIN_MODAL}>
            {updateCreateBulletinModal => (
              <Mutation
                mutation={CREATE_BULLETIN_MUTATION}
                update={(cache, { data: { createBulletin } }) => {
                  let data = cache.readQuery({
                    query: BULLETINS_PAGE_QUERY
                  });
                  cache.writeQuery({
                    query: BULLETINS_PAGE_QUERY,
                    data: mergeBulletinList(
                      data,
                      [createBulletin],
                      'plannedBulletins'
                    )
                  });
                }}
                refetchQueries={() => {
                  const data = client.readQuery({
                    query: BULLETINS_PAGE_QUERY
                  });

                  return [
                    {
                      query: BULLETINS_PAGE_QUERY,
                      variables: {
                        limit:
                          data.loggedInUser.adminOf.services[
                            selectedService.index
                          ].plannedBulletins.length
                      }
                    }
                  ];
                }}
              >
                {createBulletin => (
                  <Mutation
                    mutation={REPLICATE_BULLETIN}
                    update={(cache, { data: { duplicateBulletin } }) => {
                      try {
                        const data = cache.readQuery({
                          query: BULLETINS_PAGE_QUERY
                        });
                        cache.writeQuery({
                          query: BULLETINS_PAGE_QUERY,
                          data: mergeBulletinList(
                            data,
                            [duplicateBulletin],
                            'plannedBulletins'
                          )
                        });
                      } catch (e) {
                        // Cover the case where planned bulletins are not in the cache
                        // which will cause readQuery to fail. This
                        // can happen if the user navigates directly to Past Bulletins
                        // without having already navigated to Planned Bulletins. Don't
                        // manipulate the planned bulletins data in this case
                      }
                    }}
                    refetchQueries={() => {
                      let data = null;
                      try {
                        data = client.readQuery({
                          query: BULLETINS_PAGE_QUERY
                        });
                      } catch (e) {}

                      // If there are no planned bulletins (data=null because readQuery failed),
                      // then we don't need to refetch that query
                      return data
                        ? [
                            {
                              query: BULLETINS_PAGE_QUERY,
                              variables: {
                                limit:
                                  data.loggedInUser.adminOf.services[
                                    selectedService.index
                                  ].plannedBulletins.length
                              }
                            }
                          ]
                        : [];
                    }}
                  >
                    {duplicateBulletin => (
                      <NewBulletinModal
                        updateCreateBulletinModal={updateCreateBulletinModal}
                        createBulletin={createBulletin}
                        duplicateBulletin={duplicateBulletin}
                        isOpen={isOpen}
                        replicate={replicate}
                        originalBulletinId={originalBulletinId}
                        services={church.services}
                        selectedServiceIndex={selectedService.index}
                      />
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Query>
    );
  }
}

export default withApollo(NewBulletinModalWithData);

export class NewBulletinModal extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();

    this.serviceSelected = this.serviceSelected.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  state = {
    selectedService: this.props.selectedServiceIndex,
    selectedDate: null,
    title: '',
    validDay: null
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedService: nextProps.selectedServiceIndex
    });
  }

  serviceSelected(e) {
    this.setState({ selectedService: e });
  }

  onDateSelect(moment) {
    this.setState({
      selectedDate: moment._d
    });
  }

  onTitleChange(e) {
    this.setState({
      title: e.target.value
    });
  }

  async validateAndSubmit() {
    const yesterday = moment().subtract(1, 'day');
    let { title, selectedDate, selectedService } = this.state;
    let {
      createBulletin,
      duplicateBulletin,
      services,
      updateCreateBulletinModal,
      replicate,
      originalBulletinId
    } = this.props;
    if (
      title.length > 0 &&
      moment(selectedDate).isValid() &&
      moment(selectedDate).isAfter(yesterday) &&
      moment(selectedDate).day() ===
        moment()
          .day(`${services[selectedService].day}`)
          .day()
    ) {
      this.setState({
        selectedService: 0,
        selectedDate: null,
        title: ''
      });
      try {
        if (replicate) {
          duplicateBulletin({
            variables: {
              title,
              id: originalBulletinId,
              date: moment(selectedDate).format('YYYY-MM-DD'),
              serviceId: services[selectedService].id
            },
            optimisticResponse: {
              __typename: 'Mutation',
              duplicateBulletin: {
                __typename: 'Bulletin',
                id: -1,
                title: title,
                date: selectedDate
              }
            }
          });
        } else {
          createBulletin({
            variables: {
              title,
              date: moment(selectedDate).format('YYYY-MM-DD'),
              serviceId: services[selectedService].id
            },
            optimisticResponse: {
              __typename: 'Mutation',
              createBulletin: {
                __typename: 'Bulletin',
                id: -1,
                title: title,
                date: selectedDate
              }
            }
          });
        }
        updateCreateBulletinModal({ variables: { isOpen: false } });
      } catch (e) {}
    } else {
      alert('Invalid Submission');
    }
  }

  onClose() {
    const { updateCreateBulletinModal } = this.props;
    this.setState({
      selectedService: 0,
      selectedDate: null,
      title: ''
    });
    updateCreateBulletinModal({ variables: { isOpen: false } });
  }

  render() {
    const { isOpen, services } = this.props;
    const { selectedService, selectedDate, title } = this.state;

    return (
      <Modal
        modalId="newBulletinModal"
        show={isOpen}
        onClose={this.onClose}
        initialFocus="select"
      >
        <ModalHeader
          icon={<IconEdit height="40" width="40" viewBox="-2.5 -2.5 22 22" />}
        >
          <h2 id="newBulletinModal-header">New Bulletin</h2>
        </ModalHeader>
        <ModalBody>
          <FormControl labelTop={true} className={styles.formControl}>
            <Label for="nb-service">Service</Label>
            <Select
              onSelect={this.serviceSelected}
              id="nb-service"
              className={styles.select}
              refFn={this.selectRef}
            >
              {services.map(service => {
                return (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl labelTop={true} className={styles.formControl}>
            <Label for="nb-title">Title</Label>
            <Input
              id="nb-title"
              type="text"
              value={title}
              onChange={this.onTitleChange}
              inputStyle="underlined"
            />
          </FormControl>
          <NewBulletinModalDateInput
            className={styles.formControl}
            value={selectedDate}
            onChange={this.onDateSelect}
            selectedService={services[selectedService]}
          />
        </ModalBody>
        <ModalFooter>
          <Button btnStyle="primary" onClick={this.validateAndSubmit}>
            Create Bulletin
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
