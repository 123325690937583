import gql from 'graphql-tag';

import ChurchNameFormApi from './forms/ChurchNameForm/ChurchNameFormApi';
import ChurchWebsiteFormApi from './forms/ChurchWebsiteForm/ChurchWebsiteFormApi';
import LocationFormApi from './forms/LocationForm/LocationFormApi';
import PlanTierFormApi from './forms/PlanTierForm/PlanTierFormApi';

export const fragments = {
  church: gql`
    fragment Account on Church {
      ...ChurchNameForm
      ...ChurchWebsiteForm
      ...LocationForm
      ...PlanTierFormChurch
    }
    ${ChurchNameFormApi.fragments.church}
    ${ChurchWebsiteFormApi.fragments.church}
    ${LocationFormApi.fragments.church}
    ${PlanTierFormApi.fragments.church}
  `,
  activePlanTiers: PlanTierFormApi.fragments.activePlanTiers
};

export default {
  fragments
};
