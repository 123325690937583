import React, { useState, useRef, useEffect } from 'react';

import { IconCaretRight, IconCaretLeft } from 'components/common/icons';

import LinkTypeSelectOption from './LinkTypeSelectOption';
import linkTypeData from '../linkTypeData';

import styles from './LinkTypeSelect.module.css';

const valueIds = Object.keys(linkTypeData);

const LinkTypeSelect = ({ value, handleChange }) => {
  // Manages whether the menu is opened or closed. the handleFocusOnUpdate lets
  // us know in useEffect whether or not we need to manually handle the focus, or
  // if the built in keyboard events will do the work for us
  const [open, setOpen] = useState({ open: false, handleFocusOnUpdate: false });

  // Previously selected control. Used for focus management in the button's
  // onMouseDown handler
  const [prevControl, setPrevControl] = useState(null);

  // The item in the list which is currently focused.
  const [focusedValue, setFocusedValue] = useState(value);

  const buttonRef = useRef(null);
  const listRef = useRef(null);

  /**
   * When a value is chosen, this calls back to the LinkTooltip to update
   * the chosen link type.
   * @param {String} valueId The chosen value from the dropdown
   */
  const updateValue = valueId => {
    setOpen({ open: false, handleFocusOnUpdate: true });
    handleChange({ target: { name: 'type', value: valueId } });
  };

  const handleMenuKeyDown = e => {
    if (e.key === 'Escape') {
      // Stop propagation so that escape doesn't close the tooltip too
      setOpen({ open: false, handleFocusOnUpdate: true });
      e.stopPropagation();
      return false;
    } else if (e.key === 'Enter') {
      updateValue(focusedValue);
    } else if (e.key === 'ArrowDown') {
      const index = valueIds.indexOf(focusedValue);
      // If the index is at the beginning of the list, don't move the selection
      if (index < valueIds.length - 1) {
        setFocusedValue(valueIds[index + 1]);
      }
    } else if (e.key === 'ArrowUp') {
      const index = valueIds.indexOf(focusedValue);
      // If the index is at the end of the list, don't move the selection
      if (index > 0) {
        setFocusedValue(valueIds[index - 1]);
      }
    }
  };

  const handleButtonKeyDown = e => {
    // For ArrowUp and ArrowDown, we both update the chosen value and update the
    // focused value to match that chosen value
    if (e.key === 'ArrowDown') {
      const index = valueIds.indexOf(value);
      if (index < valueIds.length - 1) {
        const newValue = valueIds[index + 1];
        setFocusedValue(newValue);
        updateValue(newValue);
      }
    } else if (e.key === 'ArrowUp') {
      const index = valueIds.indexOf(value);
      if (index > 0) {
        const newValue = valueIds[index - 1];
        setFocusedValue(newValue);
        updateValue(newValue);
      }
    }
  };

  const getMenuPosition = () => {
    const rect = buttonRef.current.getBoundingClientRect();

    return {
      left: `${rect.width}px`
    };
  };

  useEffect(() => {
    if (!open.handleFocusOnUpdate) {
      return;
    } else if (open.open) {
      listRef.current.focus();
    } else {
      buttonRef.current.focus();
    }
  }, [open]);

  const { Icon } = linkTypeData[value];

  return (
    <div className={styles.root}>
      <button
        id="type"
        name="type"
        aria-label={`Select link type: ${linkTypeData[value].value}`}
        aria-haspopup="listbox"
        ref={buttonRef}
        type="button"
        className={`${styles.button} ${open.open ? styles.open : ''}`}
        onMouseDown={e => {
          // Focus control. If the previously focused control is the list, then
          // the blur event will handle the focus management. Therefore, we don't
          // want this to continue and trigger the "onClick" event
          if (prevControl === 'list') {
            e.preventDefault();
          }
        }}
        onClick={() => {
          setOpen({ open: !open.open, handleFocusOnUpdate: true });
          setPrevControl('button');
        }}
        onKeyDown={handleButtonKeyDown}
      >
        <Icon {...{ [linkTypeData[value].colorAttr]: '#6b8089' }} />
        {open.open ? (
          <IconCaretLeft fill="#6b8089" height="10" width="10" />
        ) : (
          <IconCaretRight fill="#6b8089" height="10" width="10" />
        )}
      </button>
      {open.open && (
        <ul
          id="linkTypeList"
          ref={listRef}
          tabIndex="0"
          role="listbox"
          aria-expanded={open.open}
          aria-activedescendant={focusedValue}
          className={styles.ul}
          style={getMenuPosition()}
          onKeyDown={handleMenuKeyDown}
          onFocus={() => {
            setPrevControl('list');
          }}
          onBlur={() => {
            setOpen({ open: false, handleFocusOnUpdate: false });
          }}
        >
          {valueIds.map(valueId => (
            <LinkTypeSelectOption
              valueId={valueId}
              key={valueId}
              selected={valueId === value}
              focused={focusedValue === valueId}
              optionConfig={linkTypeData[valueId]}
              handleClick={() => updateValue(valueId)}
              handleMouseEnter={() => setFocusedValue(valueId)}
              tooltipContent={linkTypeData[valueId].helperText}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default LinkTypeSelect;
