import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';

import ServiceSettingsModal from './ServiceSettingsModal';
import { Button, ButtonGroup } from 'components/common/buttons';
import { IconPlanned, IconEdit } from 'components/common/icons';
import {
  ConsoleHeader,
  ConsoleHeaderContainer
} from 'components/bulletins/ConsoleHeader';

import { Mutation } from 'react-apollo';
import {
  UPDATE_SERVICE_SETTINGS_MODAL,
  UPDATE_CREATE_BULLETIN_MODAL
} from 'services/graphql/local-mutations';

export default class PlannedBulletinHeaderWithData extends Component {
  render() {
    const { services, selectedServiceIndex } = this.props;
    return (
      <Mutation mutation={UPDATE_CREATE_BULLETIN_MODAL}>
        {openNewBulletinModal => (
          <Mutation mutation={UPDATE_SERVICE_SETTINGS_MODAL}>
            {openServiceSettingsModal => (
              <PlannedBulletinHeader
                services={services}
                openNewBulletinModal={openNewBulletinModal}
                openServiceSettingsModal={openServiceSettingsModal}
                selectedServiceIndex={selectedServiceIndex}
              />
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

class PlannedBulletinHeader extends Component {
  getServiceDateFormat(selectedService) {
    return `${selectedService.day}, ${moment(selectedService.time)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format('h:mma z')}`;
  }

  render() {
    const {
      services,
      openNewBulletinModal,
      openServiceSettingsModal,
      selectedServiceIndex
    } = this.props;
    const selectedService = services[selectedServiceIndex];
    return (
      <ConsoleHeaderContainer>
        <Row>
          <Col xs={6}>
            <ConsoleHeader icon={IconPlanned}>
              <div>
                <h2>Planned Bulletins</h2>
                <p>{this.getServiceDateFormat(selectedService)}</p>
              </div>
            </ConsoleHeader>
          </Col>
          <Col xs={6}>
            <ButtonGroup flexRight={true}>
              <Button
                btnStyle="tertiary"
                btnSize="small"
                onClick={e => {
                  openServiceSettingsModal({
                    variables: { isOpen: true }
                  });
                }}
              >
                Service settings
              </Button>
              <ServiceSettingsModal
                services={services}
                selectedServiceIndex={selectedServiceIndex}
              />
              <Button
                btnStyle="primary"
                btnSize="small"
                onClick={e => {
                  openNewBulletinModal({
                    variables: { isOpen: true }
                  });
                }}
              >
                <IconEdit />
                New bulletin
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </ConsoleHeaderContainer>
    );
  }
}
