import React from 'react';
import FocusTrap from 'focus-trap-react';

import { IconX } from 'components/common/icons';
import { Button } from 'components/common/buttons';

import LinkTypeSelect from './LinkTypeSelect';
import linkTypeData from './linkTypeData';

import styles from '../AddLinkPlugin.module.css';

class EditLinkForm extends React.Component {
  componentWillUnmount() {
    this.props.addLinkPlugin.onLinkFormSubmit(null, this.props.linkData);
  }

  render() {
    const { addLinkPlugin, onChange, linkData } = this.props;

    return (
      <FocusTrap>
        <div className={styles.editLinkFormContainer}>
          <form
            id="editLinkForm"
            className={styles.editLinkForm}
            onSubmit={e => addLinkPlugin.onLinkFormSubmit(e, linkData)}
          >
            <LinkTypeSelect handleChange={onChange} value={linkData.type} />
            <span
              id="typeLabel"
              aria-relevant="all"
              className={styles.linkTypeLabel}
            >
              {linkTypeData[linkData.type].label}
            </span>
            <input
              id="link"
              name="link"
              type="text"
              aria-label="Link"
              className={styles.input}
              value={linkData.link}
              onChange={onChange}
              placeholder="Type or paste a link..."
            />
          </form>
          <Button
            btnStyle="styleless"
            className={styles.close}
            aria-label="Close link menu"
            onClick={addLinkPlugin.onLinkFormBlur}
          >
            <IconX />
          </Button>
        </div>
      </FocusTrap>
    );
  }
}

export default EditLinkForm;
