import React from 'react';
import FadeIn from 'react-fade-in';
import DateTime from 'react-datetime';

import Tile from 'components/layouts/Tile';
import Input from 'components/common/form/inputs/Input';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';

import styles from './BulletinInfo.module.css';
import inputStyles from 'components/common/form/inputs/Input/input.module.css';

class BulletinInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onTitleChange = this.onTitleChange.bind(this);
  }

  onTitleChange(e) {
    this.props.onAutoSaveFieldsChange({
      title: e.target.value
    });
  }

  render() {
    const {
      date,
      restrictDateSelection,
      onDateSelectionChange,
      title
    } = this.props;

    return (
      <FadeIn>
        <Tile withPadding={false}>
          <h2 className={styles.h2}>Bulletin details</h2>
          <div className={styles.info}>
            <FormControl labelTop={true} className={styles.formControl}>
              <Label for="title">Title</Label>
              <Input
                id="title"
                name="title"
                type="text"
                value={title}
                onChange={this.onTitleChange}
                inputStyle="underlined"
              />
            </FormControl>
            <FormControl labelTop={true} className={styles.formControl}>
              <Label for="date">Date</Label>
              <DateTime
                id="date"
                name="date"
                value={new Date(date)}
                timeFormat={false}
                onChange={onDateSelectionChange}
                isValidDate={restrictDateSelection}
                closeOnSelect={true}
                inputProps={{
                  className: `${inputStyles.input} ${inputStyles.underlined}`
                }}
              />
            </FormControl>
          </div>
        </Tile>
      </FadeIn>
    );
  }
}

export default BulletinInfo;
