import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { StripeProvider, Elements } from 'react-stripe-elements';
import FadeIn from 'react-fade-in';

import LoadingIcon from 'components/common/Loading';
import Toast from 'components/common/ToastContainer/Toast';
import { PAYMENT_SETTINGS_QUERY } from './PaymentApi';
import CONFIG from 'config';
import PaymentForm from './PaymentForm';
import { Tile } from 'components/layouts';
import CurrentCard from './CurrentCard';

import accountStyles from '../account-settings.module.css';
import styles from './Payment.module.css';

export class Payment extends React.Component {
  state = {
    form: {
      name: ''
    }
  };

  render() {
    const { churchPaymentInfo, loading, churchId } = this.props;

    // TODO: Remove dirty patch to show general 90 day trial message only to new churches
    return (
      <Tile withPadding={true}>
        <h2 className={accountStyles.h2}>Payment</h2>
        <div className={accountStyles.content}>
          {loading ? (
            <LoadingIcon />
          ) : (
            <Fragment>
              <div className={accountStyles.contentDescription}>
                <p>
                  Below is your current card on file. You can change this by
                  filling out and submitting the new card form.
                </p>
              </div>
              {churchId !== 'cjqybksjh8scu0e869al80xnn' &&
                churchId !== 'ck0vjygd1u2af0714n6md96b4' && (
                  <Toast
                    className={styles.toast}
                    dismissable={false}
                    toast={{
                      type: 'info',
                      id: 'paymentMessage',
                      title: 'First Three Months Free',
                      content:
                        'You are using a 90-day trial that began the day you signed up. Email support@bulletn.co with questions.'
                    }}
                  />
                )}
              {churchPaymentInfo && (
                <FadeIn>
                  <CurrentCard
                    brand={churchPaymentInfo.brand}
                    last4={churchPaymentInfo.last4}
                    name={churchPaymentInfo.name}
                    expiryMonth={churchPaymentInfo.expiryMonth}
                    expiryYear={churchPaymentInfo.expiryYear}
                  />
                </FadeIn>
              )}
              <PaymentForm churchId={churchId} />
            </Fragment>
          )}
        </div>
      </Tile>
    );
  }
}

export default class PaymentWithData extends React.Component {
  state = {
    stripe: null
  };

  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(CONFIG.STRIPE_KEY) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(CONFIG.STRIPE_KEY) });
      });
    }
  }

  render() {
    const { stripe } = this.state;
    const { churchId } = this.props;
    return (
      <Query query={PAYMENT_SETTINGS_QUERY} variables={{ churchId }}>
        {({ loading, error, data }) => {
          if (error) {
            return null;
          }

          return (
            <StripeProvider stripe={stripe}>
              <Elements>
                <Payment
                  churchPaymentInfo={data.churchPaymentInfo}
                  churchId={churchId}
                  loading={loading}
                />
              </Elements>
            </StripeProvider>
          );
        }}
      </Query>
    );
  }
}
