import gql from 'graphql-tag';

export const fragments = {
  bulletin: gql`
    fragment EditBulletin on Bulletin {
      id
      title
      date
      body
      updatedAt
      headerImage {
        id
        url
        urlSchema
      }
      service {
        id
        day
        time
        plannedBulletins {
          id
          date
        }
      }
    }
  `
};

export default {
  fragments
};
