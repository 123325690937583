import gql from 'graphql-tag';

import ProfileAndServicesApi from './ProfileAndServices/ProfileAndServicesApi';
import PlannedBulletinsApi from './PlannedBulletins/PlannedBulletinsApi';
import PastBulletinsApi from './PastBulletins/PastBulletinsApi';

export const fragments = {
  church: gql`
    fragment BulletinsChurch on Church {
      logo @include(if: $shouldIncludeBaseData) {
        ...ProfileAndServicesFile
      }
      services {
        ...ProfileAndServicesService @include(if: $shouldIncludeBaseData)
        ...PlannedBulletinsService
        ...PastBulletinsService
      }
    }
    ${ProfileAndServicesApi.fragments.file}
    ${ProfileAndServicesApi.fragments.service}
    ${PlannedBulletinsApi.fragments.service}
    ${PastBulletinsApi.fragments.service}
  `
};

export default {
  fragments
};
