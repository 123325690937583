import React from 'react';

import FullViewMessage from '../FullViewMessage';

import styles from './Error.module.css';

const Error = () => (
  <FullViewMessage>
    <p className={styles.message}>
      An unknown error occurred. Sorry about that!
    </p>
  </FullViewMessage>
);

export default Error;
