import { IconEmail, IconPhone, IconInternet } from 'components/common/icons';

export default {
  web: {
    value: 'web',
    Icon: IconInternet,
    colorAttr: 'fill',
    helperText: 'Web',
    label: 'url:'
  },
  phone: {
    value: 'phone',
    Icon: IconPhone,
    colorAttr: 'stroke',
    helperText: 'Phone Number',
    label: 'tel:'
  },
  email: {
    value: 'email',
    Icon: IconEmail,
    colorAttr: 'stroke',
    helperText: 'Email Address',
    label: 'mailto:'
  }
};
