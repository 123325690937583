import React, { Component } from 'react';

import {
  IconChurchPlant,
  IconSmallChurch,
  IconMediumChurch,
  IconLargeChurch,
  IconMegaChurch
} from 'components/common/icons';
import './ChurchPlanDisplay.css';

const icons = {
  Plant: <IconChurchPlant />,
  Small: <IconSmallChurch />,
  Medium: <IconMediumChurch />,
  Large: <IconLargeChurch />,
  Mega: <IconMegaChurch />
};

export default class ChurchPlanDisplay extends Component {
  render() {
    const {
      id,
      tier,
      minMembers,
      maxMembers,
      cost,
      selectedPlan,
      handlePlanSelect
    } = this.props;
    let range = `${minMembers} - ${maxMembers}`;
    if (maxMembers > 1000) {
      range = `${minMembers}+`;
    }
    if (minMembers === 0) {
      range = `< ${maxMembers}`;
    }
    return (
      <div>
        <input
          type="radio"
          id={`${tier}-radio`}
          name={id}
          className="church-plan-radio"
          checked={selectedPlan === id}
          onChange={handlePlanSelect}
        />
        <label className="church-plan-display-label" htmlFor={`${tier}-radio`}>
          {icons[tier]}
          <h3>
            <b>{tier}</b>
          </h3>
          <p className="body-text-small">{range} people/wk</p>
          <h3>{cost}/mo</h3>
        </label>
      </div>
    );
  }
}
