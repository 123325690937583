import { isHotkey, toKeyName } from 'is-hotkey';

const isWindows = toKeyName('mod') === 'control';

export function hasBlocks(editor) {
  const { value } = editor;
  return value.blocks.size > 1;
}

export function hasBlockType(type, editor) {
  const { value } = editor;
  return value.blocks.some(node => node.type === type);
}

export function hasMarkType(type, editor) {
  const { value } = editor;
  return value.activeMarks.some(mark => mark.type === type);
}

export function isBlockEmpty(editor) {
  const { blocks } = editor.value;
  if (blocks.size === 1) {
    const texts = blocks.get(0).getTexts();
    return texts.size === 1 && texts.get(0).get('text') === '';
  }
  return false;
}

/**
 * For toolbar buttons whose significant keys are between 1 and 8,
 * this function returns either the hotkey test "ctrl+shift+{key}" or
 * "ctrl+{key}"
 * @param {String} key Significant key of the hotkey
 */
export function getCmdShiftHotkeyTest(key) {
  return isWindows ? isHotkey(`ctrl+shift+${key}`) : isHotkey(`ctrl+${key}`);
}

/**
 * For toolbar buttons whose significant keys are between 1 and 8,
 * this function returns either the label "Ctrl+Shift+{key}" or
 * "Ctrl+{key}"
 * @param {String} key Significant key of the hotkey
 */
export function getCmdShiftHotkeyLabel(key) {
  return isWindows ? `Ctrl+Shift+${key}` : `Ctrl+${key}`;
}
