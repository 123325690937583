import React from 'react';
import { isHotkey } from 'is-hotkey';

import {
  handleBlockChange,
  handleMarkChange
} from './StickyToolbarEditorHandler';
import { BLOCK_TAGS, MARK_TAGS } from '../../HtmlSerializer';
import BlockButton from './BlockButton';
import MarkButton from './MarkButton';
import { getCmdShiftHotkeyTest, getCmdShiftHotkeyLabel } from '../SlateUtils';
import { LinkButton } from '../../SlatePlugins/AddLinkPlugin';
import { ImageButton } from '../../SlatePlugins/AddImagePlugin';
import { DividerButton } from '../../SlatePlugins/DividerPlugin';

import styles from './sticky-toolbar.module.css';
import { FeatureToggleListener } from 'services/firebase';

export class StickyToolbar extends React.Component {
  render() {
    const { editor } = this.props;

    return (
      <div className={styles.root}>
        <MarkButton
          editor={editor}
          type={MARK_TAGS.strong}
          hoverText="Bold"
          hotkey="mod+B"
        />
        <MarkButton
          editor={editor}
          type={MARK_TAGS.em}
          hoverText="Italic"
          hotkey="mod+I"
        />
        <MarkButton
          editor={editor}
          type={MARK_TAGS.u}
          hoverText="Underline"
          hotkey="mod+U"
        />
        <LinkButton editor={editor} classConfig="colorAndFill" />
        <BlockButton
          editor={editor}
          type={BLOCK_TAGS.ul}
          hoverText="Bulleted List"
          hotkey={getCmdShiftHotkeyLabel('2')}
        />
        <BlockButton
          editor={editor}
          type={BLOCK_TAGS.ol}
          hoverText="Numbered List"
          hotkey={getCmdShiftHotkeyLabel('3')}
        />
        <div className={styles.divider}>|</div>
        <BlockButton
          editor={editor}
          type={BLOCK_TAGS.h1}
          hoverText="Heading 1"
          hotkey={getCmdShiftHotkeyLabel('4')}
        />
        <BlockButton
          editor={editor}
          type={BLOCK_TAGS.h2}
          hoverText="Heading 2"
          hotkey={getCmdShiftHotkeyLabel('5')}
        />
        <BlockButton
          editor={editor}
          type={BLOCK_TAGS.blockquote}
          hoverText="Blockquote"
          hotkey={getCmdShiftHotkeyLabel('6')}
        />
        <div className={styles.divider}>|</div>
        <FeatureToggleListener featureToggleName="bulletin_images">
          <ImageButton editor={editor} classConfig="colorOnly" />
        </FeatureToggleListener>
        <FeatureToggleListener featureToggleName="bulletin_divider">
          <DividerButton className={styles.dividerButton} editor={editor} />
        </FeatureToggleListener>
      </div>
    );
  }
}

export default function StickyToolbarPlugin() {
  const hotkeyHandlers = [
    { type: MARK_TAGS.strong, test: isHotkey(`mod+b`) },
    { type: MARK_TAGS.em, test: isHotkey(`mod+i`) },
    { type: MARK_TAGS.u, test: isHotkey(`mod+u`) },
    { type: BLOCK_TAGS.ul, test: getCmdShiftHotkeyTest('2') },
    { type: BLOCK_TAGS.ol, test: getCmdShiftHotkeyTest('3') },
    { type: BLOCK_TAGS.h1, test: getCmdShiftHotkeyTest('4') },
    { type: BLOCK_TAGS.h2, test: getCmdShiftHotkeyTest('5') },
    { type: BLOCK_TAGS.blockquote, test: getCmdShiftHotkeyTest('6') }
  ];

  return {
    onKeyDown(event, editor, next) {
      const matchedHandler = hotkeyHandlers.find(handler =>
        handler.test(event)
      );
      if (!matchedHandler) return next();

      event.preventDefault();
      const { type } = matchedHandler;
      if (
        type === MARK_TAGS.strong ||
        type === MARK_TAGS.em ||
        type === MARK_TAGS.u
      ) {
        handleMarkChange(event, editor, type);
      } else {
        handleBlockChange(event, editor, type);
      }
      return true;
    },
    renderEditor: (props, editor, next) => {
      const children = next();

      return (
        <React.Fragment>
          {children}
          <StickyToolbar editor={editor} />
        </React.Fragment>
      );
    }
  };
}
