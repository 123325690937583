import React, { Component } from 'react';
import { withFirebase } from 'services/firebase';
import { CenteredContainer, Tile } from 'components/layouts';
import { Button } from 'components/common/buttons';
import NoMatch from 'components/routing/NoMatch';
import { Link } from 'react-router-dom';

const errorMessages = {
  'auth/invalid-action-code': 'This email verification link is invalid.',
  'auth/expired-action-code': 'This email verification link has expired.'
};

class VerifyEmail extends Component {
  state = {
    errorMessage: null,
    displayComponent: null
  };

  async componentDidMount() {
    const { firebase, code } = this.props;

    try {
      await firebase.applyActionCode(code);
      this.setState({
        displayComponent: (
          <Tile withPadding>
            <h2>We've successfully verified your email!</h2>
            <br />
            <Link to="/dashboard/bulletins">
              <Button>Go to the Bulletn console</Button>
            </Link>
          </Tile>
        )
      });
    } catch (ex) {
      const errorMessage = errorMessages[ex.code];
      if (errorMessage)
        this.setState({
          errorMessage,
          displayComponent: <Tile withPadding>{errorMessage}</Tile>
        });
      else this.setState({ displayComponent: <NoMatch /> });
    }
  }

  render() {
    const { displayComponent } = this.state;

    return <CenteredContainer>{displayComponent}</CenteredContainer>;
  }
}

export default withFirebase(VerifyEmail);
