import gql from 'graphql-tag';

export const fragments = {
  service: gql`
    fragment PlannedBulletinHeaderService on Service {
      day
      time
    }
  `
};

export default {
  fragments
};
