import React from 'react';
import Collapsible from 'react-collapsible';
import Divider from 'components/common/Divider';
import './TileDropdown.css';

class TileDropdown extends React.Component {
  render() {
    return (
      <div className="tile tileDropdown">
        <Collapsible trigger="Filter 1" transitionTime={300} easing="ease-out">
          <Divider />
          <div className="collapsedOption">
            <input type="radio" id="filterChoice1" name="filter" value="none" />
            <label for="filterChoice1">None</label>
          </div>

          <div className="collapsedOption">
            <input
              type="radio"
              id="filterChoice2"
              name="filter"
              value="firstTimeGuest"
            />
            <label for="filterChoice2">First-time guest</label>
          </div>

          <div className="collapsedOption">
            <input
              type="radio"
              id="filterChoice3"
              name="filter"
              value="secondTimeGuest"
            />
            <label for="filterChoice3">Second-time guest</label>
          </div>

          <div className="collapsedOption">
            <input
              type="radio"
              id="filterChoice4"
              name="filter"
              value="regularAttender"
            />
            <label for="filterChoice4">Regular Attender</label>
          </div>

          <div className="collapsedOption">
            <input
              type="radio"
              id="filterChoice5"
              name="filter"
              value="member"
            />
            <label for="filterChoice5">Member</label>
          </div>
        </Collapsible>
      </div>
    );
  }
}

export default TileDropdown;
