import React, { Component } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'components/common/Modal';
import FormControl from 'components/common/form/FormControl';
import Input from 'components/common/form/inputs/Input';
import Label from 'components/common/form/Label';
import { Button } from 'components/common/buttons';
import moment from 'moment';
import { IconToastInfo, IconCalendar } from 'components/common/icons';
import WeekDayPicker from './WeekDayPicker';
import Datetime from 'react-datetime';

import { Query, Mutation } from 'react-apollo';

import { UPDATE_SERVICE_MUTATION } from 'services/graphql/mutations';
import { GET_SERVICE_SETTINGS_MODAL_STATE } from './ServiceSettingsModalApi';
import { UPDATE_SERVICE_SETTINGS_MODAL } from 'services/graphql/local-mutations';
import iconWithTooltip from 'components/common/Tooltip/iconWithTooltip';
import { BULLETINS_PAGE_QUERY } from 'scenes/Console/ConsoleApi';

import styles from './ServiceSettingsModal.module.css';

const ServiceInfoMessage = iconWithTooltip(
  <IconToastInfo
    width={15}
    height={15}
    circleFill="#EAEAEA"
    textFill="#333333"
  />,
  {
    theme: 'dark',
    placement: 'right',
    className: styles.tooltip,
    showOnHover: true
  }
);

export default class ServiceSettingsModalWithDataStateAndContext extends Component {
  render() {
    const { close, services, selectedServiceIndex } = this.props;
    return (
      <Query query={GET_SERVICE_SETTINGS_MODAL_STATE}>
        {({
          data: {
            serviceSettingsModal: { isOpen }
          }
        }) => (
          <Mutation
            mutation={UPDATE_SERVICE_MUTATION}
            refetchQueries={[
              {
                query: BULLETINS_PAGE_QUERY,
                variables: {
                  shouldIncludeBaseData: true,
                  shouldIncludePlannedBulletins: true,
                  shouldIncludePastBulletins: false,
                  skip: 0
                }
              }
            ]}
          >
            {updateServiceMutation => (
              <Mutation mutation={UPDATE_SERVICE_SETTINGS_MODAL}>
                {updateServiceSettingsModal => (
                  <ServiceSettingsModal
                    close={close}
                    serviceSettingsModalOpen={isOpen}
                    services={services}
                    selectedServiceIndex={selectedServiceIndex}
                    updateServiceMutation={updateServiceMutation}
                    updateServiceSettingsModal={updateServiceSettingsModal}
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Query>
    );
  }
}

class ServiceSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceName: props.services[props.selectedServiceIndex].name,
      selectedWeekdayIndex: moment()
        .isoWeekday(props.services[props.selectedServiceIndex].day)
        .isoWeekday(),
      serviceTime: moment(
        new Date(props.services[props.selectedServiceIndex].time)
      ).format('h:mm A')
    };

    this.onServiceNameChange = this.onServiceNameChange.bind(this);
    this.onWeekdayChange = this.onWeekdayChange.bind(this);
    this.onServiceTimeChange = this.onServiceTimeChange.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      serviceName: nextProps.services[nextProps.selectedServiceIndex].name,
      selectedWeekdayIndex: moment()
        .isoWeekday(nextProps.services[nextProps.selectedServiceIndex].day)
        .isoWeekday(),
      serviceTime: moment(
        new Date(nextProps.services[nextProps.selectedServiceIndex].time)
      ).format('h:mm A')
    });
  }

  onServiceNameChange(e) {
    this.setState({
      serviceName: e.target.value
    });
  }

  onWeekdayChange(index) {
    this.setState({ selectedWeekdayIndex: index });
  }

  onServiceTimeChange(e) {
    if (e instanceof moment) {
      this.setState({ serviceTime: e.format('h:mm A') });
    }
  }

  validateAndSubmit = () => {
    const { serviceName, selectedWeekdayIndex, serviceTime } = this.state;
    const {
      updateServiceMutation,
      selectedServiceIndex,
      services,
      updateServiceSettingsModal
    } = this.props;

    if (
      serviceName &&
      !isNaN(selectedServiceIndex) &&
      moment(serviceTime, 'h:mm A').isValid()
    ) {
      updateServiceMutation({
        variables: {
          id: services[selectedServiceIndex].id,
          name: serviceName,
          day: moment()
            .isoWeekday(selectedWeekdayIndex)
            .format('dddd'),
          time: moment(serviceTime, 'h:mm A').toISOString()
        }
      });

      updateServiceSettingsModal({ variables: { isOpen: false } });
    } else {
      alert('Invalid Submission');
    }
  };

  onHide() {
    const { updateServiceSettingsModal } = this.props;
    updateServiceSettingsModal({ variables: { isOpen: false } });
  }

  render() {
    const { serviceSettingsModalOpen } = this.props;
    const { serviceName, selectedWeekdayIndex, serviceTime } = this.state;

    return (
      <Modal
        show={serviceSettingsModalOpen}
        onClose={this.onHide}
        modalId="serviceSettingsModal"
      >
        <ModalHeader
          icon={<IconCalendar width="40" height="40" viewBox="-4 -4 25 25" />}
        >
          <h2>Service Settings</h2>
        </ModalHeader>
        <ModalBody>
          <form>
            <FormControl labelTop={true} className={styles.formControl}>
              <div className={styles.labelWithIcon}>
                <Label className={styles.label}>Service Name</Label>
                <ServiceInfoMessage
                  id="serviceName"
                  className={styles.icon}
                  tooltipContent={
                    'This is the name your congregation will see in their mobile application'
                  }
                />
              </div>
              <Input
                type="text"
                inputStyle="underlined"
                value={serviceName}
                onChange={this.onServiceNameChange}
              />
            </FormControl>
            <FormControl labelTop={true} className={styles.formControl}>
              <WeekDayPicker
                dayIndex={selectedWeekdayIndex}
                onWeekdayChange={this.onWeekdayChange}
              />
            </FormControl>
            <FormControl labelTop={true} className={styles.formControl}>
              <div className={styles.labelWithIcon}>
                <Label className={styles.label}>Time</Label>
                <ServiceInfoMessage
                  id="serviceTime"
                  className={styles.icon}
                  tooltipContent={
                    'This is the time your bulletin will actually change to the next planned bulletin'
                  }
                />
              </div>
              <Datetime
                dateFormat={false}
                value={serviceTime}
                onChange={this.onServiceTimeChange}
                renderInput={props => {
                  return (
                    <Input type="text" inputStyle="underlined" {...props} />
                  );
                }}
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button btnStyle="primary" onClick={this.validateAndSubmit}>
            Accept
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
