import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

import styles from './index.module.css';

export const ModalContext = React.createContext();

/**
 * Modal 
 */
class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.escapeListener = this.escapeListener.bind(this);
  }

  addOpenClasses() {
    const classMatch = new RegExp(styles.open);
    if (this.root && !this.root.className.match(classMatch)) {
      this.root.className += ` ${styles.open} ${this.props.openClassName}`;
      this.background.className += ` ${styles.open} ${this.props.openClassName}`;
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.escapeListener);
    this.addOpenClasses();
  }

  componentDidUpdate() {
    this.addOpenClasses();
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.escapeListener);
  }

  escapeListener(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeModal();
    }
  }

  closeModal() {
    this.root.className = this.root.className.replace(styles.open, '');
    this.background.className = this.background.className.replace(styles.open, '');
    setTimeout(() => {
      this.props.onClose();
    }, 300);
  }

  render() {
    const { modalId, show, children, initialFocus, className } = this.props;
    const { closeModal } = this;

    if (!show) return null;

    const modal = {
      closeModal,
      modalId
    };

    return ReactDOM.createPortal(
      <ModalContext.Provider value={{modal}}>
        <FocusTrap focusTrapOptions={{
          initialFocus
        }}>
          <div
            className={styles.background}
            ref={background => this.background = background}
            onClick={this.closeModal}
          ></div>
          <div className={`${styles.root} ${className}`} role="dialog" aria-modal="true"
            aria-labelledby={`${modalId}-header`}
            ref={root => this.root = root}
          >
            {children}
          </div>
        </FocusTrap>
      </ModalContext.Provider>
    , document.getElementsByTagName('body')[0])
  }
}

Modal.propTypes = {
  modalId: PropTypes.string.isRequired
};

Modal.defaultProps = {
  className: '',
  openClassName: ''
};

export {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
};