import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';

import { FirebaseContext } from 'services/firebase';
import { Button } from 'components/common/buttons';
import Label from 'components/common/form/Label';
import Input from 'components/common/form/inputs/Input';
import FormControl from 'components/common/form/FormControl';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { ToastContext } from 'components/common/ToastContainer';
import { Mutation } from 'react-apollo';
import { UPDATE_CHURCH_PAYMENT_INFO } from 'services/graphql/mutations';
import Validator from 'components/common/form/Validator';

import paymentFormStyles from './payment-form.module.css';
import inputStyles from 'components/common/form/inputs/Input/input.module.css';

const stripeInputStyles = {
  base: {
    fontSize: '14px',
    lineHeight: '20px',
    height: '20px'
  },
  invalid: {
    color: '#333333'
  }
};

export class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStripeFieldChange = this.handleStripeFieldChange.bind(this);

    this.stripeElements = {};
  }

  state = { email: '' };

  componentDidMount() {
    const { firebase } = this.props;
    this.unsubscribeFromTokenChanges = firebase.auth.onIdTokenChanged(user => {
      if (user) {
        this.setState({ email: user.email });
      } else {
        this.setState({ email: '' });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromTokenChanges();
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (!this.props.validator.validate()) {
      return;
    }

    try {
      let createTokenResponse = await this.props.stripe.createToken({
        email: this.state.email,
        name: this.props.validator.form.fields.name.value
      });

      await this.props.updateChurchPaymentInfo({
        variables: {
          stripeToken: createTokenResponse.token.id,
          id: this.props.churchId
        }
      });

      this.props.showToast({
        type: 'success',
        content: 'Your card has been updated!'
      });

      this.resetForm();
    } catch (e) {
      this.props.showToast({
        type: 'error',
        content:
          'We were unable to update your card at this time. Please try again later'
      });
    }
  }

  handleStripeFieldChange(e) {
    this.props.validator.handleChange({
      target: {
        name: e.elementType,
        value: e
      }
    });
  }

  resetForm() {
    this.props.validator.resetForm();
    this.stripeElements.cardNumber.clear();
    this.stripeElements.expiryDate.clear();
    this.stripeElements.cvc.clear();
  }

  render() {
    const {
      cardNumber,
      name,
      cardExpiry,
      cardCvc
    } = this.props.validator.form.fields;

    return (
      <div className={paymentFormStyles.formContainer}>
        <form
          className={paymentFormStyles.form}
          onSubmit={this.handleSubmit}
          noValidate
        >
          <div className={paymentFormStyles.formRow}>
            <FormControl labelTop={true}>
              <Label>
                Card Number
                <CardNumberElement
                  onReady={el => {
                    this.stripeElements.cardNumber = el;
                  }}
                  onChange={this.handleStripeFieldChange}
                  className={`
                    ${inputStyles.input}
                    ${paymentFormStyles.input}
                    ${!cardNumber.valid ? paymentFormStyles.stripeInvalid : ''}
                  `}
                  style={stripeInputStyles}
                />
                {!cardNumber.valid && (
                  <ErrorMessage
                    id="cardNumber-error"
                    message={cardNumber.errorMessage}
                  />
                )}
              </Label>
            </FormControl>
          </div>
          <div className={paymentFormStyles.bottomRow}>
            <FormControl labelTop={true}>
              <Label>
                Cardholder Name
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className={paymentFormStyles.input}
                  formField={name}
                  onChange={this.props.validator.handleChange}
                />
              </Label>
            </FormControl>
            <FormControl labelTop={true}>
              <Label>
                Exp Date
                <CardExpiryElement
                  onReady={el => {
                    this.stripeElements.expiryDate = el;
                  }}
                  onChange={this.handleStripeFieldChange}
                  className={`
                    ${inputStyles.input}
                    ${paymentFormStyles.input}
                    ${!cardExpiry.valid ? paymentFormStyles.stripeInvalid : ''}
                  `}
                  style={stripeInputStyles}
                />
                {!cardExpiry.valid && (
                  <ErrorMessage
                    id="cardExpiry-error"
                    message={cardExpiry.errorMessage}
                  />
                )}
              </Label>
            </FormControl>
            <FormControl labelTop={true}>
              <Label>
                CVC
                <CardCVCElement
                  onReady={el => {
                    this.stripeElements.cvc = el;
                  }}
                  onChange={this.handleStripeFieldChange}
                  className={`
                    ${inputStyles.input}
                    ${paymentFormStyles.input}
                    ${!cardCvc.valid ? paymentFormStyles.stripeInvalid : ''}
                  `}
                  style={stripeInputStyles}
                />
                {!cardCvc.valid && (
                  <ErrorMessage
                    id="cardCvc-error"
                    message={cardCvc.errorMessage}
                  />
                )}
              </Label>
            </FormControl>
          </div>
          <div className={paymentFormStyles.submitRow}>
            <img
              src="https://d2rdpmg2nfb82t.cloudfront.net/powered_by_stripe.svg"
              alt="Payments powered by Stripe"
            />
            <Button type="submit" btnStyle="primary">
              Update Card
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export class PaymentFormWithContext extends React.Component {
  constructor(props) {
    super(props);

    this.validateStripeField = this.validateStripeField.bind(this);
  }

  /**
   * Verify that the stripe field has a value, as well as that the stripe field
   * validation object indicates no errors
   * @param {*} elementType Identifier for the stripe field
   * @param {*} stripeField The Stripe change event object
   */
  validateStripeField(elementType, stripeField) {
    if (!stripeField || stripeField.empty) {
      if (elementType === 'cardNumber')
        return 'Card Number is a required field';
      if (elementType === 'cardCvc') return 'CVC is a required field';
      if (elementType === 'cardExpiry') return 'Exp Date is a required field';
    } else if (stripeField.error) {
      return stripeField.error.message;
    }
    return '';
  }

  render() {
    const { churchId } = this.props;
    const formConfig = {
      fields: {
        name: {
          value: '',
          validations: [
            {
              type: 'required',
              value: 'true',
              message: 'Cardholder Name is a required field'
            }
          ]
        },
        cardNumber: {
          validations: [
            {
              dynamicMessages: true,
              validatorFn: value => {
                return this.validateStripeField('cardNumber', value);
              }
            }
          ]
        },
        cardExpiry: {
          validations: [
            {
              dynamicMessages: true,
              validatorFn: value => {
                return this.validateStripeField('cardExpiry', value);
              }
            }
          ]
        },
        cardCvc: {
          validations: [
            {
              dynamicMessages: true,
              validatorFn: value => {
                return this.validateStripeField('cardCvc', value);
              }
            }
          ]
        }
      }
    };

    return (
      <FirebaseContext.Consumer>
        {firebase => (
          <ToastContext.Consumer>
            {({ showToast }) => (
              <Validator formConfig={formConfig}>
                {validator => (
                  <Mutation mutation={UPDATE_CHURCH_PAYMENT_INFO}>
                    {updateChurchPaymentInfo => (
                      <PaymentForm
                        firebase={firebase}
                        showToast={showToast}
                        validator={validator}
                        email={''}
                        churchId={churchId}
                        updateChurchPaymentInfo={updateChurchPaymentInfo}
                        {...this.props}
                      />
                    )}
                  </Mutation>
                )}
              </Validator>
            )}
          </ToastContext.Consumer>
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default injectStripe(PaymentFormWithContext);
