import React from 'react';

import styles from './password-checklist.module.css';

const PasswordChecklist = props => {
  const listClasses = {
    minLength: '',
    lowercase: '',
    uppercase: '',
    numbers: '',
    symbols: ''
  };

  for (let listClass in listClasses) {
    listClasses[listClass] = `
      ${styles.hintItem} ${props[listClass] ? styles.verified : ''}
    `;
  }

  return (
    <div className={styles.root}>
      <span className={styles.hintHeading}>Password must have</span>
      <ul className={styles.hintList}>
        <li id="minLengthVerified" className={listClasses.minLength}>
          At least 8 characters
        </li>
        <li id="lowercaseVerified" className={listClasses.lowercase}>
          Lowercase letters
        </li>
        <li id="uppercaseVerified" className={listClasses.uppercase}>
          Uppercase letters
        </li>
        <li id="numbersVerified" className={listClasses.numbers}>
          Numbers
        </li>
        <li id="symbolsVerified" className={listClasses.symbols}>
          Symbols
        </li>
      </ul>
    </div>
  );
};

export default PasswordChecklist;
