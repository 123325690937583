import React from 'react';

import styles from './select.module.css';

class Select extends React.Component {

  state = {
    isOpen: false
  }

  componentDidMount() {
    if (this.props.mountFn) {
      this.props.mountFn();
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      className,
      handleChange,
      mountFn,
      refFn,
      size,
      ...otherProps
    } = this.props;

    return (
      <div className={`${styles.selectWrapper} ${className}`}>
        <select
          className={`${styles.select} ${isOpen ? styles.close : styles.open} ${styles[size]}`}
          onChange={handleChange}
          ref={refFn}
          {...otherProps}
        >
          {this.props.children}
        </select>
      </div>
    );
  }
}

Select.defaultProps = {
  type: 'text',
  className: '',
  size: ''
};

export default Select;
