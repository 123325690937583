import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

class PieGraph extends React.Component {
  render() {
    const views = this.props.views;
    const responses = this.props.responses;
    const max = this.props.max;

    const viewOnly = views - responses;
    const maxRemaining = max - views;

    const data = [
      { name: 'Responses', value: responses },
      { name: 'Views', value: viewOnly },
      { name: 'Max', value: maxRemaining }
    ];

    var COLORS = [];
    var viewsStyle;
    var viewsSVGFill;
    var responsesSVGFill;

    if (views > max) {
      COLORS = ['#E96565', '#B93838', '#8A1818'];
      viewsStyle = { color: '#E96565' };
      viewsSVGFill = '#B93838';
      responsesSVGFill = '#B93838';
    } else {
      COLORS = ['#00C7B2', '#0179A7', '#F3F4F6'];
      viewsSVGFill = '#0179A7';
      responsesSVGFill = '#00C7B2';
    }

    return (
      <div>
        <PieChart width={160} height={160} onMouseEnter={this.onPieEnter}>
          <Pie
            cx={75}
            cy={75}
            data={data}
            innerRadius={53}
            outerRadius={75}
            fill="#8884d8"
            startAngle={270}
            endAngle={-90}
            key="piechart"
          >
            {data.map((entry, index) => (
              <Cell fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <div className="chartNumbers">
          <h3 style={viewsStyle}>
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill={viewsSVGFill}>
                <path d="M8.6264,13.538C11.7795,13.538,14.5445,11.5394,15.5735,8.6313C14.5445,5.7233,11.7795,3.7246,8.6264,3.7246C5.4733,3.7246,2.7083,5.7232,1.6793,8.6313C2.7083,11.5393,5.4733,13.538,8.6264,13.538ZM8.6264,2.725C12.3913,2.725,15.5754,5.2114,16.6264,8.6317C15.5754,12.052,12.3913,14.5384,8.6264,14.5384C4.8615,14.5384,1.6774,12.052,0.6264,8.6317C1.6774,5.2114,4.8615,2.725,8.6264,2.7245Z M8.5,11C9.8807,11,11,9.8807,11,8.5S9.8807,6,8.5,6S6,7.1193,6,8.5S7.1193,11,8.5,11ZM8.5,12C6.567,12,5,10.433,5,8.5C5,6.567,6.567,5,8.5,5C10.433,5,12,6.567,12,8.5C12,10.433,10.433,12,8.5,12Z" />
              </g>
            </svg>
            <b>{views}</b> Views
          </h3>
          <h3 style={viewsStyle} className="chartResponses">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill={responsesSVGFill}>
                <path d="M12.45 12H13c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2h5.06l1.74 2.1 1.65-2.1zM4 2h9c1.66 0 3 1.34 3 3v5c0 1.66-1.34 3-3 3h-.06l-2.12 2.68L8.6 13H4c-1.66 0-3-1.34-3-3V5c0-1.66 1.34-3 3-3z" />
              </g>
            </svg>
            <b>{responses}</b> Responses
          </h3>
        </div>
      </div>
    );
  }
}

export default PieGraph;
