import SoftBreak from 'slate-soft-break';

import TabKey from './TabKey';
import RenderMarks from './RenderMarks';
import { RenderNodes, RenderNodesReadonly } from './RenderNodes';
import { AddLinkPlugin, AddLinkPluginReadonly } from './AddLinkPlugin';
import { AddImagePlugin, AddImagePluginReadonly } from './AddImagePlugin';
import { DividerPlugin, DividerPluginReadonly } from './DividerPlugin';
import StickyToolbarPlugin from './StickyToolbarPlugin';
import ExitBlockOnEnterPlugin from './ExitBlockOnEnterPlugin';

const SlatePlugins = [
  DividerPlugin(),
  AddLinkPlugin(),
  AddImagePlugin(),
  ExitBlockOnEnterPlugin(),
  SoftBreak({ shift: true }),
  RenderNodes(),
  RenderMarks(),
  TabKey(),
  StickyToolbarPlugin()
];

export default SlatePlugins;

const SlatePluginsReadonly = [
  DividerPluginReadonly(),
  AddLinkPluginReadonly(),
  AddImagePluginReadonly(),
  RenderNodesReadonly(),
  RenderMarks()
];

export { SlatePluginsReadonly };
