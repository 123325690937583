import React from 'react';

import { IconBold, IconItalic, IconUnderline } from 'components/common/icons';

import { handleMarkChange } from './StickyToolbarEditorHandler';
import { MARK_TAGS } from '../../HtmlSerializer';
import { hasMarkType } from '../SlateUtils';
import PluginButton from '../components/PluginButton';

const IconComponents = {
  [MARK_TAGS.em]: IconItalic,
  [MARK_TAGS.strong]: IconBold,
  [MARK_TAGS.u]: IconUnderline
};

const MarkButton = ({ classConfig, type, editor, hoverText, hotkey }) => {
  const isActive = hasMarkType(type, editor);
  const Icon = IconComponents[type];

  return (
    <PluginButton
      classConfig={classConfig}
      hoverText={hoverText}
      hotkey={hotkey}
      isActive={isActive}
      onClick={event => {
        handleMarkChange(event, editor, type);
      }}
      Icon={Icon}
    />
  );
};

export default MarkButton;
