import React, { Component } from 'react';
import { withFirebase } from 'services/firebase';
import { withToastMessages } from 'components/common/ToastContainer';
import Dropzone from 'react-dropzone';
import { Button } from 'components/common/buttons';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import {
  DELETE_BULLETIN_HEADER_IMAGE,
  UPDATE_BULLETIN_HEADER_IMAGE
} from 'services/graphql/mutations';
import './HeaderImage.css';
import Loading from 'components/common/Loading';

export class HeaderImage extends Component {
  constructor(props) {
    super(props);

    this._onDrop = this._onDrop.bind(this);
    this._onDeleteHeaderClick = this._onDeleteHeaderClick.bind(this);
  }

  state = {
    loadingImage: false,
    uploadError: false,
    headerUrl: null
  };

  async componentDidMount() {
    const headerUrl = await this.props.firebase.calculateImageUrl(
      this.props.image
    );
    this.setState({ headerUrl });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      const headerUrl = await this.props.firebase.calculateImageUrl(
        this.props.image
      );
      this.setState({ headerUrl, loadingImage: false });
    }
  }

  async _calculateImageUrl(image) {
    const { firebase } = this.props;

    if (!image) return null;

    switch (image.urlSchema) {
      case 'HTTP_LINK':
        return image.url;
      case 'GCS_REF':
        return await firebase.storage.ref(image.url).getDownloadURL();
      default:
        return null;
    }
  }

  _onDrop(acceptedFiles, rejectedFiles) {
    const {
      updateBulletinHeaderImage,
      showToast,
      firebase,
      match: {
        params: { id }
      }
    } = this.props;

    if (acceptedFiles[0]) {
      this.setState({ loadingImage: true });

      const fileName = 'headerImage';
      const imageRef = firebase.churchStorageRef.child(`${id}/${fileName}`);
      const urlSchema = 'HTTP_LINK';
      const uploadTask = imageRef.put(acceptedFiles[0]);

      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
        },
        error => {
          // Handle unsuccessful uploads
          this.setState({ loadingImage: false, uploadError: true });
          showToast({
            type: 'error',
            content:
              'An error occurred when updating this bulletins header image. Please try again later.'
          });
        },
        async () => {
          // Handle successful uploads on complete
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          const { error } = await updateBulletinHeaderImage({
            variables: {
              id,
              fileName,
              url,
              urlSchema
            }
          });
          if (error) {
            showToast({
              type: 'error',
              content:
                'An error occurred when updating this bulletins header image. Please try again later.'
            });
          } else {
            showToast({
              type: 'success',
              content: 'Header image updated!'
            });
          }
        }
      );
    }
  }

  async _onDeleteHeaderClick(e) {
    try {
      this.setState({ loadingImage: true });
      await this.props.deleteBulletinHeaderImage({
        variables: { id: this.props.match.params.id }
      });
    } catch (ex) {
      // This is a workaround for this error: https://github.com/graphcool/graphcool-framework/issues/183
      const { bulletinRefetch } = this.props;
      bulletinRefetch();
    }
  }

  render() {
    const { image } = this.props;
    const { loadingImage, uploadError, headerUrl } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative'
        }}
      >
        {image && !loadingImage ? (
          <Button
            btnSize="xsmall"
            btnStyle="destructive"
            style={{
              top: '-5px',
              right: '-5px',
              position: 'absolute',
              zIndex: '100'
            }}
            onClick={this._onDeleteHeaderClick}
          >
            X
          </Button>
        ) : null}
        <Dropzone
          onDrop={this._onDrop}
          accept="image/jpeg, image/png"
          maxSize={3000000}
          className="headerImageWrapper"
          acceptClassName="headerImageWrapper-accepted"
        >
          <img
            src={
              image
                ? headerUrl
                : 'https://d2rdpmg2nfb82t.cloudfront.net/header-image-placeholder.jpg'
            }
            alt="header"
            className={
              loadingImage
                ? 'loadingHeaderImage'
                : uploadError
                ? 'uploadImageError'
                : null
            }
          />
        </Dropzone>
        {loadingImage ? <Loading /> : null}
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(UPDATE_BULLETIN_HEADER_IMAGE, {
    name: 'updateBulletinHeaderImage'
  }),
  graphql(DELETE_BULLETIN_HEADER_IMAGE, {
    name: 'deleteBulletinHeaderImage'
  }),
  withFirebase,
  withToastMessages
)(HeaderImage);
