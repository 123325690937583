import React from 'react';

import BlockWrapperMenu from './components/BlockWrapperMenu';
import { BLOCK_TAGS } from '../HtmlSerializer';
import { getSpacingClassname } from './BulletinSpaceComputer';

/* Basic block components */
const Heading1 = ({ children }) => <h1>{children}</h1>;

const Heading2 = ({ children }) => <h2>{children}</h2>;

const BulletedList = ({ children }) => <ul>{children}</ul>;

const NumberedList = ({ children }) => <ol>{children}</ol>;

const ListItem = ({ attributes, children }) => (
  <li {...attributes}>{children}</li>
);

const Blockquote = ({ children }) => <blockquote>{children}</blockquote>;

const Paragraph = ({ children }) => <p>{children}</p>;

/**
 * Returns the rendered block component based on the "type" value
 * @param {Object} props Props passed by Slate
 * @returns {Component} Rendered component
 */
const renderBlock = props => {
  switch (props.node.type) {
    case BLOCK_TAGS.blockquote:
      return <Blockquote {...props} />;
    case BLOCK_TAGS.ul:
      return <BulletedList {...props} />;
    case BLOCK_TAGS.h1:
      return <Heading1 {...props} />;
    case BLOCK_TAGS.h2:
      return <Heading2 {...props} />;
    case BLOCK_TAGS.li:
      return <ListItem {...props} />;
    case BLOCK_TAGS.ol:
      return <NumberedList {...props} />;
    default:
      return <Paragraph {...props} />;
  }
};

/**
 * Slate config for renderNodes in a readonly state
 * @returns {Object} Slate config with renderBlock function
 */
export function RenderNodesReadonly() {
  return {
    renderBlock: (props, editor) => {
      const block = renderBlock(props);

      if (props.node.type === BLOCK_TAGS.li) {
        return block;
      } else {
        const spacingClassname = getSpacingClassname(editor, props.node);
        return (
          <div className={spacingClassname} {...props.attributes}>
            {block}
          </div>
        );
      }
    }
  };
}

/**
 * Slate config for renderNodes in an editable state. Block components
 * are wrapped by BlockWrapperMenu, with the exception of "li".
 * @returns {Object} Slate config with renderBlock function
 */
export function RenderNodes() {
  return {
    renderBlock: (props, editor) => {
      const block = renderBlock(props);
      if (props.node.type === BLOCK_TAGS.li) {
        return block;
      } else {
        const spacingClassname = getSpacingClassname(editor, props.node);
        return (
          <div className={spacingClassname} {...props.attributes}>
            <BlockWrapperMenu {...props}>{block}</BlockWrapperMenu>
          </div>
        );
      }
    }
  };
}
