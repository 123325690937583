import React from 'react';

import FullViewMessage from '../FullViewMessage';
import tumbleweed from './tumbleweed.gif';

import styles from './NoLiveBulletin.module.css';

const NoLiveBulletin = () => (
  <FullViewMessage>
    <div className={styles.root}>
      <img src={tumbleweed} alt="" />
      <p className={styles.header}>Nothing to see here!</p>
      <p className={styles.description}>
        This week's bulletin has not been created yet. Check back in a little
        bit!
      </p>
    </div>
  </FullViewMessage>
);

export default NoLiveBulletin;
