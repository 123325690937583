import React from 'react';

import { Button } from 'components/common/buttons';
import Loading from 'components/common/Loading';
import BlockWrapperMenu from '../components/BlockWrapperMenu';
import { IconImgContained, IconImgFull } from 'components/common/icons';

import { withImagePlugin } from './AddImagePluginProvider';
import { EditorActionSVGButton } from '../components/EditorActionButton';

import styles from './AddImagePlugin.module.css';

export class ImageNode extends React.Component {
  constructor(props) {
    super(props);

    this.deleteImage = this.deleteImage.bind(this);
    this.setImageContained = this.setImageContained.bind(this);
    this.setImageFull = this.setImageFull.bind(this);
  }

  setImageContained(e) {
    e.preventDefault();
    this.props.addImagePlugin.setImageContained(this.props.node);
  }

  setImageFull(e) {
    e.preventDefault();
    this.props.addImagePlugin.setImageFull(this.props.node);
  }

  deleteImage(e) {
    e.preventDefault();
    this.props.addImagePlugin.deleteImage(this.props.node);
  }

  render() {
    const { data } = this.props.node;
    const src = data.get('src');
    const loading = data.get('loading');
    const display = data.get('display');
    const uploadError = data.get('uploadError');

    return (
      <BlockWrapperMenu
        className={`${styles.root} ${
          loading || uploadError ? styles.processing : ''
        } ${styles[display]}`}
        {...this.props}
        actions={[
          <EditorActionSVGButton
            onClick={this.setImageFull}
            aria-label="Set image full width"
            isActive={display === 'full'}
            className={styles.displayOption}
          >
            <IconImgFull width="20" height="20" />
          </EditorActionSVGButton>,
          <EditorActionSVGButton
            onClick={this.setImageContained}
            aria-label="Set image contained width"
            isActive={display === 'contained'}
            className={styles.displayOption}
          >
            <IconImgContained />
          </EditorActionSVGButton>
        ]}
      >
        {src && <img src={src} alt="" className={`${styles.img}`} />}
        {loading && <Loading />}
        {uploadError && (
          <div className={styles.errorMessage}>
            <p>Oops!</p>
            <p>
              Something went wrong while uploading your image. Please try again
              later.
            </p>
            <Button
              btnStyle="secondary"
              onClick={this.deleteImage}
              className={styles.errorMessageButton}
            >
              Dismiss
            </Button>
          </div>
        )}
      </BlockWrapperMenu>
    );
  }
}

export default withImagePlugin(ImageNode);
