import React from 'react';
import { Link } from 'react-router-dom';

import { IconArrowRight } from 'components/common/icons';
import { Tile } from 'components/layouts';
import NewTag from 'components/common/NewTag';
import { FeatureToggleListener } from 'services/firebase';
import CONFIG from 'config';
import styles from './account-nav.module.css';

const AccountNav = props => {
  return (
    <Tile El="nav" className={styles.accountNav}>
      <ul className={styles.ul}>
        <li className={styles.li}>
          <Link
            to="/account-settings/account"
            onClick={e => props.handleViewChange('account', e)}
            className={`${styles.navLink} ${
              props.currentView === 'account' ? styles.selected : ''
            }`}
          >
            Account
            <IconArrowRight height="20px" width="20px" />
          </Link>
        </li>
        <li className={styles.li}>
          <Link
            to="/account-settings/password"
            onClick={e => props.handleViewChange('password', e)}
            className={`${styles.navLink} ${
              props.currentView === 'password' ? styles.selected : ''
            }`}
          >
            Password
            <IconArrowRight height="20px" width="20px" />
          </Link>
        </li>
        {CONFIG.APPEARANCE_ENABLED ? (
          <li className={styles.li}>
            <Link
              to="/account-settings/appearance"
              onClick={e => props.handleViewChange('appearance', e)}
              className={`${styles.navLink} ${
                props.currentView === 'appearance' ? styles.selected : ''
              }`}
            >
              Appearance
              <IconArrowRight height="20px" width="20px" />
            </Link>
          </li>
        ) : null}
        <li className={styles.li}>
          <Link
            to="/account-settings/payment"
            onClick={e => props.handleViewChange('payment', e)}
            className={`${styles.navLink} ${
              props.currentView === 'payment' ? styles.selected : ''
            }`}
          >
            Payment
            <IconArrowRight height="20px" width="20px" />
          </Link>
        </li>
        <FeatureToggleListener featureToggleName="resources_page">
          <li className={styles.li}>
            <Link
              to="/account-settings/resources"
              onClick={e => props.handleViewChange('resources', e)}
              className={`${styles.navLink} ${
                props.currentView === 'resources' ? styles.selected : ''
              }`}
            >
              <div>
                Resources <NewTag />
              </div>
              <IconArrowRight height="20px" width="20px" />
            </Link>
          </li>
        </FeatureToggleListener>
      </ul>
    </Tile>
  );
};

export default AccountNav;
