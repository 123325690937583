import React, { useCallback, useEffect, useRef, useState } from 'react';
import Clipboard from 'clipboard';

import { Tooltip } from 'components/common/Tooltip';
import { Button } from 'components/common/buttons';
import { IconCheckmark } from 'components/common/icons';

import styles from './LiveBulletinLink.module.css';

const Success = () => (
  <span>
    <IconCheckmark width={'15'} /> Copied to clipboard
  </span>
);

const hoverMessage = 'Click to copy';
const defaultTooltipState = {
  content: hoverMessage,
  copying: false,
  show: false
};

const LiveBulletinLink = ({ serviceId }) => {
  const clipboard = useRef(null);
  const btnRef = useRef(null);
  const [tooltipDisplay, setTooltipDisplay] = useState(defaultTooltipState);

  const onSuccess = useCallback(() => {
    setTooltipDisplay({ displayed: true, copying: true, content: <Success /> });
    setTimeout(() => {
      setTooltipDisplay(defaultTooltipState);
    }, 2000);
  }, [setTooltipDisplay]);

  const onError = useCallback(() => {
    setTooltipDisplay({
      displayed: true,
      copying: true,
      content: `That didn't work. We'll open the link instead`
    });
    setTimeout(() => {
      setTooltipDisplay(defaultTooltipState);
      window.open(`${window.location.origin}/${serviceId}/live`, '_blank');
    }, 2000);
  }, [setTooltipDisplay, serviceId]);

  const hideTooltip = () => {
    if (!tooltipDisplay.copying) {
      setTooltipDisplay(defaultTooltipState);
    }
  };

  useEffect(() => {
    clipboard.current = new Clipboard(btnRef.current);
    clipboard.current.on('success', onSuccess);
    clipboard.current.on('error', onError);
  }, [onSuccess, onError]);

  return (
    <p className={styles.root}>
      Copy your
      <Button
        id="liveBulletinLink"
        aria-describedby="liveBulletinLink-tooltip"
        ref={btnRef}
        btnStyle="styleless"
        className={styles.button}
        data-clipboard-text={`${window.location.origin}/${serviceId}/live`}
        onMouseDown={() =>
          setTooltipDisplay({
            displayed: true,
            copying: true,
            content: tooltipDisplay.content
          })
        }
        onMouseEnter={() => {
          setTooltipDisplay({
            displayed: true,
            copying: tooltipDisplay.copying,
            content: hoverMessage
          });
        }}
        onFocus={() =>
          setTooltipDisplay({
            displayed: true,
            copying: tooltipDisplay.copying,
            content: hoverMessage
          })
        }
        onMouseLeave={hideTooltip}
        onBlur={hideTooltip}
      >
        live bulletin weblink!
      </Button>
      {tooltipDisplay.displayed && (
        <Tooltip
          id="liveBulletinLink"
          placement="bottom"
          theme="dark"
          small={true}
          triggerRect={btnRef.current.getBoundingClientRect()}
        >
          {tooltipDisplay.content}
        </Tooltip>
      )}
    </p>
  );
};

export default LiveBulletinLink;
