import React from 'react';

import Label from 'components/common/form/Label';
import Input from 'components/common/form/inputs/Input';
import FormControl from 'components/common/form/FormControl';
import PasswordChecklist from './PasswordChecklist';
import withTooltip from 'components/common/Tooltip';

import passwordStyles from '../password-section.module.css';
import newPasswordStyles from './new-password-input.module.css';

const InputWithTooltip = withTooltip(Input, {
  className: newPasswordStyles.tooltip
});

class NewPasswordInput extends React.Component {
  constructor(props) {
    super(props);

    this.validateMinLength = this.validateMinLength.bind(this);
    this.validateUppercase = this.validateUppercase.bind(this);
    this.validateLowercase = this.validateLowercase.bind(this);
    this.validateNumbers = this.validateNumbers.bind(this);
    this.validateSymbols = this.validateSymbols.bind(this);
  }

  validateMinLength() {
    return this.props.formField.value.match(/.{8,}/);
  }

  validateUppercase() {
    return this.props.formField.value.match(/[A-Z]+/);
  }

  validateLowercase() {
    return this.props.formField.value.match(/[a-z]+/);
  }

  validateSymbols() {
    return this.props.formField.value.match(/[!-/:-@[-`{-~]/);
  }

  validateNumbers() {
    return this.props.formField.value.match(/\d+/);
  }

  render() {
    return (
      <FormControl
        className={`${passwordStyles.fieldRow} ${newPasswordStyles.root}`}
      >
        <Label htmlFor="newPassword" className={passwordStyles.label}>
          New Password
        </Label>
        <InputWithTooltip
          id="newPassword"
          name="newPassword"
          type="password"
          size="large"
          {...this.props}
          tooltipContent={
            <PasswordChecklist
              minLength={this.validateMinLength()}
              lowercase={this.validateLowercase()}
              uppercase={this.validateUppercase()}
              numbers={this.validateNumbers()}
              symbols={this.validateSymbols()}
            />
          }
        />
      </FormControl>
    );
  }
}

export default NewPasswordInput;
