import React from 'react';

import styles from './AddLinkPlugin.module.css';

function Link(props) {
  const { attributes, children, node } = props;
  const { data } = node;
  const href = data.get('href');
  return (
    <a {...attributes} href={href} className={styles.anchor}>
      {children}
    </a>
  );
}

function LinkPlaceholder(props) {
  return (
    <span {...props.attributes} style={{ background: '#c2c9cb' }}>
      {props.children}
    </span>
  );
}

export { Link, LinkPlaceholder };
