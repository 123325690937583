import React from 'react';

import BlockWrapperMenu from '../components/BlockWrapperMenu';

import styles from './DividerNode.module.css';

const DividerNode = ({ children, ...rest }) => {
  return (
    <BlockWrapperMenu {...rest}>
      <div
        className={`${styles.root} ${
          rest.isSelected && rest.isFocused ? styles.selected : ''
        }`}
      >
        <hr className={styles.hr} />
      </div>
    </BlockWrapperMenu>
  );
};

export default DividerNode;
