import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Mutation } from 'react-apollo';

import CONFIG from 'config';
import { PropsRoute } from 'components/routing/CustomRoutes';
import { FirebaseContext } from 'services/firebase';
import { UPDATE_SELECTED_SERVICE } from 'services/graphql/local-mutations';
import NavBar from 'components/common/NavBar';
import Loading from 'components/common/Loading';
import { PageWrapper, PageInner } from 'components/layouts';
import { LOGGED_IN_USER_QUERY } from 'scenes/CommonApi';

import Breadcrumbs from './Breadcrumbs';
import Bulletins from './Bulletins';
import EditBulletin from './EditBulletin';
import EditConnectCard from './EditConnectCard';

import 'react-datetime/css/react-datetime.css';
import 'css/datetime.css';

export default class ConsoleWithDataAndContext extends Component {
  render() {
    const { queryResponse } = this.props;
    return (
      <FirebaseContext.Consumer>
        {firebase => (
          <Mutation mutation={UPDATE_SELECTED_SERVICE}>
            {updateSelectedService => {
              // This check for loading makes sure the loading indicator doesn't
              // render in the following cases:
              // 1) "Load More" was clicked (a fetchMore networkStatus is 3)
              // 2) We're navigating from planned -> past bulletins or past ->
              // planned bulletins. The data attribute will only contain a loggedInUser
              // if the BULLETINS_PAGE_QUERY has already been executed.
              if (
                queryResponse.loading &&
                queryResponse.networkStatus !== 3 &&
                !queryResponse.data.loggedInUser
              ) {
                // Basic user data is in the cache if we've made it to this point.
                let user = null;
                try {
                  user = queryResponse.client.readQuery({
                    query: LOGGED_IN_USER_QUERY
                  }).loggedInUser;
                } catch (e) {}
                return (
                  <PageWrapper>
                    <NavBar homeChurch={user.adminOf.name} />
                    <Loading />
                  </PageWrapper>
                );
              }

              return (
                <Console
                  queryResponse={queryResponse}
                  updateSelectedService={updateSelectedService}
                  firebase={firebase}
                />
              );
            }}
          </Mutation>
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export class Console extends Component {
  componentDidMount() {
    const { updateSelectedService, firebase, queryResponse } = this.props;
    const { adminOf: church } = queryResponse.data.loggedInUser;
    const selectedServiceIndex = queryResponse.data.selectedService.index;
    updateSelectedService({
      variables: {
        index: selectedServiceIndex,
        id: church.services[selectedServiceIndex].id
      }
    });

    firebase.initializeChurchStorageRef(`user-images/${church.id}`);
  }

  render() {
    const { queryResponse } = this.props;
    return (
      <PageWrapper>
        <NavBar homeChurch={queryResponse.data.loggedInUser.adminOf.name} />
        <PageInner>
          <Breadcrumbs />
          <Switch>
            <PropsRoute
              exact
              path="/dashboard/bulletins/edit/:id"
              component={EditBulletin}
              queryResponse={queryResponse}
            />
            <Route
              exact
              path="/dashboard/bulletins/edit/:id/response-card"
              component={EditConnectCard}
            />
            <PropsRoute
              exact
              path={
                CONFIG.PAST_BULLETINS_ENABLED
                  ? '/dashboard/(bulletins|pastBulletins)'
                  : '/dashboard/bulletins'
              }
              component={Bulletins}
              queryResponse={queryResponse}
            />
          </Switch>
        </PageInner>
      </PageWrapper>
    );
  }
}
