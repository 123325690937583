import React from 'react';

import styles from './error-message.module.css';

const ErrorMessage = props => {
  const { message, ...otherProps } = props;
  return (
    <span className={`${styles.errorMessage}`} {...otherProps}>
      {message}
    </span>
  );
};

export default ErrorMessage;
