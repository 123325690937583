import React from 'react';

import { FlexRight } from 'components/layouts';
import styles from '../buttons.module.css';

/**
 * Button Component
 * @param {*} props
 *  float:
 */
const ButtonGroup = props => {
  let Container = props.flexRight ? FlexRight : 'div';
  return <Container className={styles.buttonGroup}>{props.children}</Container>;
};

export default ButtonGroup;
