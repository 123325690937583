import React from "react";

import styles from "./TableHeader.module.css";

const TableHead = props => {
  const { children, className } = props;
  return <th scope="col" className={`${styles.th} ${className}`}>{children}</th>
};

TableHead.defaultProps = {
  className: ''
};

export default TableHead;