import React from 'react';

import { AddLinkPluginContext } from './AddLinkPlugin';
import { IconLink } from 'components/common/icons';
import PluginButton from '../components/PluginButton';
import { getCmdShiftHotkeyTest, getCmdShiftHotkeyLabel } from '../SlateUtils';

const isLinkHotKey = getCmdShiftHotkeyTest('mod+shift+1');

export class AddLinkButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleHotkey = this.handleHotkey.bind(this);
  }

  componentDidMount() {
    document
      .getElementById('editor')
      .addEventListener('keydown', this.handleHotkey);
  }

  componentWillUnmount() {
    document
      .getElementById('editor')
      .removeEventListener('keydown', this.handleHotkey);
  }

  handleHotkey(e) {
    if (!isLinkHotKey(e)) return;

    e.preventDefault();
    this.props.addLinkPlugin.onClickLinkButton(e);
  }

  render() {
    const {
      errorMessage,
      onClickLinkButton,
      isButtonActive
    } = this.props.addLinkPlugin;
    return (
      <PluginButton
        {...this.props}
        errorMessage={errorMessage}
        onClick={onClickLinkButton}
        isActive={isButtonActive}
        hoverText="Link"
        hotkey={getCmdShiftHotkeyLabel('1')}
        Icon={IconLink}
      />
    );
  }
}

export default function AddLinkButtonWithContext(props) {
  return (
    <AddLinkPluginContext.Consumer>
      {({ addLinkPlugin }) => (
        <AddLinkButton {...props} addLinkPlugin={addLinkPlugin} />
      )}
    </AddLinkPluginContext.Consumer>
  );
}
