import { IconLive } from 'components/common/icons';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import styles from '../ProfileAndServices.module.css';
import './liveIcon.css';

class NoLiveBulletinDisplay extends Component {
  render() {
    return (
      <div className={styles.liveBulletin}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 17 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke="#EAEAEA" fill="none" fillRule="evenodd">
            <circle cx="8.5" cy="8.5" r="1.5" />
            <path d="M10.96 6C11.6 6.65 12 7.54 12 8.5c0 1.03-.44 1.95-1.15 2.6m-4.6.07C5.47 10.53 5 9.57 5 8.5c0-.97.4-1.84 1.03-2.47m6.34-1.43c1 1 1.63 2.37 1.63 3.9 0 1.58-.67 3-1.73 4m-7.45.1C3.7 11.57 3 10.1 3 8.5c0-1.52.62-2.9 1.6-3.9" />
            <path d="" />
            <path d="M13.8 3.18C15.14 4.54 16 6.42 16 8.5c0 2.13-.9 4.06-2.32 5.42m-10.27.1C1.95 12.62 1 10.67 1 8.5c0-2.07.84-3.95 2.2-5.3" />
          </g>
        </svg>
        <div className={styles.bulletinTitleAndDate}>
          <h3>No Live Bulletin</h3>
        </div>
      </div>
    );
  }
}

class LiveBulletinDisplay extends Component {
  render() {
    const { liveBulletin } = this.props;
    return (
      <div className={styles.liveBulletin}>
        <IconLive flashing={true} height="30px" width="30px" />
        <div className={styles.bulletinTitleAndDate}>
          <h3>{liveBulletin.title}</h3>
          <p>{moment(liveBulletin.date).format('MMMM D, YYYY')}</p>
        </div>
      </div>
    );
  }
}

class LiveBulletin extends Component {
  render() {
    const { liveBulletinForService } = this.props;
    return liveBulletinForService ? (
      <LiveBulletinDisplay liveBulletin={liveBulletinForService} />
    ) : (
      <NoLiveBulletinDisplay />
    );
  }
}

export default LiveBulletin;
