/**
 * Inserts a divider into the document. If the end of the current selection is
 * in a block with no text (ie, a blank line), the function adds the divider
 * in place of the block. If the end of the current selection is in a block with
 * text, a divider is added after that block of text.
 * @param {Object} editor Slate Editor
 */
const insertDivider = editor => {
  const path = editor.value.selection.end.get('path');
  if (editor.value.document.getNode(path).get('text').length === 0) {
    editor.setBlocks({ type: 'divider' });
  } else {
    editor.moveToEndOfBlock().insertBlock({ type: 'divider' });
  }

  editor
    .select(editor.value.selection)
    .moveToEndOfBlock()
    .insertBlock({ type: 'paragraph' })
    .focus();
};

export default {
  insertDivider
};
