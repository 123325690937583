import gql from 'graphql-tag';
import BulletinsApi from 'scenes/Console/Bulletins/BulletinsApi';
import EditBulletinApi from 'scenes/Console/EditBulletin/EditBulletinApi';

export const BULLETINS_PAGE_QUERY = gql`
  query BULLETINS_PAGE_QUERY(
    $shouldIncludeBaseData: Boolean! = true
    $shouldIncludePlannedBulletins: Boolean! = true
    $shouldIncludePastBulletins: Boolean! = false
    $skip: Int! = 0
    $limit: Int! = 10
  ) {
    loggedInUser {
      id @include(if: $shouldIncludeBaseData)
      role @include(if: $shouldIncludeBaseData)
      adminOf {
        id @include(if: $shouldIncludeBaseData)
        name @include(if: $shouldIncludeBaseData)
        ...BulletinsChurch
      }
    }
    selectedService @client {
      id
      index
    }
  }
  ${BulletinsApi.fragments.church}
`;

export const EDIT_BULLETIN_PAGE_QUERY = gql`
  query EDIT_BULLETIN_PAGE_QUERY($id: ID!) {
    loggedInUser {
      id
      role
      adminOf {
        id
        name
        services {
          id
        }
      }
    }
    bulletin(id: $id) {
      ...EditBulletin
    }
    selectedService @client {
      id
      index
    }
  }
  ${EditBulletinApi.fragments.bulletin}
`;

export const SINGLE_BULLETIN_QUERY = gql`
  query SINGLE_BULLETIN_QUERY($id: ID!) {
    bulletin(id: $id) {
      ...EditBulletin
    }
  }
  ${EditBulletinApi.fragments.bulletin}
`;

export const mergeBulletinList = (oldData, newBulletins, listName) => {
  return {
    ...oldData,
    loggedInUser: {
      ...oldData.loggedInUser,
      adminOf: {
        ...oldData.loggedInUser.adminOf,
        services: [
          {
            ...oldData.loggedInUser.adminOf.services[0],
            [listName]: [
              ...oldData.loggedInUser.adminOf.services[0][listName],
              ...newBulletins
            ]
          }
        ]
      }
    }
  };
};

export const getPageQuery = ({ location }) => {
  const { pathname, state } = location;
  let query = null;
  if (pathname.match('bulletins/edit')) {
    query = {
      query: EDIT_BULLETIN_PAGE_QUERY,
      variables: {
        shouldIncludeBaseData: true,
        id: state && state.id
      }
    };
  } else if (pathname.match(/bulletins|pastBulletins/)) {
    query = {
      query: BULLETINS_PAGE_QUERY,
      variables: {
        shouldIncludeBaseData: true,
        shouldIncludePlannedBulletins: pathname === '/dashboard/bulletins',
        shouldIncludePastBulletins: pathname === '/dashboard/pastBulletins',
        skip: 0
      },
      notifyOnNetworkStatusChange: true
    };
  }

  return query;
};

export default {
  getPageQuery,
  queries: {
    BULLETINS_PAGE_QUERY,
    EDIT_BULLETIN_PAGE_QUERY,
    SINGLE_BULLETIN_QUERY
  }
};
