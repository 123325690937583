import { Inline } from 'slate';

const setLinkTooltipActive = editor => {
  editor.wrapInline({ type: 'link-placeholder' }).blur();

  return editor;
};

const getCurrentLinkData = editor => {
  return editor.value.inlines.reduce(
    (accum, inline) => {
      if (inline.type !== 'link') return accum;

      const href = inline.data.get('href');
      if (href.startsWith('mailto:')) {
        return { type: 'email', link: href.substring(7) };
      } else if (href.startsWith('tel:')) {
        return { type: 'phone', link: href.substring(4) };
      } else {
        return { type: 'web', link: href };
      }
    },
    { link: '', type: 'web' }
  );
};

const setCurrentLinkData = (editor, linkData) => {
  const inline = editor.value.inlines.get(0);

  return editor.setInlines(
    new Inline({
      ...inline.toJSON(),
      data: {
        href: formatLink(linkData)
      }
    })
  );
};

const hasLink = editor => {
  return (
    editor.value.inlines.filter(inline => inline.type === 'link').size === 1
  );
};

const unwrapLink = editor => {
  editor.unwrapInline('link');
  return editor;
};

const blurLinkForm = (editor, resetRange, prevSelection) => {
  if (!hasLink(editor)) {
    editor.unwrapInline('link-placeholder');
    editor.deselect();
  }
  if (resetRange) {
    editor.select(prevSelection);
    editor.focus();
  }
  return editor;
};

/**
 * Adds protocol to the link (http://, tel:, mailto:)
 * @param {*} linkData
 */
const formatLink = linkData => {
  const { link, type } = linkData;
  if (type === 'web' && !link.startsWith('http')) {
    return `http://${link}`;
  } else if (type === 'email') {
    return `mailto:${link}`;
  } else if (type === 'phone') {
    return `tel:${link}`;
  }
  return link;
};

const submitLinkForm = (editor, linkData, prevSelection) => {
  const currentValue = getCurrentLinkData(editor).link;
  if (currentValue) {
    return setCurrentLinkData(editor, linkData)
      .moveToEndOfInline()
      .focus();
  } else {
    return editor
      .unwrapInline('link-placeholder')
      .select(prevSelection)
      .wrapInline({
        type: 'link',
        data: {
          href: formatLink(linkData)
        }
      })
      .moveToEndOfInline()
      .focus();
  }
};

const AddLinkEditorHandler = {
  formatLink,
  getCurrentLinkData,
  hasLink,
  setLinkTooltipActive,
  unwrapLink,
  blurLinkForm,
  submitLinkForm
};

export default AddLinkEditorHandler;
