import React from 'react';
import { Transition, animated } from 'react-spring/renderprops.cjs';

import Toast from './Toast';

import styles from './toast-container.module.css';

export const ToastContext = React.createContext();

const TOAST_TYPES = ['error', 'info', 'success', 'warning'];

class ToastContainer extends React.Component {
  constructor(props) {
    super(props);

    this.showToast = this.showToast.bind(this);
    this.closeToast = this.closeToast.bind(this);

    this.state = {
      toasts: []
    };
  }

  showToast(toast) {
    if (!TOAST_TYPES.find(type => type === toast.type)) {
      console.error(
        `Make sure the toast type is set to one of: ${TOAST_TYPES.join(', ')}`
      );
      return;
    }
    toast.id = Date.now(); // Quick way to get a unique ID for the toast
    this.state.toasts.unshift(toast);
    this.setState({
      toasts: this.state.toasts
    });
    setTimeout(() => {
      this.closeToast(toast.id);
    }, 4000);
  }

  closeToast(id) {
    this.setState({
      toasts: this.state.toasts.filter(toast => {
        return toast.id !== id;
      })
    });
  }

  render() {
    const { showToast } = this;
    const { toasts } = this.state;

    return (
      <ToastContext.Provider value={{ showToast }}>
        {this.props.children}
        <ul className={styles.root}>
          <Transition
            native
            items={toasts}
            keys={toasts.map(toast => toast.id)}
            from={{ opacity: 0, transform: 'translate3d(-400px, 0, 0)' }}
            enter={{ opacity: 1, transform: 'translate3d(0px, 0, 0)' }}
            leave={{ opacity: 0, transform: 'translate3d(-400px, 0, 0)' }}
          >
            {toast => styles => {
              return (
                <animated.li
                  style={styles}
                  role={styles.destroyed ? undefined : 'alert'}
                >
                  <Toast toast={toast} closeToast={this.closeToast} />
                </animated.li>
              );
            }}
          </Transition>
        </ul>
      </ToastContext.Provider>
    );
  }
}

export const withToastMessages = Component => props => (
  <ToastContext.Consumer>
    {({ showToast }) => <Component {...props} showToast={showToast} />}
  </ToastContext.Consumer>
);

export default ToastContainer;
