import gql from 'graphql-tag';

export const fragments = {
  church: gql`
    fragment LocationForm on Church {
      addresses {
        id
        city
        state
        zip
        street1
        street2
      }
    }
  `
};

export default {
  fragments
};
