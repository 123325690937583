import React from 'react';
import Tile from 'components/layouts/Tile';
import { Link } from 'react-router-dom';

import './NoMatch.css';

export default class NoMatch extends React.Component {
  render() {
    return (
      <div id="no-match-container">
        <Tile withPadding id="no-match-404-tile">
          <h1 id="no-match-404">404</h1>
          <h1>Page Not Found!</h1>
        </Tile>
        <Link to="/">
          <Tile id="no-match-return-tile">
            <h3 id="no-match-return-text">Go back to Bulletn.co</h3>
          </Tile>
        </Link>
      </div>
    );
  }
}
