import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import RecoverEmail from './RecoverEmail';
import NoMatch from 'components/routing/NoMatch';

class PasswordReset extends Component {
  state = {
    invalidRequest: false,
    displayComponent: null
  };
  componentDidMount() {
    const { search } = this.props.location;
    const options = qs.parse(search);

    switch (options.mode) {
      case 'resetPassword':
        this.setState({
          displayComponent: <ResetPassword code={options.oobCode} />
        });
        break;
      case 'verifyEmail':
        this.setState({
          displayComponent: <VerifyEmail code={options.oobCode} />
        });
        break;
      case 'recoverEmail':
        this.setState({
          displayComponent: <RecoverEmail code={options.oobCode} />
        });
        break;
      default:
        this.setState({ displayComponent: <NoMatch /> });
        break;
    }
  }
  render() {
    const { displayComponent } = this.state;
    return displayComponent;
  }
}

export default withRouter(PasswordReset);
