import React from 'react';
import { Button } from 'components/common/buttons';
import { Link } from 'react-router-dom';

import { FlexRight } from 'components/layouts';

class SuccessScreen extends React.Component {
  render() {
    let {
      statusMessage,
      errorMessage,
      churchName,
      signupSuccessful,
      creditCardError,
      goBack
    } = this.props;
    return (
      <div className="success-screen-container">
        {signupSuccessful ? (
          <div className="success-screen-container">
            <h1>Welcome to Bulletn!</h1>
            <h2 className="success-screen-status">
              Go to the console for {churchName}
            </h2>
            <Link to="/dashboard/bulletins">
              <Button>Console</Button>
            </Link>
          </div>
        ) : (
          <h2 className={`success-screen-${errorMessage ? 'error' : 'status'}`}>
            {errorMessage ? errorMessage : statusMessage}
          </h2>
        )}
        {creditCardError ? (
          <FlexRight className="sign-up-fields-form-button-container">
            <Button
              btnStyle="secondary"
              className="sign-up-previous"
              onClick={e => goBack()}
            >
              Go Back
            </Button>
          </FlexRight>
        ) : null}
      </div>
    );
  }
}

export default SuccessScreen;
