import React from 'react';

import styles from './ConsoleHeader.module.css';

const ConsoleHeader = props => {
  const Icon = props.icon;
  return (
    <div className={styles.consoleHeader}>
      <Icon width="40" height="40" />
      {props.children}
    </div>
  );
};

const ConsoleHeaderContainer = props => {
  return <div className={styles.consoleHeaderContainer}>{props.children}</div>;
};

export { ConsoleHeader, ConsoleHeaderContainer };
