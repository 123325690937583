import React from 'react';

import { Button, ButtonGroup } from 'components/common/buttons';
import { Tile } from 'components/layouts';
import Label from 'components/common/form/Label';
import Input from 'components/common/form/inputs/Input';
import FormControl from 'components/common/form/FormControl';
import NewPasswordInput from './NewPasswordInput';
import Validator from 'components/common/form/Validator';
import { ToastContext } from 'components/common/ToastContainer';
import FirebaseContext from 'services/firebase/context';
import ReauthModal from '../ReauthModal';

import passwordStyles from './password-section.module.css';
import accountStyles from '../account-settings.module.css';

export class Password extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.reauthCompleted = this.reauthCompleted.bind(this);
  }

  state = {
    reauthNeeded: false
  };

  async handleSubmit(e) {
    const { validator, firebase } = this.props;

    e.preventDefault();

    if (!validator.validate()) {
      return;
    }

    let { newPassword } = validator.form.fields;

    try {
      await firebase.passwordUpdate(newPassword.value);

      this.props.showToast({
        type: 'success',
        content: 'Password successfully updated!'
      });
      validator.resetForm();
    } catch (e) {
      // Show a toast for unexpected messages
      if (e.code === 'auth/requires-recent-login') {
        this.setState({ reauthNeeded: true });
      } else {
        this.props.showToast({
          type: 'error',
          content:
            'There was an issue updating your password. Please try again later'
        });
      }
    }
  }

  reauthCompleted() {
    this.setState({ reauthNeeded: false });
  }

  render() {
    const { validator } = this.props;
    const { reauthNeeded } = this.state;
    const { handleSubmit } = this;

    return (
      <Tile withPadding={true} className={`password-section`}>
        <h2 className={accountStyles.h2}>Password</h2>
        <div className={accountStyles.content}>
          <p className={accountStyles.contentDescription}>
            Change your password or recover your current one
          </p>
          <form onSubmit={handleSubmit} noValidate>
            <NewPasswordInput
              formField={validator.form.fields.newPassword}
              onChange={validator.handleChange}
            />
            <FormControl className={passwordStyles.fieldRow}>
              <Label htmlFor="confirmPassword" className={passwordStyles.label}>
                Verify Password
              </Label>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                size="large"
                formField={validator.form.fields.confirmPassword}
                handleChange={validator.handleChange}
              />
            </FormControl>
            <div
              className={`${passwordStyles.fieldRow} ${
                passwordStyles.formOffset
              }`}
            >
              <ButtonGroup>
                <Button type="submit" btnStyle="primary">
                  Save Changes
                </Button>
                <Button btnStyle="secondary">Cancel</Button>
              </ButtonGroup>
            </div>
          </form>
        </div>
        <ReauthModal
          show={reauthNeeded}
          reauthCompleted={this.reauthCompleted}
          attributeUnderChange="password"
        />
      </Tile>
    );
  }
}

export default class PasswordWithData extends React.Component {
  render() {
    const formConfig = {
      fields: {
        newPassword: {
          value: '',
          validations: [
            {
              type: 'required',
              value: 'true',
              message: 'New Password is a required field'
            },
            {
              type: 'pattern',
              value: /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}/,
              message: 'Password does not meet requirements'
            }
          ]
        },
        confirmPassword: {
          value: '',
          validations: [
            {
              type: 'required',
              value: 'true',
              message: 'Verify Password is a required field'
            },
            {
              type: 'match',
              value: 'newPassword',
              message: 'Passwords must match'
            }
          ]
        }
      }
    };

    return (
      <ToastContext.Consumer>
        {({ showToast }) => (
          <Validator formConfig={formConfig}>
            {validator => (
              <FirebaseContext.Consumer>
                {firebase => (
                  <Password
                    validator={validator}
                    firebase={firebase}
                    showToast={showToast}
                    {...this.props}
                  />
                )}
              </FirebaseContext.Consumer>
            )}
          </Validator>
        )}
      </ToastContext.Consumer>
    );
  }
}
