import React from 'react';

import styles from '../buttons.module.css';

/**
 * Button Component
 * @param {*} props
 *  btnSize: xsmall, xsmallFixed, small, smallFixed, medium, mediumFixed (default: medium)
 *  btnStyle: styleless, primary, secondary, tertiary, destructive (default: primary)
 */
const Button = React.forwardRef((props, ref) => {
  let { btnSize, btnStyle, href, className, refFn, ...otherProps } = props;

  const sizeClass = btnStyle === 'styleless' ? '' : styles[btnSize];

  if (href) {
    return (
      <a
        className={`${styles.link} ${styles[btnStyle]} ${sizeClass} ${className}`}
        href={href}
        {...otherProps}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      className={`${styles[btnStyle]} ${sizeClass} ${className}`}
      ref={ref || refFn}
      {...otherProps}
    >
      {props.children}
    </button>
  );
});

Button.defaultProps = {
  btnStyle: 'primary',
  btnSize: 'medium',
  className: ''
};

export default Button;
