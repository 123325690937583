import React from 'react';

import { Tooltip } from 'components/common/Tooltip';

import { EditorActionSVGButton } from '../EditorActionButton';
import PluginButtonHotkeyTooltipText from './PluginButtonHotkeyTooltipText';

import styles from './PluginButton.module.css';

export default class PluginButton extends React.Component {
  constructor(props) {
    super(props);
    this.setHoverState = this.setHoverState.bind(this);
    this.state = {
      isHovered: false
    };
  }

  setHoverState(isHovered) {
    this.setState({ isHovered });
  }

  getButtonCoords() {
    return this.button.getBoundingClientRect();
  }

  render() {
    const {
      classConfig,
      errorMessage,
      hotkey,
      onClick,
      isActive,
      hoverText,
      Icon
    } = this.props;
    const { isHovered } = this.state;

    return (
      <React.Fragment>
        <EditorActionSVGButton
          classConfig={classConfig}
          isActive={isActive}
          onMouseEnter={() => this.setHoverState(true)}
          onMouseLeave={() => this.setHoverState(false)}
          ref={button => {
            this.button = button;
          }}
          onMouseDown={onClick}
        >
          <Icon width={24} />
        </EditorActionSVGButton>
        {isHovered && !errorMessage && (
          <Tooltip
            triggerRect={this.getButtonCoords()}
            placement={'top'}
            theme={'dark'}
            fixed={true}
            className={styles.tooltip}
          >
            <PluginButtonHotkeyTooltipText text={hoverText} hotkey={hotkey} />
          </Tooltip>
        )}
        {errorMessage && (
          <Tooltip
            triggerRect={this.getButtonCoords()}
            placement={'top'}
            theme={'dark'}
            fixed={true}
            className={styles.tooltip}
          >
            <span style={{ fontSize: '12px', color: 'white' }}>
              {errorMessage}
            </span>
          </Tooltip>
        )}
      </React.Fragment>
    );
  }
}
