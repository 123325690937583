import React, { Component } from 'react';
import { Tile, FlexRight } from 'components/layouts';
import { Button } from 'components/common/buttons';

import styles from './reset-password-form.module.css';

class ResetPasswordForm extends Component {
  state = {
    newPassword: ''
  };

  _onNewPasswordChange = e => {
    this.setState({ newPassword: e.target.value });
  };

  _onSubmit = e => {
    e.preventDefault();
    const { newPassword } = this.state;
    const { completePasswordReset } = this.props;

    if (this.validPassword()) {
      completePasswordReset(newPassword);
    }
  };

  validPassword() {
    let { newPassword } = this.state;
    return newPassword.length >= 8;
  }

  render() {
    const { email } = this.props;
    const { newPassword } = this.state;
    return (
      <Tile withPadding>
        <h1>Reset your password</h1>
        <h2>
          for <b>{email}</b>
        </h2>
        <form>
          <input
            type="password"
            value={newPassword}
            onChange={this._onNewPasswordChange}
            className={styles.resetPasswordInput}
            placeholder="New password"
          />
          <FlexRight>
            <Button type="submit" onClick={this._onSubmit}>
              Reset Password
            </Button>
          </FlexRight>
        </form>
      </Tile>
    );
  }
}

export default ResetPasswordForm;
