import React from 'react';

import styles from './form-control.module.css';

const FormControl = props => {
  let { labelTop, className } = props;
  let labelPosClass = labelTop ? styles.labelTop : '';

  return (
    <div className={`${styles.formControl} ${labelPosClass} ${className}`}>
      {props.children}
    </div>
  );
};

export default FormControl;
