import React from 'react';
import { MARK_TAGS } from '../HtmlSerializer';

export default function RenderMarks() {
  return {
    renderMark(props, editor, next) {
      switch (props.mark.type) {
        case MARK_TAGS.strong:
          return <BoldMark {...props} />;
        case MARK_TAGS.em:
          return <ItalicMark {...props} />;
        case MARK_TAGS.u:
          return <UnderlineMark {...props} />;
        default:
          return next();
      }
    }
  };
}

/* Mark React components */
function BoldMark(props) {
  return <strong {...props.attributes}>{props.children}</strong>;
}

function ItalicMark(props) {
  return <em {...props.attributes}>{props.children}</em>;
}

function UnderlineMark(props) {
  return <u {...props.attributes}>{props.children}</u>;
}
