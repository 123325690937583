import React from 'react';

import Tile from 'components/layouts/Tile';
import PastBulletinsHeader from './PastBulletinsHeader';
import PastBulletinsTable from './PastBulletinsTable';
import LoadMoreResponses from 'components/bulletins/LoadMoreBulletins';
import ViewBulletinModal from 'components/bulletins/ViewBulletinModal';

class Responses extends React.Component {
  render() {
    const { pastBulletins, fetchMore, loading, church } = this.props;
    return (
      <Tile>
        <PastBulletinsHeader />
        <PastBulletinsTable bulletins={pastBulletins} />
        <LoadMoreResponses
          church={church}
          fetchMore={fetchMore}
          loading={loading}
          bulletinListName="pastBulletins"
        />
        <ViewBulletinModal />
      </Tile>
    );
  }
}

export default Responses;
