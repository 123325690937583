import React from 'react';
import ReactDOM from 'react-dom';

import Tile from 'components/layouts/Tile';

import styles from './tooltip.module.css';

function withTooltip(Component, tooltipConfig) {
  tooltipConfig = tooltipConfig || {};

  return class Tooltipped extends React.Component {
    constructor(props) {
      super(props);

      this.showTooltip = this.showTooltip.bind(this);
      this.hideTooltip = this.hideTooltip.bind(this);

      this.state = {
        showTooltip: false
      };
    }

    showTooltip() {
      this.setState({
        showTooltip: true
      });
    }

    hideTooltip() {
      this.setState({
        showTooltip: false
      });
    }

    render() {
      const { handleMouseEnter, tooltipContent, ...otherProps } = this.props;

      return (
        <React.Fragment>
          <Component
            onBlur={this.hideTooltip}
            onMouseLeave={
              tooltipConfig.showOnHover ? this.hideTooltip : undefined
            }
            onFocus={this.showTooltip}
            onMouseEnter={e => {
              if (tooltipConfig.showOnHover) {
                this.showTooltip();
              }
              if (handleMouseEnter) {
                handleMouseEnter(e);
              }
            }}
            refFn={item => {
              this.triggerElement = item;
            }}
            aria-describedby={`${this.props.id}-tooltip`}
            {...otherProps}
          />
          {this.state.showTooltip ? (
            <Tooltip
              triggerRect={this.triggerElement.getBoundingClientRect()}
              id={this.props.id}
              {...tooltipConfig}
            >
              {this.props.tooltipContent}
            </Tooltip>
          ) : null}
        </React.Fragment>
      );
    }
  };
}

export default withTooltip;

export class Tooltip extends React.Component {
  componentDidMount() {
    this.configureDisplay();
  }

  componentDidUpdate() {
    this.configureDisplay();
  }

  /**
   * This function positions the tooltip horizontally when the `position` is set
   * to "top" or "bottom". It positions it so that the tooltip either lines up with
   * the left side of the trigger, the right side of the tooltip trigger, or is
   * centered with the tooltip trigger.
   */
  getHorizontalForTopBottom(triggerRect, tooltipRect, horizontal) {
    if (horizontal === 'left') {
      return triggerRect.left;
    } else if (horizontal === 'right') {
      return triggerRect.right - tooltipRect.width; // right
    } else {
      return triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
    }
  }

  configureDisplay() {
    const { triggerRect, fixed, horizontal, small } = this.props;
    const tooltipRect = this.tooltip.getBoundingClientRect();
    const tooltipEl = this.tooltip;
    const scrollY = window.scrollY;
    const offset = small ? 10 : 15;

    // Remove placement classNames
    let regex = new RegExp(
      `${styles.left}|${styles.right}|${styles.top}|${styles.bottom}`
    );
    tooltipEl.className = tooltipEl.className.replace(regex, '');

    if (this.props.placement === 'right') {
      let leftPosition = triggerRect.left + triggerRect.width + offset;
      tooltipEl.style.top = `${triggerRect.top +
        triggerRect.height / 2 -
        tooltipRect.height / 2 +
        (fixed ? 0 : scrollY)}px`;

      if (leftPosition + tooltipRect.width + offset > window.innerWidth) {
        tooltipEl.style.left = `${triggerRect.left -
          tooltipRect.width -
          offset}px`;
        tooltipEl.className += ` ${styles.left}`;
      } else {
        tooltipEl.style.left = `${leftPosition}px`;
        tooltipEl.className += ` ${styles.right}`;
      }
    } else if (this.props.placement === 'left') {
      let leftPosition = triggerRect.left - tooltipRect.width - offset;
      tooltipEl.style.top = `${triggerRect.top +
        triggerRect.height / 2 -
        tooltipRect.height / 2 +
        (fixed ? 0 : scrollY)}px`;

      if (leftPosition < 0) {
        tooltipEl.style.left = `${triggerRect.left +
          triggerRect.width +
          offset}px`;
        tooltipEl.className += ` ${styles.right}`;
      } else {
        tooltipEl.style.left = `${leftPosition}px`;
        tooltipEl.className += ` ${styles.left}`;
      }
    } else if (this.props.placement === 'top') {
      const topPosition =
        triggerRect.top - tooltipRect.height + (fixed ? 0 : scrollY) - offset;
      tooltipEl.style.left = `${this.getHorizontalForTopBottom(
        triggerRect,
        tooltipRect,
        horizontal
      )}px`;

      // If position is fixed, we don't factor in the scroll position
      // when determining whether we need to place the tooltip at the bottom
      // If it's not fixed, we do
      const flipCheck = fixed ? topPosition : topPosition - scrollY;

      if (flipCheck < 0) {
        tooltipEl.style.top = `${triggerRect.top +
          triggerRect.height +
          (fixed ? 0 : scrollY) +
          offset}px`;
        tooltipEl.className += ` ${styles.bottom}`;
      } else {
        tooltipEl.style.top = `${topPosition}px`;
        tooltipEl.className += ` ${styles.top}`;
      }
    } else {
      const topPosition =
        triggerRect.top + triggerRect.height + (fixed ? 0 : scrollY) + offset;
      tooltipEl.style.left = `${this.getHorizontalForTopBottom(
        triggerRect,
        tooltipRect,
        horizontal
      )}px`;

      // If position is fixed, we don't factor in the scroll position
      // when determining whether we need to place the tooltip at the bottom
      // If it's not fixed, we do
      const flipCheck = fixed
        ? topPosition + tooltipRect.height
        : topPosition + tooltipRect.height - scrollY;

      if (flipCheck > window.innerHeight) {
        tooltipEl.style.top = `${triggerRect.top -
          tooltipRect.height +
          (fixed ? 0 : scrollY) -
          offset}px`;
        tooltipEl.className += ` ${styles.top}`;
      } else {
        tooltipEl.style.top = `${topPosition}px`;
        tooltipEl.className += ` ${styles.bottom}`;
      }
    }
  }

  render() {
    const { className, fixed, small, theme } = this.props;
    return ReactDOM.createPortal(
      <Tile
        id={`${this.props.id}-tooltip`}
        role="tooltip"
        className={`${styles.root} ${styles[theme]} ${
          small ? styles.small : ''
        } ${className} ${fixed ? styles.fixed : ''}`}
        refFn={item => {
          this.tooltip = item;
        }}
      >
        {this.props.children}
      </Tile>,
      window.document.body
    );
  }
}

Tooltip.defaultProps = {
  horizontal: 'center',
  placement: 'right',
  className: '',
  theme: 'light',
  fixed: false,
  small: false
};
