import React from 'react';

import styles from './AddImagePlugin.module.css';

const ImageNodeReadonly = props => {
  const { attributes, node } = props;
  const { data } = node;
  const src = data.get('src');
  const display = data.get('display');

  return src ? (
    <div className={styles[`${display}Readonly`]} {...attributes}>
      <img src={src} alt="" className={`${styles.img}`} />
    </div>
  ) : null;
};

export default ImageNodeReadonly;
