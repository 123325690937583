import React from "react";

import styles from "./Fieldset.module.css";

const Fieldset = props => {
  const { className, children, ...rest } = props;
  return (
    <fieldset className={`${className}`} {...rest}>{children}</fieldset>
  );
};

Fieldset.defaultProps = {
  className: ""
};

const Legend = props => {
  const { className, children, ...rest } = props;
  return (
    <legend className={`${styles.legend} ${className}`} {...rest}>{children}</legend>
  );
};

Legend.defaultProps = {
  className: ""
};

export {
  Fieldset,
  Legend
}