import React from 'react';
import { Mutation } from 'react-apollo';

import {
  CREATE_ADDRESS_MUTATION,
  UPDATE_ADDRESS_MUTATION
} from 'services/graphql/mutations';
import { ACCOUNT_PAGE_QUERY } from 'scenes/AccountSettings/AccountSettingsApi';

import AccountForm from '../AccountForm';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import InputDynamic from 'components/common/form/inputs/dynamic/InputDynamic';
import Input from 'components/common/form/inputs/Input';
import Validator from 'components/common/form/Validator';
import { ToastContext } from 'components/common/ToastContainer';

import accountStyles from '../../account.module.css';

export class LocationForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormattedLocation = this.getFormattedLocation.bind(this);
    this.handleMount = this.handleMount.bind(this);
  }

  handleMount(handleEditableMount) {
    handleEditableMount(this.locationInput);

    // Initialize google autocomplete behavior
    this.autoComplete = new window.google.maps.places.Autocomplete(
      this.locationInput,
      { types: ['address'] }
    );
    this.autoComplete.addListener('place_changed', () => {
      this.props.validator.validate();
    });
    this.props.setValidatorAutoComplete(this.autoComplete);
  }

  async handleSubmit(updateAddressMutation, createAddressMutation, validator) {
    let place = this.autoComplete.getPlace();
    let newAddress = {
      city: '',
      state: '',
      zipCode: '',
      street1: ''
    };

    // If there's no place, but there's text in the location field, make the
    // user select a place instead of submitting a freehand address
    if (!this.props.validator.validate()) {
      return Promise.reject();
    }

    if (place && this.locationInput.value) {
      newAddress = this.mapPlaceToAddressObject(place);
    }

    let { city, state, zipCode, street1 } = newAddress;

    let { adminOf: church } = this.props.acctData;
    this.props.validator.resetForm();

    let updateResponse;

    try {
      if (church.addresses[0]) {
        let id = church.addresses[0].id;
        updateResponse = await this.props.updateAddressMutation({
          variables: { id, city, state, zipCode, street1 },
          optimisticResponse: {
            updateChurchLocation: {
              id,
              city,
              state,
              zip: zipCode,
              street1,
              __typename: 'Address'
            }
          }
        });
      } else {
        let churchId = church.id;
        updateResponse = await this.props.createAddressMutation({
          variables: { churchId, city, state, zipCode, street1 },
          optimisticResponse: {
            addChurchLocation: {
              id: -1,
              city,
              state,
              zip: zipCode,
              street1,
              __typename: 'Address'
            }
          }
        });
      }

      this.props.showToast({
        type: 'success',
        content: 'Location successfully updated!'
      });
    } catch (e) {
      updateResponse = e;
      this.props.showToast({
        type: 'error',
        content: 'There was an issue saving. Please try again later'
      });
    }

    return updateResponse;
  }

  /**
   * Takes a Place object, and returns a new object with all the fields mapped
   * to the field names our Address entity uses
   * @param {GooglePlace} place
   */
  mapPlaceToAddressObject(place) {
    if (!place) {
      return {
        street1: '',
        city: '',
        state: '',
        zipCode: ''
      };
    }

    let addressFieldMapper = {
      locality: 'city',
      administrative_area_level_1: 'state',
      postal_code: 'zipCode'
    };

    let mappedAddressData = {
      street1: place.name
    };

    return place.address_components
      .filter(component => {
        return component.types.filter(type => {
          return Object.keys(addressFieldMapper).filter(key => {
            return key === type;
          }).length;
        }).length;
      })
      .reduce((accum, component) => {
        component.types.forEach(type => {
          if (addressFieldMapper[type]) {
            accum[addressFieldMapper[type]] = component.short_name;
          }
        });
        return accum;
      }, mappedAddressData);
  }

  getFormattedLocation() {
    let address = this.props.acctData.adminOf.addresses[0];
    if (!address || address.zip === '') {
      return '';
    } else {
      return `${address.street1}, ${address.city}, ${address.state} ${address.zip}`;
    }
  }

  render() {
    const { handleSubmit, getFormattedLocation } = this;
    const { validator } = this.props;

    return (
      <AccountForm
        handleSubmit={handleSubmit}
        render={({ editable, handleMount }) => (
          <FormControl labelTop={true} className={accountStyles.inputContainer}>
            <Label htmlFor="location">Location</Label>
            <InputDynamic
              displayInput={editable}
              value={getFormattedLocation()}
            >
              <Input
                defaultValue={getFormattedLocation()}
                id="location"
                name="location"
                refFn={input => {
                  this.locationInput = input;
                }}
                formField={validator.form.fields.location}
                handleChange={validator.handleChange}
                placeholder="Start typing, then select your address"
                mountFn={() => this.handleMount(handleMount)}
              />
            </InputDynamic>
          </FormControl>
        )}
      />
    );
  }
}

export default class LocationFormWithData extends React.Component {
  constructor(props) {
    super(props);

    this.setValidatorAutoComplete = this.setValidatorAutoComplete.bind(this);
  }

  /**
   * Gets a reference to the Google Places autoComplete object to use for the
   * validateLocationField function
   * @param {*} autoComplete
   */
  setValidatorAutoComplete(autoComplete) {
    this.autoComplete = autoComplete;
  }

  validateLocationField(value) {
    // getPlace can return a stub object with just { name: 'text entered by user' }
    // so we need to make sure the getPlace() object is defined and has more fields
    // than just name
    const place = this.autoComplete.getPlace();
    return !(value && !(place && place.address_components));
  }

  render() {
    const formConfig = {
      fields: {
        location: {
          value: '',
          validations: [
            {
              type: 'placeSelected',
              message: 'Please select a location from the list of addresses',
              validatorFn: this.validateLocationField.bind(this)
            }
          ]
        }
      }
    };

    return (
      <ToastContext.Consumer>
        {({ showToast }) => (
          <Validator formConfig={formConfig}>
            {validator => (
              <Mutation
                mutation={CREATE_ADDRESS_MUTATION}
                refetchQueries={[
                  {
                    query: ACCOUNT_PAGE_QUERY
                  }
                ]}
              >
                {createAddressMutation => (
                  <Mutation mutation={UPDATE_ADDRESS_MUTATION}>
                    {updateAddressMutation => (
                      <LocationForm
                        updateAddressMutation={updateAddressMutation}
                        createAddressMutation={createAddressMutation}
                        validator={validator}
                        setValidatorAutoComplete={this.setValidatorAutoComplete}
                        showToast={showToast}
                        {...this.props}
                      />
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Validator>
        )}
      </ToastContext.Consumer>
    );
  }
}
