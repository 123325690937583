const tabCharacter = '     ';

export default function TabKey() {
  return {
    onKeyDown(event, change, next) {
      if (event.key !== 'Tab') return next();

      event.preventDefault();
      change.insertText(tabCharacter);
      return true;
    }
  };
}
