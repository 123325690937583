import gql from 'graphql-tag';

export const fragments = {
  church: gql`
    fragment PlanTierFormChurch on Church {
      planTier {
        id
        displayName
      }
    }
  `,
  activePlanTiers: gql`
    fragment PlanTierFormTiers on PlanTier {
      id
      displayName
      pricePerMonth
      congregantsMin
      congregantsMax
    }
  `
};

export default {
  fragments
};
