import React from 'react';

import {
  EditorTooltipButtonContainer,
  EditorTooltipDivider
} from '../../components/EditorTooltip';
import { EditorActionTextButton } from '../../components/EditorActionButton';

export const LinkActions = ({ addLinkPlugin, linkData }) => {
  return (
    <EditorTooltipButtonContainer>
      <EditorActionTextButton
        target="_blank"
        href={addLinkPlugin.formatLink(linkData)}
      >
        View Link
      </EditorActionTextButton>
      <EditorTooltipDivider />
      <EditorActionTextButton onClick={addLinkPlugin.onClickEditButton}>
        Edit URL
      </EditorActionTextButton>
    </EditorTooltipButtonContainer>
  );
};

export default LinkActions;
