import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import errorHandler from '../errorHandlerUtility';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.app = app;
    this.auth = app.auth();
    this.storage = app.storage();
    this.db = app.firestore();

    this.churchStorageRef = null;
  }

  signInWithEmailAndPassword = async (email, password) =>
    await this.auth.signInWithEmailAndPassword(email, password);

  signInWithCustomToken = async token =>
    await this.auth.signInWithCustomToken(token);

  signOut = async () => await this.auth.signOut();

  sendPasswordResetEmail = async email =>
    await this.auth.sendPasswordResetEmail(email);

  checkActionCode = async code => await this.auth.checkActionCode(code);

  applyActionCode = async code => await this.auth.applyActionCode(code);

  verifyPasswordResetCode = async code =>
    await this.auth.verifyPasswordResetCode(code);

  completePasswordReset = async (code, newPassword) =>
    await this.auth.confirmPasswordReset(code, newPassword);

  updateEmail = async email => await this.auth.currentUser.updateEmail(email);

  passwordUpdate = async password =>
    await this.auth.currentUser.updatePassword(password);

  getIdToken = async () => {
    try {
      return await this.auth.currentUser.getIdToken();
    } catch (ex) {
      return null;
    }
  };

  getCurrentUser = () => {
    return this.auth.currentUser;
  };

  generateNewCredential = (username, password) => {
    return this.app.auth.EmailAuthProvider.credential(username, password);
  };

  initializeChurchStorageRef = churchId => {
    this.churchStorageRef = this.storage.ref(churchId);
  };

  calculateImageUrl = async image => {
    if (!image) return null;

    switch (image.urlSchema) {
      case 'HTTP_LINK':
        return image.url;
      case 'GCS_REF':
        return await this.storage.ref(image.url).getDownloadURL();
      default:
        return null;
    }
  };

  isFeatureEnabled = async featureToggleName => {
    const featureTogglesRef = this.db.collection('feature_toggles');
    try {
      const featureToggle = await featureTogglesRef
        .doc(featureToggleName)
        .get();
      if (!featureToggle.exists) {
        return false;
      } else {
        return featureToggle.data().enabled;
      }
    } catch (ex) {
      errorHandler.report(ex);
      return false;
    }
  };
}

export default Firebase;
