import React, { useState, useRef, useEffect, useCallback } from 'react';

import { Tooltip } from 'components/common/Tooltip';
import { Button } from 'components/common/buttons';

import styles from './HelpTip.module.css';

const HelpTip = () => {
  const [tooltipDisplayed, setTooltipDisplayed] = useState(false);
  const [flyoutDisplayed, setFlyoutDisplayed] = useState(false);
  const [shouldKeepFlyoutOpenOnBlur, setShouldKeepFlyoutOpenOnBlur] = useState(
    false
  );
  const btnRef = useRef(null);
  const flyout = useRef(null);

  const handleMouseEnter = () => {
    if (flyoutDisplayed) return;
    setTooltipDisplayed(true);
  };

  const handleMouseLeave = () => {
    setTooltipDisplayed(false);
  };

  const handleFocus = () => {
    if (flyoutDisplayed) return;
    setTooltipDisplayed(true);
  };

  const handleBlur = () => {
    if (shouldKeepFlyoutOpenOnBlur) {
      setShouldKeepFlyoutOpenOnBlur(false);
      return;
    }
    setTooltipDisplayed(false);
    setFlyoutDisplayed(false);
  };

  // Track the mouseDown event instead of click since the event order is
  // mouseDown -> focus -> click. Focus shows the "Help" tip, but we want
  // the click to actually show the email flyout.
  const handleMouseDown = e => {
    setTooltipDisplayed(false);
    setFlyoutDisplayed(true);
  };

  // Since we're not tracking mouse click (which is fired by the enter key)
  // we need to explicitly bind the enter key to open the email flyout
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      setTooltipDisplayed(false);
      setFlyoutDisplayed(true);
    }
  };

  const handleCloseOffClick = useCallback(
    e => {
      if (flyoutDisplayed && !flyout.current.tooltip.contains(e.target)) {
        // Remove the flyout if the click target is outside the flyout
        setFlyoutDisplayed(false);
      } else if (flyoutDisplayed) {
        // If the click target is inside the flyout, then make sure when
        // the help button is blurred, that we don't remove the flyout
        setShouldKeepFlyoutOpenOnBlur(true);
      }
    },
    [flyoutDisplayed]
  );

  const getFlyoutRect = () => {
    // Adjust the positioning of the flyout by pushing it 15 px to the right
    const rect = btnRef.current.getBoundingClientRect();
    return {
      bottom: rect.bottom,
      height: rect.height,
      left: rect.left,
      right: rect.right + 15,
      top: rect.top,
      width: rect.width,
      x: rect.x,
      y: rect.y
    };
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseOffClick);

    return () => {
      document.removeEventListener('mousedown', handleCloseOffClick);
    };
  }, [handleCloseOffClick]);

  return (
    <React.Fragment>
      <Button
        id="support"
        aria-label="help"
        aria-describedby="support-flyout-tooltip"
        btnStyle="styleless"
        ref={btnRef}
        className={styles.supportBtn}
        onKeyPress={handleKeyPress}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        ?
      </Button>
      {tooltipDisplayed && (
        <Tooltip
          id="support"
          theme="dark"
          placement="bottom"
          horizontal="center"
          small={true}
          triggerRect={btnRef.current.getBoundingClientRect()}
        >
          Help
        </Tooltip>
      )}
      {flyoutDisplayed && (
        <Tooltip
          id="support-flyout"
          placement="bottom"
          horizontal="right"
          triggerRect={getFlyoutRect()}
          className={styles.supportTooltip}
          ref={flyout}
        >
          <p className={styles.supportParagraph1}>
            <span role="img" aria-label="Hmm">
              🤔
            </span>{' '}
            Need help?
          </p>
          <p className={styles.supportParagraph2}>
            Email us at <span className={styles.email}>support@bulletn.co</span>
          </p>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default HelpTip;
