import {
  Table,
  TableBody,
  TableHead,
  TableHeader
} from 'components/bulletins/BulletinsTable';
import {
  ActionsTableHeader,
  DateTableHeader
} from 'components/bulletins/BulletinTableRow';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import BulletinTableRow from './BulletinTableRow';

export default class PastBulletins extends Component {
  render() {
    const { bulletins } = this.props;
    if (!bulletins || bulletins.length === 0) return null;
    return (
      <Table>
        <TableHead>
          <DateTableHeader>Date</DateTableHeader>
          <TableHeader>Title</TableHeader>
          <ActionsTableHeader />
        </TableHead>
        <TableBody>
          {[]
            .concat(bulletins)
            .sort((a, b) => moment(b.date) - moment(a.date))
            .map(bulletin => (
              <BulletinTableRow
                date={moment(bulletin.date).format('MMM D, YYYY')}
                name={bulletin.title}
                key={bulletin.id}
                id={bulletin.id}
                viewCount={bulletin.viewCount}
              />
            ))}
        </TableBody>
      </Table>
    );
  }
}
