import React from 'react';

import PluginButton from '../components/PluginButton';
import { IconDivider } from 'components/common/icons';
import DividerPluginEditorHandler from './DividerPluginEditorHandler';
import { getCmdShiftHotkeyTest, getCmdShiftHotkeyLabel } from '../SlateUtils';

const isDividerHotkey = getCmdShiftHotkeyTest('8');

export default class DividerButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleHotkey = this.handleHotkey.bind(this);
  }

  componentDidMount() {
    document
      .getElementById('editor')
      .addEventListener('keydown', this.handleHotkey);
  }

  componentWillUnmount() {
    document
      .getElementById('editor')
      .removeEventListener('keydown', this.handleHotkey);
  }

  handleHotkey(e) {
    if (!isDividerHotkey(e)) return;

    e.preventDefault();
    this.handleClick(e);
  }

  handleClick(e) {
    e.preventDefault();
    DividerPluginEditorHandler.insertDivider(this.props.editor);
  }

  render() {
    return (
      <PluginButton
        {...this.props}
        onClick={this.handleClick}
        hoverText="Horizontal Divider"
        hotkey={getCmdShiftHotkeyLabel('8')}
        Icon={IconDivider}
      />
    );
  }
}
