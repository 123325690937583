import React from 'react';

import { Button } from 'components/common/buttons';

import styles from './EditorActionButton.module.css';

const config = {
  colorOnly: {
    baseClass: `${styles.button} ${styles.svgColorOnly}`,
    activeClass: styles.svgColorOnly
  },
  colorAndFill: {
    baseClass: `${styles.button} ${styles.svgColorAndFill}`,
    activeClass: `${styles.svgColorAndFillActive}`
  }
};

/**
 * Editor action button that styled for an SVG element as its child
 */
export const EditorActionSVGButton = React.forwardRef((props, ref) => {
  const { isActive, children, classConfig, className, ...rest } = props;
  const { baseClass, activeClass } = config[classConfig];

  return (
    <button
      ref={ref}
      className={`${baseClass} ${isActive ? activeClass : ''} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
});

EditorActionSVGButton.defaultProps = {
  classConfig: 'colorAndFill'
};

/**
 * Editor action button that styled for text as its child
 */
export const EditorActionTextButton = React.forwardRef((props, ref) => {
  const { isActive, children, className, ...rest } = props;
  return (
    <Button
      btnStyle="styleless"
      ref={ref}
      className={`${styles.textButton} ${
        isActive ? styles.textButtonActive : ''
      } ${className}`}
      {...rest}
    >
      {children}
    </Button>
  );
});
