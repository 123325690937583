import React from 'react';

import { Button } from 'components/common/buttons';
import FieldInput from '../FieldInput';

import { FlexRight } from 'components/layouts';

class AccountFields extends React.Component {
  constructor(props) {
    super(props);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.validatePassword = this.validatePassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);

    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
      this
    );

    this._formSubmitted = this._formSubmitted.bind(this);
  }

  state = {
    email: '',
    password: '',
    confirmPassword: '',
    attemptedSubmit: false
  };

  //TODO: Add check for if value has been deleted
  validateEmail() {
    let { email, attemptedSubmit } = this.state;
    // eslint-disable-next-line
    var regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regEx.test(email)) return 'success';
    if (email.length === 0 && attemptedSubmit) return 'error';
    return null;
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  validatePassword() {
    let { password, attemptedSubmit } = this.state;
    if (password.length < 8 && attemptedSubmit) return 'error';
    if (password.length >= 8) return 'success';
    return null;
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  validateConfirmPassword() {
    let { password, confirmPassword, attemptedSubmit } = this.state;
    if (confirmPassword.length === 0 && attemptedSubmit) return 'error';
    if (confirmPassword.length === 0) return null;
    if (password !== confirmPassword) return 'error';
    return 'success';
  }

  handleConfirmPasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  _formSubmitted(e) {
    let { successfulSubmit } = this.props;
    e.preventDefault();
    this.setState({ attemptedSubmit: true });
    if (
      this.validateEmail() === 'success' &&
      this.validatePassword() === 'success' &&
      this.validateConfirmPassword() === 'success'
    ) {
      let { email, password } = this.state;
      successfulSubmit({
        email,
        password
      });
    }
  }

  render() {
    let { email, password, confirmPassword } = this.state;

    return (
      <div className="sign-up-fields-container">
        <h2>Create an account</h2>
        <form onSubmit={this._formSubmitted} className="sign-up-fields-form">
          <FieldInput
            title="Email"
            validationState={this.validateEmail}
            onValueChange={this.handleEmailChange}
            value={email}
            type="email"
            controlId="account-field-email"
            autoFocus
          />
          <FieldInput
            title="Password"
            validationState={this.validatePassword}
            onValueChange={this.handlePasswordChange}
            value={password}
            type="password"
            controlId="account-field-password"
            popoverText="Please use a password that is at least 8 characters long."
          />
          <FieldInput
            title="Confirm Password"
            validationState={this.validateConfirmPassword}
            onValueChange={this.handleConfirmPasswordChange}
            value={confirmPassword}
            type="password"
            controlId="account-field-password-confirm"
          />
          <FlexRight className="sign-up-fields-form-button-container">
            <Button
              type="submit"
              className="sign-up-next"
              disabled={
                !(
                  this.validateEmail() === 'success' &&
                  this.validatePassword() === 'success' &&
                  this.validateConfirmPassword() === 'success'
                )
              }
            >
              Next
            </Button>
          </FlexRight>
        </form>
      </div>
    );
  }
}

export default AccountFields;
