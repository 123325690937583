import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import {
  IconPlanned,
  IconResponses,
  IconArrowLeft,
  IconNew,
  IconCamera,
  IconPastBulletin,
  IconSave
} from '../icons';
import Tile from 'components/layouts/Tile';

import styles from './TileButton.module.css';

const icons = {
  planned: IconPlanned,
  responses: IconResponses,
  leftArrow: IconArrowLeft,
  newIcon: IconNew,
  camera: IconCamera,
  pastBulletin: IconPastBulletin,
  save: IconSave
};

class TileButton extends React.Component {
  render() {
    const { destination, icon, label, onClick } = this.props;
    const ButtonIcon = icons[icon];
    const activeClass =
      this.props.location.pathname === destination ? styles.active : '';
    const buttonContent = (
      <React.Fragment>
        {ButtonIcon ? <ButtonIcon width="30" height="30" /> : null}
        <span className={styles.text}>{label}</span>
      </React.Fragment>
    );

    if (destination) {
      return (
        <Tile
          withPadding={true}
          El={Link}
          to={destination}
          className={`${styles.root} ${activeClass}`}
        >
          {buttonContent}
        </Tile>
      );
    } else if (onClick) {
      return (
        <Tile
          withPadding={true}
          El="button"
          onClick={onClick}
          className={styles.root}
        >
          {buttonContent}
        </Tile>
      );
    }
  }
}

export default withRouter(TileButton);
