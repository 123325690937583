import gql from 'graphql-tag';

import LiveBulletinApi from './LiveBulletin/LiveBulletinApi';
import ServiceSelectApi from './ServiceSelect/ServiceSelectApi';

export const fragments = {
  file: gql`
    fragment ProfileAndServicesFile on File {
      id
      url
      urlSchema
    }
  `,
  service: gql`
    fragment ProfileAndServicesService on Service {
      ...ServiceSelectService @include(if: $shouldIncludeBaseData)
      ...LiveBulletinService @include(if: $shouldIncludeBaseData)
    }
    ${ServiceSelectApi.fragments.service}
    ${LiveBulletinApi.fragments.service}
  `
};

export default {
  fragments
};
