import React from 'react';

import { Tooltip } from 'components/common/Tooltip';

import styles from './EditorTooltip.module.css';

const EditorTooltip = ({ className, children, triggerRect, ...rest }) => (
  <Tooltip
    triggerRect={triggerRect}
    className={`${styles.root} ${className}`}
    theme={'light'}
    {...rest}
  >
    {children}
  </Tooltip>
);

EditorTooltip.defaultProps = {
  placement: 'top'
};

export default EditorTooltip;
