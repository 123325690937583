import React from 'react';

import { withFirebase } from 'services/firebase';
import AddImageEditorHandler from './AddImageEditorHandler';

export const ImagePluginContext = React.createContext();

export const withImagePlugin = Component => props => (
  <ImagePluginContext.Consumer>
    {({ addImagePlugin }) => (
      <Component {...props} addImagePlugin={addImagePlugin} />
    )}
  </ImagePluginContext.Consumer>
);

export class AddImagePluginProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.deleteImage = this.deleteImage.bind(this);
    this.addImage = this.addImage.bind(this);
    this.setImageContained = this.setImageContained.bind(this);
    this.setImageFull = this.setImageFull.bind(this);
  }

  setImageContained(node) {
    AddImageEditorHandler.setImageContained(
      this.props.editor,
      node,
      'contained'
    );
  }

  setImageFull(node) {
    AddImageEditorHandler.setImageFull(this.props.editor, node, 'full');
  }

  deleteImage(node) {
    const { editor, firebase } = this.props;
    AddImageEditorHandler.deleteImage(editor, firebase, node);
  }

  addImage(file) {
    const { editor, bulletinId, firebase } = this.props;
    AddImageEditorHandler.addImage(editor, firebase, bulletinId, file);
  }

  render() {
    const addImagePlugin = {
      deleteImage: this.deleteImage,
      addImage: this.addImage,
      setImageContained: this.setImageContained,
      setImageFull: this.setImageFull
    };

    return (
      <ImagePluginContext.Provider value={{ addImagePlugin }}>
        {this.props.children}
      </ImagePluginContext.Provider>
    );
  }
}

export default withFirebase(AddImagePluginProvider);
