import React, { Component } from 'react';
import { Button } from 'components/common/buttons';
import { FlexRight } from 'components/layouts';
import ChurchPlanDisplay from './ChurchPlanDisplay';
import { Query } from 'react-apollo';
import { ACTIVE_PLAN_TIERS } from '../SignUpApi';

import './PlanSelection.css';

class PlanSelection extends Component {
  constructor(props) {
    super(props);

    this._formSubmitted = this._formSubmitted.bind(this);
    this._handlePlanSelect = this._handlePlanSelect.bind(this);
  }

  state = {
    selectedPlan: null
  };

  _formSubmitted(e) {
    const { selectedPlan } = this.state;
    const { successfulSubmit } = this.props;
    e.preventDefault();
    if (selectedPlan) {
      successfulSubmit({
        selectedPlan
      });
    }
  }

  _handlePlanSelect(e) {
    this.setState({
      selectedPlan: e.target.name
    });
  }

  render() {
    const { goBack } = this.props;
    const { selectedPlan } = this.state;
    return (
      <Query query={ACTIVE_PLAN_TIERS}>
        {({ loading, error, data }) => {
          if (loading || error) return null;

          if (data) {
            const { activePlanTiers } = data;
            return (
              <div className="sign-up-fields-container">
                <h2>Choose your plan</h2>
                <form
                  onSubmit={this._formSubmitted}
                  className="sign-up-fields-form"
                >
                  <div className="plan-selection-container">
                    {activePlanTiers.map(plan => (
                      <ChurchPlanDisplay
                        key={plan.id}
                        id={plan.id}
                        tier={plan.displayName}
                        cost={plan.pricePerMonth}
                        minMembers={plan.congregantsMin}
                        maxMembers={plan.congregantsMax}
                        selectedPlan={selectedPlan}
                        handlePlanSelect={this._handlePlanSelect}
                      />
                    ))}
                  </div>
                  <FlexRight className="sign-up-fields-form-button-container">
                    <Button
                      className="sign-up-next"
                      type="submit"
                      disabled={!selectedPlan}
                    >
                      Next
                    </Button>
                    <Button
                      btnStyle="secondary"
                      className="sign-up-previous"
                      onClick={e => goBack()}
                    >
                      Previous
                    </Button>
                  </FlexRight>
                </form>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

export default PlanSelection;
