import React from 'react';
import { Editor } from 'slate-react';
import { Value } from 'slate';
import Html from 'slate-html-serializer';
import { withRouter } from 'react-router';

import { SERIALIZER_RULES } from './HtmlSerializer';
import SlatePlugins from './SlatePlugins';
import Tile from 'components/layouts/Tile';
import HeaderImage from './HeaderImage';
import styles from 'css/bulletin.module.css';
import editorStyles from './BulletinEditorSlate.module.css';

const html = new Html({ rules: SERIALIZER_RULES });

const plugins = [...SlatePlugins];

// initial value
const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph'
      }
    ]
  }
});

const schema = {
  blocks: {
    image: {
      isVoid: true
    },
    divider: {
      isVoid: true
    }
  }
};

export class BulletinEditor extends React.Component {
  constructor(props) {
    super(props);
    const { body } = props;
    this.state = {
      showInlineToolbar: false,
      editorValue: body && body.object ? Value.fromJSON(body) : initialValue
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (e.value.document !== this.state.editorValue.document) {
      // save the document
      this.props.onAutoSaveFieldsChange(this.getValuesToSave(e));
    }

    this.setState({
      editorValue: e.value
    });
  }

  getValuesToSave = e => {
    let body = e.value.toJSON();
    let bodyHtml = html.serialize(e.value);

    return {
      body,
      bodyHtml
    };
  };

  render() {
    const { headerImage, refetch } = this.props;
    return (
      <React.Fragment>
        <Tile>
          <HeaderImage image={headerImage} bulletinRefetch={refetch} />
          <Editor
            id="editor"
            value={this.state.editorValue}
            onChange={this.onChange}
            className={`${styles.bulletin} ${editorStyles.bulletin}`}
            spellCheck={true}
            autoCorrect={true}
            plugins={plugins}
            placeholder="Your bulletin goes here..."
            schema={schema}
          />
        </Tile>
      </React.Fragment>
    );
  }
}

export default withRouter(BulletinEditor);
