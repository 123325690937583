import React from 'react';

import styles from './flex-right.module.css';

/**
 * Layout component to align elements to the right
 * @param {*} props
 */
const FlexRight = props => {
  return (
    <div className={`${styles.flexRight} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default FlexRight;
