import React from 'react';

import { withImagePlugin } from './AddImagePluginProvider';
import { IconImage } from 'components/common/icons';
import PluginButton from '../components/PluginButton';
import { getCmdShiftHotkeyTest, getCmdShiftHotkeyLabel } from '../SlateUtils';

const isImageHotKey = getCmdShiftHotkeyTest('7');

export class AddImageButton extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleHotkey = this.handleHotkey.bind(this);
  }

  componentDidMount() {
    document
      .getElementById('editor')
      .addEventListener('keydown', this.handleHotkey);
  }

  componentWillUnmount() {
    document
      .getElementById('editor')
      .removeEventListener('keydown', this.handleHotkey);
  }

  handleHotkey(e) {
    if (!isImageHotKey(e)) return;

    e.preventDefault();
    this.handleClick(e);
  }

  handleClick(e) {
    e.preventDefault();
    this.input.current.value = null;
    this.input.current.click();
  }

  handleChange(e) {
    e.preventDefault();
    this.props.addImagePlugin.addImage(this.input.current.files[0]);
  }

  render() {
    const { addImagePlugin } = this.props;
    return (
      <React.Fragment>
        <input
          type="file"
          id="imageSelector"
          name="imageSelector"
          ref={this.input}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={this.handleChange}
        />
        <PluginButton
          {...this.props}
          onClick={this.handleClick}
          isActive={addImagePlugin.isButtonActive}
          hoverText="Image"
          hotkey={getCmdShiftHotkeyLabel('7')}
          Icon={IconImage}
        />
      </React.Fragment>
    );
  }
}

export default withImagePlugin(AddImageButton);
