import React, { Fragment } from 'react';
import FadeIn from 'react-fade-in';
import PlannedBulletins from './PlannedBulletins';
import { PropsRoute } from 'components/routing/CustomRoutes';
import { Col } from 'react-bootstrap';
import PastBulletins from './PastBulletins';
import ProfileAndServices from './ProfileAndServices';
import TileButton from 'components/common/TileButton';
import ViewsAndResponses from './Responses/ViewsAndResponses';
import TileDropdown from './Responses/TileDropdown';
import NewBulletinModal from './NewBulletinModal';
import CONFIG from 'config';

class Bulletins extends React.Component {
  render() {
    const {
      loggedInUser: { adminOf: church },
      selectedService
    } = this.props.queryResponse.data;
    const { fetchMore, loading } = this.props.queryResponse;
    return (
      <FadeIn>
        <div>
          <NewBulletinModal church={church} selectedService={selectedService} />
          <Col xs={3} className="leftConsole">
            <PropsRoute
              path="/dashboard/(bulletins|pastBulletins)"
              exact
              church={church}
              selectedServiceIndex={selectedService.index}
              component={ProfileAndServices}
            />
            <PropsRoute
              path="/dashboard/(bulletins|pastBulletins)"
              exact
              icon="planned"
              label="Planned Bulletins"
              destination="/dashboard/bulletins"
              component={TileButton}
            />
            <PropsRoute
              path="/dashboard/(bulletins|pastBulletins)"
              exact
              icon="pastBulletin"
              label="Past Bulletins"
              destination="/dashboard/pastBulletins"
              component={TileButton}
            />
            {CONFIG.RESPONSES_ENABLED ? (
              <Fragment>
                <PropsRoute
                  path="dashboard/responses/:bulletin"
                  exact
                  component={ViewsAndResponses}
                />
                <PropsRoute
                  path="dashboard/responses/:bulletin"
                  exact
                  component={TileDropdown}
                />
                <PropsRoute
                  path="dashboard/responses/:bulletin"
                  exact
                  component={TileDropdown}
                />
              </Fragment>
            ) : null}
          </Col>
          <Col xs={9}>
            <PropsRoute
              exact
              path="/dashboard/bulletins"
              component={PlannedBulletins}
              church={church}
              selectedService={selectedService}
              fetchMore={fetchMore}
              loading={loading}
            />
            <PropsRoute
              exact
              path="/dashboard/pastBulletins"
              component={PastBulletins}
              pastBulletins={
                church.services[selectedService.index].pastBulletins
              }
              church={church}
              fetchMore={fetchMore}
              loading={loading}
            />
          </Col>
        </div>
      </FadeIn>
    );
  }
}

export default Bulletins;
