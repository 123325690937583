import React from 'react';

import styles from './Loading.module.css';

const Loading = props => {
  return (
    <div className={styles.root} style={props.style}>
      <img
        src="https://d2rdpmg2nfb82t.cloudfront.net/bulletn-monogram.gif"
        alt={props.alt || 'Loading'}
        width="75"
      />
    </div>
  );
};

export default Loading;
