import gql from 'graphql-tag';
import { matchPath } from 'react-router';

export const GET_FULL_LIVE_BULLETIN_FOR_SERVICE = gql`
  query GET_FULL_LIVE_BULLETIN_FOR_SERVICE($serviceId: ID!) {
    liveBulletinForService(id: $serviceId) {
      id
      title
      date
      body
      headerImage {
        id
        url
        urlSchema
      }
    }
  }
`;

export const getPageQuery = ({ firebase, location }) => {
  const match = matchPath(location.pathname, {
    path: `/:service/live`,
    exact: true
  });

  if (!match) return null;

  return {
    query: GET_FULL_LIVE_BULLETIN_FOR_SERVICE,
    skip: !firebase.getCurrentUser(),
    variables: {
      serviceId: match.params.service
    }
  };
};

export default {
  getPageQuery,
  queries: {
    GET_FULL_LIVE_BULLETIN_FOR_SERVICE
  }
};
