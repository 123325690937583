import React from "react";

import styles from "./TableHead.module.css";

const TableHead = props => {
  const { children, className } = props;
  return (
    <thead className={`${styles.thead} ${className}`}>
      <tr>
        {children}
      </tr>
    </thead>
  );
};

TableHead.defaultProps = {
  className: ''
};

export default TableHead;