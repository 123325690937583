import gql from 'graphql-tag';

export const GET_VIEW_BULLETIN_MODAL_STATE = gql`
  {
    viewBulletinModal @client {
      isOpen
      bulletinId
    }
  }
`;
