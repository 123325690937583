import React from 'react';

import { Button } from 'components/common/buttons';
import {
  IconToastError,
  IconToastInfo,
  IconToastWarning,
  IconToastSuccess,
  IconX
} from 'components/common/icons';

import styles from './toast.module.css';
import tileStyles from 'components/layouts/Tile/tile.module.css';

const toasts = {
  error: {
    title: 'Oops!',
    icon: IconToastError
  },
  warning: {
    title: 'Warning',
    icon: IconToastWarning
  },
  info: {
    title: 'Info',
    icon: IconToastInfo
  },
  success: {
    title: 'Success',
    icon: IconToastSuccess
  }
};

const Toast = ({ dismissable, toast, closeToast, className, ...rest }) => {
  const { type, id, content, title } = toast;
  const ToastIcon = toasts[type].icon;

  return (
    <div
      className={` ${tileStyles.tile} ${styles.root} ${styles[type]} ${className}`}
      {...rest}
    >
      <div className={styles.toastIcon}>
        <ToastIcon />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <strong>{title || toasts[type].title}</strong>
          <div className={styles.message}>{content}</div>
        </div>
      </div>
      <div className={styles.close}>
        {dismissable && (
          <Button btnStyle="styleless" onClick={() => closeToast(id)}>
            <IconX />
          </Button>
        )}
      </div>
    </div>
  );
};

Toast.defaultProps = {
  dismissable: true,
  className: ''
};

export default Toast;
