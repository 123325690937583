import React from 'react';

import Divider from './DividerNode';
import DividerReadonly from './DividerNodeReadonly';
import { getSpacingClassname } from '../BulletinSpaceComputer';

export default function DividerPlugin() {
  return {
    renderBlock: (props, editor, next) => {
      switch (props.node.type) {
        case 'divider':
          const spacingClassname = getSpacingClassname(editor, props.node);
          return (
            <div className={spacingClassname} {...props.attributes}>
              <Divider {...props} />
            </div>
          );
        default:
          return next();
      }
    }
  };
}

export function DividerPluginReadonly() {
  return {
    renderBlock: (props, editor, next) => {
      switch (props.node.type) {
        case 'divider':
          const spacingClassname = getSpacingClassname(editor, props.node);
          return (
            <div className={spacingClassname} {...props.attributes}>
              <DividerReadonly {...props} />
            </div>
          );
        default:
          return next();
      }
    }
  };
}
