import React from 'react';

import styles from './input-dynamic.module.css';

class InputDynamic extends React.Component {
  render() {
    let { value, displayInput } = this.props;
    let contents = displayInput ? (
      this.props.children
    ) : (
      <span className={styles.staticValue}>{value}</span>
    );

    return <div className="dynamicInput">{contents}</div>;
  }
}

export default InputDynamic;
