import React from 'react';

import styles from './page-inner.module.css';

/**
 * Layout component for the inner page container
 * @param {*} props
 */
const PageInner = props => {
  return (
    <div className={`${styles.pageInner} ${props.className}`}>
      {props.children}
    </div>
  );
};

PageInner.defaultProps = {
  className: ''
};

export default PageInner;
