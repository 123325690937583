import React from 'react';

import { Button } from 'components/common/buttons';

import accountStyles from '../account.module.css';

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.setEditable = this.setEditable.bind(this);
    this.handleInputMount = this.handleInputMount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    editable: false
  };

  async handleSubmit(e) {
    e.preventDefault();
    try {
      await this.props.handleSubmit();
      this.setEditable(false);
    } catch (e) {}
  }

  handleInputMount(input) {
    input.focus();
  }

  setEditable(editable, e) {
    if (e) e.preventDefault();

    this.setState({ editable });
  }

  render() {
    return (
      <form className={accountStyles.formRow} onSubmit={this.handleSubmit}>
        {this.props.render({
          editable: this.state.editable,
          handleMount: this.handleInputMount
        })}
        {this.state.editable ? (
          <Button btnStyle="primary" btnSize="xsmallFixed" type="submit">
            Save
          </Button>
        ) : (
          <Button
            btnStyle="tertiary"
            btnSize="xsmallFixed"
            type="button"
            onClick={e => this.setEditable(true, e)}
          >
            Change
          </Button>
        )}
      </form>
    );
  }
}

export default AccountForm;
