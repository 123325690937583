import { Tile } from 'components/layouts';
import React from 'react';
import acctSettingsStyles from '../account-settings.module.css';
import ResourceRow from './ResourceRow';

const resources = [
  {
    name: 'Bulletn QR Code',
    type: 'png',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2Fbulletn-qr-code_l.png?alt=media&token=c956a60b-f738-4523-8eda-93a017ff7b96'
  },
  {
    name: 'Bulletn PowerPoint Slide',
    type: 'pptx',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn-Onboarding-Slide.pptx?alt=media&token=f045a644-2ed8-4a85-9c37-696c0c915397'
  },
  {
    name: 'Bulletn 16x9 Slide',
    type: 'png',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn-Slide_16x9.jpg?alt=media&token=f0dc2213-b34d-48c8-b2a8-a51a63e5d11b'
  },
  {
    name: '4x6 Printed Handout',
    type: 'pdf',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn-Handout-4x6.pdf?alt=media&token=1cbf382c-fc30-4898-822c-4b24cb692c5b'
  },
  {
    name: 'Church Plant | Support Raising Handout',
    type: 'pdf',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn_Plant_Support-Raising.pdf?alt=media&token=3f49f0b2-ac67-4d7d-9e62-3d3ae5cd79b1'
  },
  {
    name: 'Small Church | Support Raising Handout',
    type: 'pdf',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn_Small_Support-Raising.pdf?alt=media&token=b82de6c4-e054-4ade-93a2-785a441ddd09'
  },
  {
    name: 'Medium Church | Support Raising Handout',
    type: 'pdf',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn_Medium_Support-Raising.pdf?alt=media&token=2e2832b3-2828-46d9-85c2-110cbf20a970'
  },
  {
    name: 'Large Church | Support Raising Handout',
    type: 'pdf',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn_Large_Support-Raising.pdf?alt=media&token=33bb40af-db7b-436c-863e-083861c1fb2c'
  },
  {
    name: 'Mega Church | Support Raising Handout',
    type: 'pdf',
    path:
      'https://firebasestorage.googleapis.com/v0/b/bulletn-prod.appspot.com/o/church-resources%2FBulletn_Mega_Support-Raising.pdf?alt=media&token=129fcb13-caa8-4649-aabf-38c139ed14c3'
  }
];

const Resources = props => {
  let { loading } = props;
  if (loading) return null;

  return (
    <Tile withPadding={true} className={acctSettingsStyles.accountSection}>
      <h2 className={acctSettingsStyles.h2}>Resources</h2>
      <div className={acctSettingsStyles.content}>
        <p className={acctSettingsStyles.contentDescription}>
          Use these free resources to help improve the onboarding and adoption
          experience at your church!
        </p>
        {resources.map(resource => (
          <ResourceRow {...resource} />
        ))}
      </div>
    </Tile>
  );
};

export default Resources;
