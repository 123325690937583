import React from 'react';
import { compose, withApollo } from 'react-apollo';
import { withFirebase } from 'services/firebase';
import { Redirect, Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';

import Tile from 'components/layouts/Tile';
import { Button } from 'components/common/buttons';
import Loading from 'components/common/Loading';
import { LOGGED_IN_USER_QUERY } from 'scenes/CommonApi';
import Input from 'components/common/form/inputs/Input';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import styles from './login.module.css';

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this._handleLoginUsernameChange = this._handleLoginUsernameChange.bind(
      this
    );
    this._handleLoginPasswordChange = this._handleLoginPasswordChange.bind(
      this
    );
    this.validateInput = this.validateInput.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
    this._onLogin = this._onLogin.bind(this);
    this._onForgotPasswordClick = this._onForgotPasswordClick.bind(this);
  }

  state = {
    email: '',
    password: '',
    errors: [],
    redirectToReferrer: false,
    showInfoTile: false,
    infoTileMessage: '',
    loggingIn: false
  };

  async _handleLoginUsernameChange(e) {
    this.setState({ email: e.target.value });
  }

  _handleLoginPasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  _onLogin(e) {
    e.preventDefault();
    this.authenticateUser();
  }

  authenticateUser = async () => {
    const { email, password } = this.state;
    const { firebase, client } = this.props;

    if (this.validateInput()) {
      try {
        this.setState({ loggingIn: true });
        await firebase.signInWithEmailAndPassword(email, password);
        const {
          data: { loggedInUser }
        } = await client.query({
          query: LOGGED_IN_USER_QUERY,
          fetchPolicy: 'network-only'
        });
        if (loggedInUser) {
          this.setState({ redirectToReferrer: true });
        } else {
          this.setState({
            errors: [
              {
                message: "We weren't able to log you in for some reason"
              }
            ]
          });
        }
      } catch (ex) {
        this.setState({
          errors: [
            {
              message: 'Invalid credentials'
            }
          ]
        });
      }
    } else {
      this.setState({
        errors: [
          {
            message: 'Invalid credentials'
          }
        ]
      });
    }
    this.setState({ loggingIn: false });
  };

  // Make sure that a username and password is entered
  validateInput() {
    return (
      this.state.email &&
      this.state.email.length &&
      this.state.password &&
      this.state.password.length
    );
  }

  async _onForgotPasswordClick() {
    const { firebase } = this.props;
    const { email } = this.state;
    if (email) {
      try {
        firebase.sendPasswordResetEmail(email);
        this.setState({
          showInfoTile: true,
          infoTileMessage: `We've sent an email to ${email} with a link to reset your password`
        });
      } catch (ex) {
        this.setState({
          errors: [
            {
              message:
                'An error occurred while attempting to send a password reset email. Please try again later.'
            }
          ]
        });
      }
    } else {
      this.setState({
        showInfoTile: true,
        infoTileMessage: `Please enter your email in the username field above`
      });
    }
  }

  render() {
    const { loggedIn } = this.props;
    const { from } = this.props.location.state || {
      from: {
        pathname: '/dashboard/bulletins'
      }
    };
    const {
      redirectToReferrer,
      errors,
      loggingIn,
      showInfoTile,
      infoTileMessage,
      email,
      password
    } = this.state;

    if (redirectToReferrer || loggedIn()) {
      return <Redirect to={from} />;
    }
    return (
      <div className={styles.root}>
        {loggingIn ? (
          <Loading />
        ) : (
          <FadeIn>
            <LoginError errors={errors} />
            <Tile className={styles.tile}>
              <form>
                <img
                  className={styles.monogram}
                  alt=""
                  src="https://d2rdpmg2nfb82t.cloudfront.net/Bulletn-monogram.png"
                />
                <h2>Sign in</h2>
                <FormControl labelTop={true} className={styles.formControl}>
                  <Label className={styles.label} for="username">
                    USERNAME
                  </Label>
                  <Input
                    id="username"
                    className={styles.input}
                    type="text"
                    value={email}
                    onChange={this._handleLoginUsernameChange}
                    inputStyle="underlined"
                  />
                </FormControl>
                <FormControl labelTop={true} className={styles.formControl}>
                  <Label className={styles.label} for="password">
                    PASSWORD
                  </Label>
                  <Input
                    id="password"
                    className={styles.input}
                    type="password"
                    value={password}
                    onChange={this._handleLoginPasswordChange}
                    inputStyle="underlined"
                  />
                </FormControl>
                <Button
                  type="submit"
                  className={styles.loginBtn}
                  onClick={this._onLogin}
                >
                  Log in
                </Button>
                <br />
                <Button
                  type="button"
                  btnStyle="styleless"
                  className={styles.forgotPassword}
                  onClick={this._onForgotPasswordClick}
                >
                  Forgot your password?
                </Button>
              </form>
            </Tile>
            {showInfoTile ? <InfoTile message={infoTileMessage} /> : null}
            <Link to="/signup" className={styles.loginSignup}>
              Don't have an account? <strong>Sign up now</strong>
            </Link>
          </FadeIn>
        )}
      </div>
    );
  }
}

function LoginError(props) {
  const errors = props.errors;
  let message = '';
  if (errors.includes('Network error')) {
    message = 'Network error';
  } else if (errors[0]) {
    message = errors[0].message;
  }

  return message ? (
    <FadeIn>
      <div>
        <div className={styles.loginError}>{message}</div>
      </div>
    </FadeIn>
  ) : null;
}

function InfoTile(props) {
  const { message } = props;
  return (
    <FadeIn>
      <div className={styles.loginInfoTile}>{message}</div>
    </FadeIn>
  );
}

export default compose(
  withFirebase,
  withApollo
)(Login);
