import React, { Component } from 'react';
import { FirebaseContext } from 'services/firebase';
import { Editor } from 'slate-react';
import { Value } from 'slate';
import { Query, Mutation } from 'react-apollo';
import { GET_VIEW_BULLETIN_MODAL_STATE } from './ViewBulletinModalApi';
import { UPDATE_VIEW_BULLETIN_MODAL } from 'services/graphql/local-mutations';
import { SINGLE_BULLETIN_QUERY } from 'scenes/Console/ConsoleApi';
import { SlatePluginsReadonly } from 'scenes/Console/EditBulletin/BulletinEditorSlate/SlatePlugins';

import { Modal, ModalHeader, ModalBody } from 'components/common/Modal';

import modalStyles from './ViewBulletinModal.module.css';
import styles from 'css/bulletin.module.css';

// initial value
const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph'
      }
    ]
  }
});

export default class ViewBulletinModalWithData extends Component {
  render() {
    return (
      <FirebaseContext.Consumer>
        {firebase => (
          <Query query={GET_VIEW_BULLETIN_MODAL_STATE}>
            {({
              data: {
                viewBulletinModal: { isOpen, bulletinId }
              }
            }) => {
              if (!isOpen) return null;
              return (
                <Mutation mutation={UPDATE_VIEW_BULLETIN_MODAL}>
                  {updateViewBulletinModal => (
                    <Query
                      query={SINGLE_BULLETIN_QUERY}
                      variables={{ id: bulletinId }}
                    >
                      {({ data, loading, error }) => {
                        if (loading || error || !data) return null;
                        const { title, body, headerImage } = data.bulletin;
                        return (
                          <ViewBulletinModal
                            isOpen={isOpen}
                            body={body}
                            title={title}
                            updateViewBulletinModal={updateViewBulletinModal}
                            headerImage={headerImage}
                            firebase={firebase}
                          />
                        );
                      }}
                    </Query>
                  )}
                </Mutation>
              );
            }}
          </Query>
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export class ViewBulletinModal extends Component {
  constructor(props) {
    super(props);
    const { body } = props;
    this.state = {
      showToolbar: false,
      editorValue: body && body.object ? Value.fromJSON(body) : initialValue,
      headerUrl: null
    };

    this._onHide = this._onHide.bind(this);
  }

  async componentDidMount() {
    const headerUrl = await this.props.firebase.calculateImageUrl(
      this.props.headerImage
    );
    this.setState({ headerUrl });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.headerImage !== this.props.headerImage) {
      const headerUrl = await this.props.firebase.calculateImageUrl(
        this.props.headerImage
      );
      this.setState({ headerUrl });
    }
  }

  _onHide() {
    const { updateViewBulletinModal } = this.props;
    updateViewBulletinModal({ variables: { isOpen: false, bulletinId: null } });
  }

  render() {
    const { isOpen, title } = this.props;
    const { editorValue, headerUrl } = this.state;
    return (
      <Modal
        modalId="newBulletinModal"
        show={isOpen}
        onClose={this._onHide}
        className={modalStyles.modal}
        openClassName={modalStyles.open}
      >
        <ModalHeader className={modalStyles.modalHeader}>
          <h2 id="newBulletinModal-header">{title}</h2>
        </ModalHeader>
        <ModalBody className={modalStyles.modalBody}>
          {headerUrl ? (
            <img
              src={headerUrl}
              alt="bulletin-header"
              className={modalStyles.headerImg}
            />
          ) : null}
          <Editor
            readOnly={true}
            plugins={SlatePluginsReadonly}
            value={editorValue}
            className={`${styles.bulletin} ${modalStyles.bulletin}`}
            onChange={() => {}}
          />
        </ModalBody>
      </Modal>
    );
  }
}
