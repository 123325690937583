import gql from 'graphql-tag';

// Note: 1000 was chosen as a reasonable number which allows us to not make any changes
// to our API and effectively guarantee all plannedBulletins to be returned. A church is
// unlikely to have 1000 planned bulletins at any given time.
export const GET_ALL_PLANNED_BULLETINS_DATES_FOR_SERVICE = gql`
  query GET_ALL_PLANNED_BULLETINS_DATES_FOR_SERVICE($serviceId: ID!) {
    service(id: $serviceId) {
      plannedBulletins(limit: 1000) {
        id
        date
      }
    }
  }
`;
