import React, { Component } from 'react';

import { TableRow, TableCell } from 'components/bulletins/BulletinsTable';
import {
  DateTableCell,
  TitleTableCell
} from 'components/bulletins/BulletinTableRow';
import { IconView } from 'components/common/icons';
import ActionsContainer from './ActionsContainer';
import SmallDropdown from './SmallDropdown';

import styles from './BulletinsRow.module.css';

class BulletinsRow extends Component {
  render() {
    const { date, name, id, viewCount } = this.props;
    return (
      <TableRow>
        <DateTableCell>{date}</DateTableCell>
        <TitleTableCell>{name}</TitleTableCell>
        <TableCell>
          <ActionsContainer>
            <div className={styles.stat}>
              <IconView className={styles.statIcon} />
              {viewCount}
            </div>
            <SmallDropdown bulletinId={id} />
          </ActionsContainer>
        </TableCell>
      </TableRow>
    );
  }
}

export default BulletinsRow;
