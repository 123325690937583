import gql from 'graphql-tag';

import PlannedBulletinsHeaderApi from './PlannedBulletinsHeader/PlannedBulletinsHeaderApi';
import PlannedBulletinsTableApi from './PlannedBulletinsTable/PlannedBulletinsTableApi';

export const fragments = {
  service: gql`
    fragment PlannedBulletinsService on Service {
      ...PlannedBulletinHeaderService @include(if: $shouldIncludeBaseData)
      ...PlannedBulletinsTableService
        @include(if: $shouldIncludePlannedBulletins)
    }
    ${PlannedBulletinsHeaderApi.fragments.service}
    ${PlannedBulletinsTableApi.fragments.service}
  `
};

export default {
  fragments
};
