import React from 'react';

import {
  IconBulletedList,
  IconNumberedList,
  IconT,
  IconTSmall,
  IconBlockquote,
  IconDivider
} from 'components/common/icons';

import { handleBlockChange } from './StickyToolbarEditorHandler';
import { BLOCK_TAGS } from '../../HtmlSerializer';
import { hasBlockType } from '../SlateUtils';
import PluginButton from '../components/PluginButton';

const IconComponents = {
  [BLOCK_TAGS.blockquote]: IconBlockquote,
  [BLOCK_TAGS.h1]: IconT,
  [BLOCK_TAGS.h2]: IconTSmall,
  [BLOCK_TAGS.hr]: IconDivider,
  [BLOCK_TAGS.ul]: IconBulletedList,
  [BLOCK_TAGS.ol]: IconNumberedList
};

const BlockButton = ({ classConfig, type, editor, hoverText, hotkey }) => {
  const { value } = editor;
  const Icon = IconComponents[type];
  let isActive = false;

  // Only do checks to see if the current selection contains the specific
  // block type if the list of blocks in the current selection is has a
  // length greater than 0
  if (value.blocks.size > 0) {
    isActive = hasBlockType(type, editor);
    if ([BLOCK_TAGS.ol, BLOCK_TAGS.ul].includes(type)) {
      const parent = value.document.getParent(value.blocks.first().key);
      isActive =
        hasBlockType(BLOCK_TAGS.li, editor) && parent && parent.type === type;
    }
  }

  return (
    <PluginButton
      classConfig={classConfig}
      hoverText={hoverText}
      hotkey={hotkey}
      isActive={isActive}
      onClick={event => handleBlockChange(event, editor, type)}
      Icon={Icon}
    />
  );
};

export default BlockButton;
