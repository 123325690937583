import gql from 'graphql-tag';

export const fragments = {
  service: gql`
    fragment ServiceSelectService on Service {
      id
      day
      time
      name
    }
  `
};

export default {
  fragments
};
