import React from 'react';

import withTooltip from './index';
import { Button } from 'components/common/buttons';

import styles from './iconWithTooltip.module.css';

/**
 * This function composes the withTooltip HOC to create a HOC
 * that satisfies the requirements of an icon with a tooltip
 * @param {Component} icon
 * @param {Object} tooltipConfig
 */
const iconWithTooltip = (icon, tooltipConfig) => {
  return withTooltip(function IconWithTooltip(props) {
    const { className, ...rest } = props;
    return (
      <Button
        className={`${styles.button} ${props.className}`}
        btnStyle="styleless"
        type="button"
        {...rest}
      >
        <span>{icon}</span>
      </Button>
    );
  }, tooltipConfig);
};

export default iconWithTooltip;
