import gql from 'graphql-tag';

export const fragments = {
  church: gql`
    fragment ChurchWebsiteForm on Church {
      website
    }
  `
};

export default {
  fragments
};
