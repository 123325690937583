import React from 'react';
import { Query } from 'react-apollo';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';

import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import { GET_ALL_PLANNED_BULLETINS_DATES_FOR_SERVICE } from './NewBulletinModalDateInputApi';

import inputStyles from 'components/common/form/inputs/Input/input.module.css';

const NewBulletinModalDateInput = ({
  className,
  onChange,
  plannedBulletins,
  selectedService,
  value
}) => {
  const restrictDateSelection = current => {
    const yesterday = DateTime.moment().subtract(1, 'day');
    const existingBulletin = plannedBulletins.find(bulletin => {
      return (
        moment(bulletin.date)
          .tz('UTC')
          .format('MM DD YYYY') === current.format('MM DD YYYY')
      );
    });

    return (
      current.day() ===
        moment()
          .day(`${selectedService.day}`)
          .day() &&
      current.isAfter(yesterday) &&
      !existingBulletin
    );
  };

  return (
    <FormControl labelTop={true} className={className}>
      <Label for="nb-date">Date</Label>
      <DateTime
        id="nb-date"
        value={value}
        timeFormat={false}
        onChange={onChange}
        isValidDate={restrictDateSelection}
        closeOnSelect={true}
        inputProps={{
          className: `${inputStyles.input} ${inputStyles.underlined}`
        }}
      />
    </FormControl>
  );
};

const NewBulletinModalDateInputWithData = ({ selectedService, ...rest }) => (
  <Query
    query={GET_ALL_PLANNED_BULLETINS_DATES_FOR_SERVICE}
    variables={{ serviceId: selectedService.id }}
    fetchPolicy="no-cache"
  >
    {({ data }) => {
      // Render the date input even if the request for all planned bulletin
      // dates is still in flight by defaulting to an empty array.
      const plannedBulletins = data.service
        ? data.service.plannedBulletins
        : [];
      return (
        <NewBulletinModalDateInput
          plannedBulletins={plannedBulletins}
          selectedService={selectedService}
          {...rest}
        />
      );
    }}
  </Query>
);

export default NewBulletinModalDateInputWithData;
