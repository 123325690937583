import React from 'react';

import styles from './centered-container.module.css';

/**
 * Layout component for a vertically and horizontally centered container
 * @param {*} props
 */
const CenteredContainer = props => {
  return (
    <div
      className={`${styles.centeredContainer} ${props.className}`}
      {...props}
    >
      {props.children}
    </div>
  );
};

CenteredContainer.defaultProps = {
  className: ''
};

export default CenteredContainer;
