import React from 'react';

import styles from './PluginButton.module.css';

const PluginButtonHotkeyTooltipText = ({ text, hotkey }) => {
  return (
    <div className={styles.tooltipText}>
      <div>{text}</div>
      <div className={styles.hotkey}>{hotkey}</div>
    </div>
  );
};

export default PluginButtonHotkeyTooltipText;
