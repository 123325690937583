import React from 'react';

export default class Autosave extends React.Component {
  constructor(props) {
    super(props);
    this.tick = this.tick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._executeSave = this._executeSave.bind(this);
    this.manualSave = this.manualSave.bind(this);
  }

  state = {
    autoSaveFields: this.props.autoSaveFields,
    changesMadeSinceLastSave: false,
    timeSinceLastChange: 0,
    autosaving: false,
    changes: {}
  };

  componentDidMount() {
    this.timer = setInterval(this.tick, 1000);
    window.addEventListener('beforeunload', this._executeSave);
  }

  async componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener('beforeunload', this._executeSave);
    await this._executeSave();
  }

  _executeSave() {
    const { executeSave } = this.props;
    return executeSave(this.state.changes);
  }

  async manualSave() {
    try {
      await this._executeSave();
    } catch (e) {
    } finally {
      this.setState({
        timeSinceLastChange: 0,
        changesMadeSinceLastSave: false,
        changes: []
      });
    }
  }

  handleChange(change) {
    this.setState({
      changesMadeSinceLastSave: true,
      timeSinceLastChange: 0,
      changes: {
        ...this.state.changes,
        ...change
      },
      autoSaveFields: {
        ...this.state.autoSaveFields,
        ...change
      }
    });
  }

  async tick() {
    const { executeSave, frequency } = this.props;
    if (this.state.changesMadeSinceLastSave) {
      const newTimeSinceLastChange = this.state.timeSinceLastChange + 1000;
      if (newTimeSinceLastChange >= frequency) {
        try {
          this.setState({
            autosaving: true
          });
          await executeSave(this.state.changes);
        } catch (e) {
        } finally {
          this.setState({
            timeSinceLastChange: 0,
            changesMadeSinceLastSave: false,
            changes: []
          });
          // Saving happens really quick. Give "Autosaving..." some breathing room
          setTimeout(() => {
            this.setState({
              autosaving: false
            });
          }, 1000);
        }
      } else {
        this.setState({
          timeSinceLastChange: newTimeSinceLastChange
        });
      }
    }
  }

  render() {
    return this.props.render({
      handleChange: this.handleChange,
      manualSave: this.manualSave,
      autosaving: this.state.autosaving,
      autoSaveFields: this.state.autoSaveFields
    });
  }
}

Autosave.defaultProps = {
  frequency: 10000
};
