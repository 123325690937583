import React, { Component } from 'react';
import { withFirebase } from 'services/firebase';
import { Modal, ModalHeader, ModalBody } from 'components/common/Modal';
import Label from 'components/common/form/Label';
import Input from 'components/common/form/inputs/Input';
import FormControl from 'components/common/form/FormControl';
import { Button } from 'components/common/buttons';
import { compose } from 'react-apollo';
import { withToastMessages } from 'components/common/ToastContainer';
import FadeIn from 'react-fade-in';

import styles from './reauthModal.module.css';

class ReauthModal extends Component {
  constructor(props) {
    super(props);

    this._onClose = this._onClose.bind(this);
    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  state = {
    email: '',
    password: '',
    errorMessage: ''
  };

  _onClose() {}

  _handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  _handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  async _onSubmit(e) {
    e.preventDefault();

    const {
      firebase,
      showToast,
      attributeUnderChange,
      reauthCompleted
    } = this.props;
    const { email, password } = this.state;

    this.setState({ errorMessage: '' });

    try {
      const credential = await firebase.generateNewCredential(email, password);

      await firebase
        .getCurrentUser()
        .reauthenticateAndRetrieveDataWithCredential(credential);

      showToast({
        type: 'success',
        content: `You've successfully reauthenticated! You may now reset your ${attributeUnderChange}`
      });

      reauthCompleted();
    } catch (e) {
      if (
        e.code === 'auth/invalid-credential' ||
        e.code === 'auth/invalid-email' ||
        e.code === 'auth/wrong-password'
      ) {
        this.setState({ errorMessage: 'Invalid credentials' });
      } else {
        this.setState({
          errorMessage: 'An unexpected error occurred. Please try agin later.'
        });
      }
    }
  }

  render() {
    const { show, attributeUnderChange } = this.props;
    const { errorMessage } = this.state;
    return (
      <Modal
        show={show}
        modalId="reauthenticationModal"
        onClose={this._onClose}
      >
        <ModalHeader>
          <h2>Login Needed</h2>
        </ModalHeader>
        <ModalBody>
          <p>
            {`Changing your ${attributeUnderChange} is sensitive and requires recent
            authentication. We need you to log in again before retrying this
            request.`}
          </p>
          <form onSubmit={this._onSubmit} className={styles.loginForm}>
            <FormControl className={styles.loginFormRow}>
              <Label htmlFor="Email">Email</Label>
              <Input
                id="Email"
                name="Email"
                type="text"
                handleChange={this._handleEmailChange}
              />
            </FormControl>
            <FormControl className={styles.loginFormRow}>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                name="password"
                type="password"
                handleChange={this._handlePasswordChange}
              />
            </FormControl>
            {errorMessage ? (
              <FadeIn>
                <div className={styles.loginError}>{errorMessage}</div>
              </FadeIn>
            ) : null}

            <Button
              type="submit"
              btnStyle="primary"
              className={styles.loginFormButton}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

export default compose(withFirebase, withToastMessages)(ReauthModal);
