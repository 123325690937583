import React, { useEffect, useCallback } from 'react';
import { useSpring, animated as a } from 'react-spring';

import { shortMonthFormatDate } from 'services/utils/formatDate';

import styles from './BulletinHeader.module.css';

const BulletinHeader = ({ bulletin, headerRef, headerUrl, revealBulletin }) => {
  // Logic for handling the decreasing opacity effect of the header image. Defines the spring
  // and how to update the spring on window scroll.
  const [headerProps, setHeaderProps] = useSpring(() => ({ opacity: 1 }));
  const onScroll = useCallback(() => {
    const newOpacity =
      (headerRef.current.offsetHeight - window.scrollY) /
      headerRef.current.offsetHeight;
    setHeaderProps({ opacity: newOpacity });
  }, [headerRef, setHeaderProps]);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [bulletin.headerImage, onScroll]);

  return bulletin.headerImage ? (
    <a.img
      ref={headerRef}
      style={headerProps}
      src={headerUrl}
      alt="bulletin header"
      className={styles.bulletinHeader}
      onLoad={revealBulletin}
    />
  ) : (
    <a.div
      ref={headerRef}
      style={headerProps}
      className={styles.bulletinHeader}
    >
      <h1 className={styles.headerText}>{bulletin.title}</h1>
      <p className={styles.headerDate}>{shortMonthFormatDate(bulletin.date)}</p>
    </a.div>
  );
};

export default BulletinHeader;
