import React from 'react';

import styles from './current-card.module.css';

export default class CurrentCard extends React.Component {
  formatExpiryDate(month, year) {
    if (month < 10) {
      month = '0' + month;
    }

    return `${month} / ${year.toString().substr(2)}`;
  }

  render() {
    const { brand, name, last4, expiryMonth, expiryYear } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.brand}>{brand}</div>
        <div className={styles.cardNumber}>
          <small>
            <i>card ending in</i>
          </small>{' '}
          ... {last4}
        </div>
        <div className={styles.bottomRow}>
          <span>{name}</span>
          <span>Exp {this.formatExpiryDate(expiryMonth, expiryYear)}</span>
        </div>
      </div>
    );
  }
}
