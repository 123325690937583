import React, { Component } from 'react';
import { mergeBulletinList } from 'scenes/Console/ConsoleApi';
import './LoadMoreBulletins.css';

export default class LoadMoreBulletins extends Component {
  render() {
    const { bulletinListName, church, fetchMore, loading } = this.props;
    return (
      <button
        className="load-more-button"
        onClick={() => {
          fetchMore({
            variables: {
              skip: church.services[0][bulletinListName].length,
              [bulletinListName]: true,
              shouldIncludeBaseData: false
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }
              return mergeBulletinList(
                previousResult,
                fetchMoreResult.loggedInUser.adminOf.services[0][
                  bulletinListName
                ],
                bulletinListName
              );
            }
          });
        }}
      >
        {loading ? 'LOADING ...' : 'LOAD 10 MORE ...'}
      </button>
    );
  }
}
