import React from 'react';

import { Button } from 'components/common/buttons';
import { IconX } from 'components/common/icons';

import styles from './SpecialMessage.module.css';

const SpecialMessage = ({ handleClose }) => (
  <div className={styles.root}>
    <p className={styles.line1}>
      First Three Months Free to Help During COVID-19
    </p>
    <p className={styles.line2}>
      No need for a promo code, your card won't be charged. We're here to help.
    </p>
    <Button
      btnStyle="styleless"
      className={styles.dismiss}
      aria-label="close"
      onClick={handleClose}
    >
      <IconX fill="#fff" height={18} width={18} />
    </Button>
  </div>
);

export default SpecialMessage;
