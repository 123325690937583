import React from 'react';
import './Divider.css';

class Divider extends React.Component {
  render() {
    return <div className="divider" />;
  }
}

export default Divider;
