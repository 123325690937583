import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import createApolloClient from 'services/graphql/apollo';

import Firebase, { FirebaseContext } from './services/firebase';
import { unregister } from './registerServiceWorker';
import App from './App';

unregister();
const firebase = new Firebase();

ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <ApolloProvider client={createApolloClient(firebase)}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
