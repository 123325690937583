import gql from 'graphql-tag';

export const fragments = {
  church: gql`
    fragment ChurchNameForm on Church {
      name
    }
  `
};

export default {
  fragments
};
