import React, { Component } from 'react';
import { Query, compose, withApollo } from 'react-apollo';

import { Route, Switch, withRouter } from 'react-router-dom';

import { PrivateRoute, PropsRoute } from './components/routing/CustomRoutes';
import Loading from 'components/common/Loading';
import NoMatch from 'components/routing/NoMatch';
import ToastContainer from 'components/common/ToastContainer';

import { LOGGED_IN_USER_QUERY } from 'scenes/CommonApi';
import Login from './scenes/Login';
import SignUp from './scenes/SignUp';
import UserManagement from './scenes/UserManagement';
import AccountSettings from './scenes/AccountSettings';
import AccountSettingsApi from './scenes/AccountSettings/AccountSettingsApi';
import BulletinWebView from './scenes/BulletinWebView';
import BulletinWebViewApi from './scenes/BulletinWebView/BulletinWebViewApi';
import Console from './scenes/Console';
import ConsoleApi from './scenes/Console/ConsoleApi';

import CONFIG from 'config';
import { withFirebase } from 'services/firebase';

import styles from './App.module.css'; // eslint-disable-line no-unused-vars

export class App extends Component {
  constructor(props) {
    super(props);

    this._isLoggedIn = this._isLoggedIn.bind(this);
  }

  state = {
    firebaseConnected: false,
    connectingToFirebase: true,
    updatesAvailable: false
  };

  componentDidMount() {
    const { firebase } = this.props;
    this.unsubscribeFromUserUpdates = firebase.auth.onIdTokenChanged(
      async user => {
        if (user) {
          this.setState({
            firebaseConnected: true,
            connectingToFirebase: false
          });
        } else {
          this.setState({
            firebaseConnected: false,
            connectingToFirebase: false
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribeFromUserUpdates();
  }

  _isLoggedIn() {
    let user = null;
    try {
      user = this.props.client.readQuery({ query: LOGGED_IN_USER_QUERY })
        .loggedInUser;
    } catch (e) {}

    return user && user.id !== null && user.role === 'ADMIN';
  }

  render() {
    const { connectingToFirebase, firebaseConnected } = this.state;
    if (!connectingToFirebase) {
      const { client } = this.props;
      const consoleQuery = ConsoleApi.getPageQuery(this.props);
      const accountSettingsQuery = AccountSettingsApi.getPageQuery(this.props);
      const bulletinWebViewQuery = BulletinWebViewApi.getPageQuery(this.props);
      const defaultQuery = { query: LOGGED_IN_USER_QUERY };
      const pageQuery =
        consoleQuery ||
        accountSettingsQuery ||
        bulletinWebViewQuery ||
        defaultQuery;

      return (
        <ToastContainer>
          <Query {...pageQuery}>
            {queryResponse => {
              let user = null;
              try {
                user = client.readQuery({ query: LOGGED_IN_USER_QUERY })
                  .loggedInUser;
              } catch (e) {}

              if (queryResponse.loading && !user && firebaseConnected) {
                // If the page query is executing and no user data is in the cache,
                // then show a loading icon for the page.
                return <Loading />;
              }

              return (
                <Switch>
                  {CONFIG.SIGNUP_ENABLED ? (
                    <Route exact path="/signup" component={SignUp} />
                  ) : null}
                  {CONFIG.LOGIN_ENABLED ? (
                    <PropsRoute
                      exact
                      path={['/', '/login']}
                      component={Login}
                      loggedIn={this._isLoggedIn}
                    />
                  ) : null}
                  {CONFIG.LOGIN_ENABLED ? (
                    <PrivateRoute
                      path="/dashboard"
                      component={Console}
                      redirectTo="/login"
                      loggedIn={this._isLoggedIn}
                      queryResponse={queryResponse}
                    />
                  ) : null}
                  {CONFIG.LOGIN_ENABLED ? (
                    <PrivateRoute
                      path="/account-settings/:section"
                      component={AccountSettings}
                      redirectTo="/login"
                      loggedIn={() => this._isLoggedIn(queryResponse)}
                      queryResponse={queryResponse}
                    />
                  ) : null}
                  <PropsRoute
                    exact
                    path="/:service/live"
                    component={BulletinWebView}
                    queryResponse={queryResponse}
                  />
                  <Route path="/_" component={UserManagement} />
                  <Route component={NoMatch} />
                </Switch>
              );
            }}
          </Query>
        </ToastContainer>
      );
    } else {
      return <Loading />;
    }
  }
}

export default compose(
  withFirebase,
  withApollo,
  withRouter
)(App);
