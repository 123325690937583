import React from 'react';

export const BLOCK_TAGS = {
  blockquote: 'blockquote',
  h1: 'heading-1',
  h2: 'heading-2',
  img: 'image',
  hr: 'divider',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  li: 'list-item',
  p: 'paragraph'
};

export const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underline'
};

export const INLINE_TAGS = {
  a: 'link'
};

export const SERIALIZER_RULES = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type === BLOCK_TAGS.img) {
        return {
          object: 'block',
          type,
          data: {
            src: el.getAttribute('src'),
            display: el.getAttribute('data-display')
          },
          nodes: next(el.childNodes)
        };
      } else if (type) {
        return {
          object: 'block',
          type,
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case BLOCK_TAGS.blockquote:
            return <blockquote>{children}</blockquote>;
          case BLOCK_TAGS.h1:
            return <h1>{children}</h1>;
          case BLOCK_TAGS.h2:
            return <h2>{children}</h2>;
          case BLOCK_TAGS.img && obj.data.get('src'):
            return (
              <img
                src={obj.data.get('src')}
                data-display={obj.data.get('display')}
                alt=""
              />
            );
          case BLOCK_TAGS.ol:
            return <ol>{children}</ol>;
          case BLOCK_TAGS.ul:
            return <ul>{children}</ul>;
          case BLOCK_TAGS.li:
            return <li>{children}</li>;
          case BLOCK_TAGS.hr:
            return <hr />;
          default:
            return <p>{children}</p>;
        }
      }
    }
  },
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'mark',
          type,
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case MARK_TAGS.strong:
            return <strong>{children}</strong>;
          case MARK_TAGS.em:
            return <em>{children}</em>;
          case MARK_TAGS.u:
            return <u>{children}</u>;
          default:
            return children;
        }
      }
    }
  },
  {
    deserialize(el, next) {
      const type = INLINE_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'inline',
          type,
          data: {
            className: el.getAttribute('class'),
            href: el.getAttribute('href')
          },
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'inline') {
        switch (obj.type) {
          case INLINE_TAGS.a:
            return <a href={obj.data.get('href')}>{children}</a>;
          default:
            return <span>{children}</span>;
        }
      }
    }
  }
];
