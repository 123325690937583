import { Block } from 'slate';
import { preloadImage } from 'services/utils/image';
import errorHandler from 'services/errorHandlerUtility';

const setImageDisplay = (editor, node, display) => {
  editor
    .focus()
    .moveToRangeOfNode(node)
    .setBlocks(
      new Block({
        ...node.toJSON(),
        data: node.data.set('display', display)
      })
    );
};

const setImageContained = (editor, node) => {
  setImageDisplay(editor, node, 'contained');
};

const setImageFull = (editor, node) => {
  setImageDisplay(editor, node, 'full');
};

const addImage = (editor, firebase, bulletinId, file) => {
  editor.insertBlock({
    type: 'image',
    data: { loading: true, full: true }
  });

  const fileName = Date.now();
  const ref = `${bulletinId}/${fileName}`;
  const imageRef = firebase.churchStorageRef.child(ref);
  const uploadTask = imageRef.put(file);

  return uploadTask.on(
    'state_changed',
    snapshot => {},
    error => {
      errorHandler.report(error);
      editor.setBlocks({
        type: 'image',
        data: { loading: false, uploadError: true }
      });
    },
    async () => {
      const src = await uploadTask.snapshot.ref.getDownloadURL();

      // Prefetch image before setting the src and loading to false so that
      // the user doesn't see a gap in between the loading animation disappearing
      // and the image displaying
      const img = await preloadImage(src);

      editor
        .setBlocks({
          type: 'image',
          data: {
            src,
            ref,
            loading: false,
            display: 'full',
            height: img.height,
            width: img.width
          }
        })
        .select(editor.value.selection)
        .moveToEndOfBlock()
        .insertBlock({ type: 'paragraph' })
        .focus();
    }
  );
};

const AddImageEditorHandler = {
  setImageContained,
  setImageFull,
  addImage
};

export default AddImageEditorHandler;
