import React, { Component } from 'react';
import { Button } from 'components/common/buttons';
import { FlexRight } from 'components/layouts';
import FieldInput from '../FieldInput';

class ChurchFields extends Component {
  constructor(props) {
    super(props);

    this._formSubmitted = this._formSubmitted.bind(this);
    this._validateChurchName = this._validateChurchName.bind(this);
    this._validateChurchZip = this._validateChurchZip.bind(this);
    this._handleChurchNameChange = this._handleChurchNameChange.bind(this);
    this._handleChurchZipChange = this._handleChurchZipChange.bind(this);
  }
  state = {
    churchName: '',
    churchZip: '',
    attemptedSubmit: false
  };

  _formSubmitted(e) {
    const { churchName, churchZip } = this.state;
    const { successfulSubmit } = this.props;
    e.preventDefault();
    this.setState({ attemptedSubmit: true });
    if (
      this._validateChurchName() === 'success' &&
      this._validateChurchZip() === 'success'
    ) {
      successfulSubmit({
        churchName,
        churchZip
      });
    }
  }

  _validateChurchName() {
    let { churchName, attemptedSubmit } = this.state;
    if (churchName.length >= 1) return 'success';
    if (churchName.length === 0 && attemptedSubmit) return 'error';
    return null;
  }

  _validateChurchZip() {
    const { churchZip, attemptedSubmit } = this.state;
    const regEx = /^\d{5}$/;
    if (regEx.test(churchZip)) return 'success';
    if (!regEx.test(churchZip) && attemptedSubmit) return 'error';
    return null;
  }

  _handleChurchNameChange(e) {
    this.setState({ churchName: e.target.value });
  }

  _handleChurchZipChange(e) {
    this.setState({ churchZip: e.target.value });
  }

  render() {
    const { goBack } = this.props;
    const { churchName, churchZip } = this.state;
    return (
      <div className="sign-up-fields-container">
        <h2>Tell us about your church</h2>
        <form onSubmit={this._formSubmitted} className="sign-up-fields-form">
          <FieldInput
            title="Church Name"
            validationState={this._validateChurchName}
            onValueChange={this._handleChurchNameChange}
            value={churchName}
            controlId="account-field-church-name"
          />
          <FieldInput
            title="ZIP Code"
            validationState={this._validateChurchZip}
            onValueChange={this._handleChurchZipChange}
            value={churchZip}
            controlId="account-field-church-zip"
          />
          <FlexRight className="sign-up-fields-form-button-container">
            <Button
              className="sign-up-next"
              type="submit"
              disabled={
                !(
                  this._validateChurchName() === 'success' &&
                  this._validateChurchZip() === 'success'
                )
              }
            >
              Next
            </Button>
            <Button
              btnStyle="secondary"
              className="sign-up-previous"
              onClick={e => goBack()}
            >
              Previous
            </Button>
          </FlexRight>
        </form>
      </div>
    );
  }
}

export default ChurchFields;
