import React from 'react';

import { Button } from 'components/common/buttons';

import styles from './EditorTooltip.module.css';

export const EditorTooltipButton = ({
  active,
  className,
  children,
  ...rest
}) => (
  <Button
    className={`${styles.button} ${className} ${active ? styles.active : ''}`}
    btnSize="small"
    {...rest}
  >
    {children}
  </Button>
);

EditorTooltipButton.defaultProps = {
  btnStyle: 'styleless'
};

export default EditorTooltipButton;
