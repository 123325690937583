import React from 'react';

import styles from './EditorTooltip.module.css';

export const EditorTooltipButtonContainer = ({ children, refFn, ...rest }) => (
  <div className={styles.buttonContainer} ref={refFn} {...rest}>
    {children}
  </div>
);

export default EditorTooltipButtonContainer;
