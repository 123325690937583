import React from 'react';

import LoadingIcon from 'components/common/Loading';

import { Tile } from 'components/layouts';
import ProfilePicture from 'components/common/ProfilePicture';
import ChurchNameForm from './forms/ChurchNameForm';
import ChurchWebsiteForm from './forms/ChurchWebsiteForm';
import EmailForm from './forms/EmailForm';
import LocationForm from './forms/LocationForm';
import PlanTierForm from './forms/PlanTierForm';
import ReauthModal from '../ReauthModal';

import acctSettingsStyles from '../account-settings.module.css';

export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reauthNeeded: false
    };

    this.requestReauth = this.requestReauth.bind(this);
    this.reauthCompleted = this.reauthCompleted.bind(this);
  }

  requestReauth() {
    this.setState({ reauthNeeded: true });
  }

  reauthCompleted() {
    this.setState({ reauthNeeded: false });
  }

  render() {
    const {
      data: { loggedInUser, activePlanTiers },
      loading
    } = this.props.queryResponse;
    const { reauthNeeded } = this.state;

    return (
      <Tile withPadding={true} className={acctSettingsStyles.accountSection}>
        <h2 className={acctSettingsStyles.h2}>Account</h2>
        <div className={acctSettingsStyles.content}>
          {loading ? (
            <LoadingIcon />
          ) : (
            <React.Fragment>
              <ProfilePicture size={77} logo={loggedInUser.adminOf.logo} />
              <ChurchNameForm acctData={loggedInUser} />
              <EmailForm
                acctData={loggedInUser}
                requestReauth={this.requestReauth}
              />
              <ChurchWebsiteForm acctData={loggedInUser} />
              <LocationForm acctData={loggedInUser} />
              <PlanTierForm
                acctData={loggedInUser}
                planTiers={activePlanTiers}
              />
            </React.Fragment>
          )}
        </div>
        <ReauthModal
          show={reauthNeeded}
          reauthCompleted={this.reauthCompleted}
          attributeUnderChange="email"
        />
      </Tile>
    );
  }
}
