import gql from 'graphql-tag';

export const typeDefs = gql`
  type CreateBulletinModal {
    isOpen: bool
    replicate: bool
    originalBulletinId: ID
  }

  type ViewBulletinModal {
    isOpen: Bool
    bulletinId: ID
  }

  type ServiceSettingsModal {
    isOpen: bool
  }

  type SelectedService {
    index: Int
    id: ID
  }

  extend type Mutation {
    updateSelectedService(index: Int!, id: ID!): SelectedService
    updateCreateBulletinModal(
      isOpen: bool
      replicate: bool
      originalBulletinId: ID
    ): CreateBulletinModal
    updateViewBulletinModal(isOpen: bool, bulletinId: ID): ViewBulletinModal
    updateServiceSettingsModal(isOpen: bool): ServiceSettingsModal
  }
`;

export const defaults = {
  selectedService: {
    index: 0,
    id: null,
    __typename: 'ServiceSettingsModal'
  },
  createBulletinModal: {
    isOpen: false,
    replicate: false,
    originalBulletinId: null,
    __typename: 'CreateBulletinModal'
  },
  serviceSettingsModal: {
    isOpen: false,
    __typename: 'ServiceSettingsModal'
  },
  viewBulletinModal: {
    isOpen: false,
    bulletinId: null,
    __typename: 'ViewBulletinModal'
  }
};

export const resolvers = {
  Mutation: {
    updateSelectedService: (_, { index, id }, { cache }) => {
      const data = {
        selectedService: {
          index,
          id,
          __typename: 'SelectedService'
        }
      };
      cache.writeData({
        data
      });
      return data;
    },
    updateCreateBulletinModal: (
      _,
      { isOpen, replicate, originalBulletinId },
      { cache }
    ) => {
      if (!replicate) replicate = defaults.createBulletinModal.replicate;
      if (!originalBulletinId)
        originalBulletinId = defaults.createBulletinModal.originalBulletinId;
      const data = {
        createBulletinModal: {
          isOpen,
          replicate,
          originalBulletinId,
          __typename: 'CreateBulletinModal'
        }
      };
      cache.writeData({
        data
      });
      return data;
    },
    updateServiceSettingsModal: (_, { isOpen }, { cache }) => {
      const data = {
        serviceSettingsModal: { isOpen, __typename: 'ServiceSettingsModal' }
      };
      cache.writeData({ data });
      return data;
    },
    updateViewBulletinModal: (_, { isOpen, bulletinId }, { cache }) => {
      const data = {
        viewBulletinModal: {
          isOpen,
          bulletinId,
          __typename: 'ViewBulletinModal'
        }
      };
      cache.writeData({
        data
      });
      return data;
    }
  }
};
