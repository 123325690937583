import React from 'react';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { withFirebase } from 'services/firebase';
import CONFIG from 'config';

import HelpTip from './HelpTip';

import styles from './navbar.module.css';

export class NavBar extends React.Component {
  _logout = async () => {
    // remove token from local storage and reload page to reset apollo client
    await this.props.firebase.signOut();
    window.location.reload();
  };

  render() {
    const { homeChurch } = this.props;

    return (
      <div className={styles.newNavbar}>
        <div className={`${styles.navLinksWrapper} ${styles.leftLinks}`}>
          <NavLink
            to="/dashboard/bulletins"
            className={styles.newNavbarItem}
            activeClassName={styles.activeNavItem}
            isActive={(match, location) => {
              return (
                location.pathname.includes('/dashboard/bulletins') ||
                location.pathname.includes('/dashboard/pastBulletins')
              );
            }}
          >
            Bulletins
          </NavLink>
          {CONFIG.TEMPLATES_ENABLED && (
            <NavLink
              to="/dashboard/templates"
              className={styles.newNavbarItem}
              activeClassName={styles.activeNavItem}
            >
              Templates
            </NavLink>
          )}
        </div>
        <div className={styles.newNavbarLogo}>
          <img
            alt=""
            src="https://d2rdpmg2nfb82t.cloudfront.net/Bulletn-monogram.png"
          />
        </div>
        <div className={`${styles.navLinksWrapper} ${styles.rightLinks}`}>
          <NavDropdown
            eventKey={1}
            className={`${styles.newNavbarItem} ${styles.navProfileName}`}
            title={<span>{homeChurch}</span>}
            id="basic-nav-dropdown"
          >
            <LinkContainer eventKey={1.1} to="/account-settings/account">
              <MenuItem>Account</MenuItem>
            </LinkContainer>
            <MenuItem eventKey={1.2} onSelect={this._logout}>
              Log out
            </MenuItem>
          </NavDropdown>
          <HelpTip />
        </div>
      </div>
    );
  }
}

export default withFirebase(NavBar);
