import StackdriverErrorReporter from 'stackdriver-errors-js';

const {
  REACT_APP_STACKDRIVER_KEY: key,
  REACT_APP_PROJECT_ID: projectId,
  REACT_APP_NAME: service,
  REACT_APP_VERSION: version
} = process.env;

const environment = process.env.NODE_ENV;

let errorHandler;

if (environment === 'production') {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key,
    projectId,
    service,
    version
  });
} else {
  errorHandler = { report: console.error };
}

export default errorHandler;
