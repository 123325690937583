import React from 'react';

import { TableRow, TableCell } from 'components/bulletins/BulletinsTable';
import SmallDropdown from './SmallDropdown';
import ActionsContainer from './ActionsContainer';
import {
  DateTableCell,
  TitleTableCell
} from 'components/bulletins/BulletinTableRow';

class PlannedBulletinRow extends React.Component {
  render() {
    const { date, name, id, churchId, selectedServiceIndex } = this.props;
    return (
      <TableRow>
        <DateTableCell>{date}</DateTableCell>
        <TitleTableCell>{name}</TitleTableCell>
        <TableCell>
          <ActionsContainer>
            <SmallDropdown
              id={id}
              name={name}
              churchId={churchId}
              selectedServiceIndex={selectedServiceIndex}
            />
          </ActionsContainer>
        </TableCell>
      </TableRow>
    );
  }
}

export default PlannedBulletinRow;
