import moment from 'moment-timezone';

export const longMonthFormatDate = rawDateString => {
  return moment(new Date(rawDateString))
    .tz('UTC')
    .format('MMMM D, YYYY');
};

export const shortMonthFormatDate = rawDateString => {
  return moment(new Date(rawDateString))
    .tz('UTC')
    .format('MMM D, YYYY');
};
