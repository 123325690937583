import React from 'react';

import styles from './label.module.css';

const Label = props => {
  let { className, htmlFor } = props;
  return (
    <label htmlFor={htmlFor} className={`${styles.label} ${className}`}>
      {props.children}
    </label>
  );
};

Label.defaultProps = {
  className: ''
};

export default Label;
