import React from "react";

import styles from "./TableCell.module.css";

const TableCell = props => {
  const { children, className, textColor } = props;
  return <td className={`${styles.td} ${styles[textColor]} ${className}`}>{children}</td>
};

TableCell.defaultProps = {
  className: '',
  textColor: 'secondary'
};

export default TableCell;