import { Block } from 'slate';

import { BLOCK_TAGS } from '../HtmlSerializer';
import { hasBlockType, isBlockEmpty } from './SlateUtils';

export default function ExitBlockOnEnterPlugin() {
  return {
    onKeyDown: (event, editor, next) => {
      const { blocks, selection, document } = editor.value;
      if (event.key === 'Enter') {
        if (isBlockEmpty(editor) && hasBlockType(BLOCK_TAGS.li, editor)) {
          const li = blocks.get(0);
          const listContainer = document.getParent(li.get('key'));
          const listContainerIndex = document.nodes.indexOf(listContainer);

          if (listContainer.nodes.size === 1) {
            // We're in a list item which is the only child of the list
            // Remove the list container, insert a paragraph in it's place,
            // and put the selection at the start of the paragraph.
            editor
              .removeNodeByKey(listContainer.get('key'))
              .insertNodeByKey(
                document.get('key'),
                listContainerIndex,
                Block.create({ type: 'paragraph' })
              )
              .moveToStartOfNode(
                editor.value.document.nodes.get(listContainerIndex)
              );
          } else {
            // We're in a list with multiple children
            // Remove this list item, insert a paragraph after the list
            // container, and put the selection at the start of the paragraph
            editor
              .removeNodeByKey(li.get('key'))
              .insertNodeByKey(
                document.get('key'),
                listContainerIndex + 1,
                Block.create({ type: 'paragraph' })
              )
              .moveToStartOfNode(
                editor.value.document.nodes.get(listContainerIndex + 1)
              );
          }
          return false;
        } else if (
          selection.isCollapsed &&
          selection.end.get('offset') ===
            blocks.get(0).getLastText().text.length &&
          (hasBlockType(BLOCK_TAGS.h1, editor) ||
            hasBlockType(BLOCK_TAGS.h2, editor))
        ) {
          // The selection is collapsed at the end of the text in a h1 or h2
          // Insert a paragraph. `insertBlock` will move the selection for us
          // to the start of the paragraph
          editor.insertBlock({ type: 'paragraph' });
          return false;
        }
        return next();
      }
      return next();
    }
  };
}
