import gql from 'graphql-tag';

export const LOGGED_IN_USER_QUERY = gql`
  query LOGGED_IN_USER_QUERY {
    loggedInUser {
      id
      role
      adminOf {
        id
        name
      }
    }
  }
`;
