import React from 'react';

import styles from './DividerNode.module.css';

const DividerNodeReadonly = ({ children, ...rest }) => {
  return (
    <div className={styles.root} {...rest}>
      <hr className={styles.hr} />
    </div>
  );
};

export default DividerNodeReadonly;
