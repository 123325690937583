import React from 'react';
import { DropdownButton, MenuItem, ButtonToolbar } from 'react-bootstrap';
import {
  IconSpeechBubble,
  IconView,
  IconDuplicate,
  IconThreeCircleDropdown
} from 'components/common/icons';
import { Mutation } from 'react-apollo';
import {
  UPDATE_VIEW_BULLETIN_MODAL,
  UPDATE_CREATE_BULLETIN_MODAL
} from 'services/graphql/local-mutations';
import CONFIG from 'config';
// import './SmallDropdown.css';

class SmallDropdown extends React.Component {
  render() {
    const { bulletinId } = this.props;
    return (
      <ButtonToolbar>
        <div className="smallDropdown">
          <DropdownButton
            bsStyle="smallDropdown"
            pullRight
            title={<IconThreeCircleDropdown />}
            noCaret
            id="dropdown-no-caret"
          >
            <div className="arrow-up" />
            {CONFIG.RESPONSES_ENABLED ? (
              <MenuItem id="response" eventKey="2">
                <IconSpeechBubble fill="#6b8089" />
                Responses
              </MenuItem>
            ) : null}
            <Mutation mutation={UPDATE_VIEW_BULLETIN_MODAL}>
              {updateViewBulletinModal => (
                <MenuItem
                  id="view"
                  eventKey="1"
                  onClick={() =>
                    updateViewBulletinModal({
                      variables: { isOpen: true, bulletinId }
                    })
                  }
                >
                  <h5>
                    <IconView fill="#6b8089" />
                    View Bulletin
                  </h5>
                </MenuItem>
              )}
            </Mutation>
            <Mutation mutation={UPDATE_CREATE_BULLETIN_MODAL}>
              {openNewBulletinModal => (
                <MenuItem
                  id="replicate"
                  eventKey="3"
                  onClick={() => {
                    openNewBulletinModal({
                      variables: {
                        isOpen: true,
                        replicate: true,
                        originalBulletinId: bulletinId
                      }
                    });
                  }}
                >
                  <h5>
                    <IconDuplicate fill="#6b8089" />
                    Copy to Planned
                  </h5>
                </MenuItem>
              )}
            </Mutation>
          </DropdownButton>
        </div>
      </ButtonToolbar>
    );
  }
}

export default SmallDropdown;
