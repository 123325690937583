import styles from 'css/bulletin.module.css';

/**
 * Maps the slate block "type" values to a simplified classname fragment
 * that is used to compose a classname for the first level blocks
 */
const spacingClassnameMapping = {
  blockquote: 'blockquote',
  'heading-1': 'h1',
  'heading-2': 'h2',
  image: 'img',
  divider: 'divider',
  'bulleted-list': 'bodyText',
  'numbered-list': 'bodyText',
  paragraph: 'bodyText'
};

/**
 * Determines which class should be rendered for a particular node based on
 * its type and its position in the document.
 * @param {Object} editor Slate editor object
 * @param {Object} node Current node being rendered
 */
export const getSpacingClassname = (editor, node) => {
  const currBlockIndex = editor.value.document.getPath(node.get('key')).get(0);
  if (currBlockIndex === 0) {
    return null;
  }

  const prevBlock = editor.value.document.getNode([currBlockIndex - 1]);
  const prevBlockClassFragment = spacingClassnameMapping[prevBlock.get('type')];
  const currBlockClassFragment = spacingClassnameMapping[node.get('type')];
  return styles[`${currBlockClassFragment}-after-${prevBlockClassFragment}`];
};
