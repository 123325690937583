import React, { Component } from 'react';
import {
  DropdownButton,
  MenuItem,
  ButtonToolbar,
  Button
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';
import { Mutation, ApolloConsumer } from 'react-apollo';
import {
  BULLETINS_PAGE_QUERY,
  SINGLE_BULLETIN_QUERY
} from 'scenes/Console/ConsoleApi';
import { DELETE_BULLETIN_MUTATION } from 'services/graphql/mutations';
import {
  UPDATE_VIEW_BULLETIN_MODAL,
  UPDATE_CREATE_BULLETIN_MODAL
} from 'services/graphql/local-mutations';
import {
  IconEdit,
  IconDelete,
  IconView,
  IconReplicate,
  IconThreeCircleDropdown
} from 'components/common/icons';

import './SmallDropdown.css';
bootstrapUtils.addStyle(Button, 'smallDropdown');

export default class SmallDropdownWithData extends Component {
  render() {
    const { selectedServiceIndex } = this.props;
    return (
      <ApolloConsumer>
        {client => (
          <Mutation mutation={UPDATE_CREATE_BULLETIN_MODAL}>
            {openNewBulletinModal => (
              <Mutation
                mutation={DELETE_BULLETIN_MUTATION}
                update={(cache, { data: { deleteBulletin } }) => {
                  const data = cache.readQuery({
                    query: BULLETINS_PAGE_QUERY
                  });
                  let { plannedBulletins } = data.loggedInUser.adminOf.services[
                    selectedServiceIndex
                  ];
                  data.loggedInUser.adminOf.services[
                    selectedServiceIndex
                  ].plannedBulletins = plannedBulletins.filter(
                    bulletin => bulletin.id !== deleteBulletin.id
                  );
                  cache.writeQuery({
                    query: BULLETINS_PAGE_QUERY,
                    data
                  });
                }}
                refetchQueries={() => {
                  const data = client.readQuery({
                    query: BULLETINS_PAGE_QUERY
                  });

                  return [
                    {
                      query: BULLETINS_PAGE_QUERY,
                      variables: {
                        limit:
                          data.loggedInUser.adminOf.services[
                            selectedServiceIndex
                          ].plannedBulletins.length
                      }
                    }
                  ];
                }}
              >
                {deleteBulletinMutation => (
                  <SmallDropdown
                    {...this.props}
                    deleteBulletinMutation={deleteBulletinMutation}
                    openNewBulletinModal={openNewBulletinModal}
                    client={client}
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

class SmallDropdown extends Component {
  constructor(props) {
    super(props);

    this.deleteBulletin = this.deleteBulletin.bind(this);
    this.duplicateBulletin = this.duplicateBulletin.bind(this);
  }
  deleteBulletin(e) {
    const { id, deleteBulletinMutation } = this.props;
    deleteBulletinMutation({
      variables: { id },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteBulletin: {
          __typename: 'Bulletin',
          id: id
        }
      }
    });
  }

  duplicateBulletin(e) {
    const { id, openNewBulletinModal } = this.props;
    openNewBulletinModal({
      variables: { isOpen: true, replicate: true, originalBulletinId: id }
    });
  }

  render() {
    const { id, name, client } = this.props;
    const editLocation = {
      pathname: `/dashboard/bulletins/edit/${id}`,
      state: {
        name: name,
        id: id
      }
    };
    return (
      <ButtonToolbar>
        <div
          className="smallDropdown"
          onClick={() =>
            client.query({ query: SINGLE_BULLETIN_QUERY, variables: { id } })
          }
        >
          <DropdownButton
            bsStyle="smallDropdown"
            pullRight
            title={<IconThreeCircleDropdown />}
            noCaret
            id="dropdown-no-caret"
          >
            <div className="arrow-up" />
            <Mutation mutation={UPDATE_VIEW_BULLETIN_MODAL}>
              {updateViewBulletinModal => (
                <MenuItem
                  id="view"
                  eventKey="1"
                  onClick={() =>
                    updateViewBulletinModal({
                      variables: { isOpen: true, bulletinId: id }
                    })
                  }
                >
                  <h5>
                    <IconView fill="#6B8089" />
                    View
                  </h5>
                </MenuItem>
              )}
            </Mutation>

            <LinkContainer to={editLocation}>
              <MenuItem id="edit" eventKey="2">
                <h5>
                  <IconEdit
                    viewBox="0 0 17 17"
                    height="18"
                    width="18"
                    fill="#6b8089"
                  />
                  Edit
                </h5>
              </MenuItem>
            </LinkContainer>
            <MenuItem
              id="replicate"
              eventKey="3"
              onClick={this.duplicateBulletin}
            >
              <h5>
                <IconReplicate />
                Replicate
              </h5>
            </MenuItem>
            <MenuItem id="delete" eventKey="4" onClick={this.deleteBulletin}>
              <h5>
                <IconDelete />
                Delete
              </h5>
            </MenuItem>
          </DropdownButton>
        </div>
      </ButtonToolbar>
    );
  }
}
