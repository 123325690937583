import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const ModalBody = props => {
  return <div className={`${styles.body} ${props.className}`}>{props.children}</div> 
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired
};

ModalBody.defaultProps = {
  className: ''
};

export default ModalBody;