import gql from 'graphql-tag';

export const PAYMENT_SETTINGS_QUERY = gql`
  query PAYMENT_SETTINGS_QUERY($churchId: ID!) {
    churchPaymentInfo(id: $churchId) {
      id
      last4
      name
      brand
      expiryMonth
      expiryYear
    }
  }
`;
