import gql from 'graphql-tag';

export const ACTIVE_PLAN_TIERS = gql`
  query activePlanTiers {
    activePlanTiers {
      id
      displayName
      pricePerMonth
      congregantsMin
      congregantsMax
    }
  }
`;
