import React from 'react';
import ServiceSelect from './ServiceSelect';
import LiveBulletin from './LiveBulletin';
import LiveBulletinLink from './LiveBulletinLink';
import ProfilePicture from 'components/common/ProfilePicture';
import Divider from 'components/common/Divider';
import styles from './ProfileAndServices.module.css';
import Tile from 'components/layouts/Tile';

class ProfileAndServices extends React.Component {
  render() {
    const { church, selectedServiceIndex } = this.props;
    return (
      <Tile className={styles.root} withPadding={true}>
        <div className={styles.consoleProfilePictureWrapper}>
          <ProfilePicture size={150} logo={church.logo} />
        </div>
        <Divider />
        <ServiceSelect
          services={church.services}
          selectedServiceIndex={selectedServiceIndex}
        />
        <Divider />
        <LiveBulletinLink
          serviceId={church.services[selectedServiceIndex].id}
        />
        <Divider />
        <LiveBulletin
          liveBulletinForService={
            church.services[selectedServiceIndex].liveBulletin
          }
        />
      </Tile>
    );
  }
}

export default ProfileAndServices;
