import React from 'react';
import { FirebaseContext } from 'services/firebase';

import { Row, Col } from 'react-bootstrap';

import { PropsRoute } from 'components/routing/CustomRoutes';

import AccountSettingsApi from './AccountSettingsApi';
import { FeatureToggleListener } from 'services/firebase';

import Account from './Account';
import Appearance from './Appearance';
import Password from './Password';
import Payment from './Payment';
import Resources from './Resources';
import AccountNav from './AccountNav';
import LoadingIcon from 'components/common/Loading';
import ProfilePicture from 'components/common/ProfilePicture';
import { PageWrapper, PageInner } from 'components/layouts';
import NavBar from 'components/common/NavBar';

import accountStyles from './account-settings.module.css';

export class AccountSettings extends React.Component {
  constructor(props) {
    super(props);

    this._handleViewChange = this._handleViewChange.bind(this);
    this.state.currentView = props.match.params.section;
  }

  state = {
    currentView: ''
  };

  componentDidMount() {
    const { id } = this.props.cachedPageData.loggedInUser.adminOf;
    const { firebase } = this.props;

    firebase.initializeChurchStorageRef(`user-images/${id}`);
  }

  _handleViewChange(currentView, e) {
    this.setState({
      currentView
    });
  }

  render() {
    let { currentView } = this.state;
    const { queryResponse, cachedPageData } = this.props;
    let church = cachedPageData.loggedInUser.adminOf;

    return (
      <PageWrapper>
        <NavBar homeChurch={church.name} />
        <div className={accountStyles.header}>
          <PageInner className={accountStyles.headerInner}>
            <ProfilePicture size={77} logo={church.logo} />
            <h1 className={accountStyles.h1}>{church.name}</h1>
          </PageInner>
        </div>
        <PageInner>
          <Row>
            <Col xs={3} className="leftConsole">
              <AccountNav
                handleViewChange={this._handleViewChange}
                currentView={currentView}
              />
            </Col>
            <Col xs={9}>
              <PropsRoute
                path="/account-settings/password"
                component={Password}
              />
              <PropsRoute
                path="/account-settings/account"
                component={Account}
                queryResponse={queryResponse}
              />
              <PropsRoute
                path="/account-settings/appearance"
                component={Appearance}
                churchId={church.id}
                logo={church.logo}
              />
              <PropsRoute
                path="/account-settings/payment"
                component={Payment}
                churchId={church.id}
              />
              <FeatureToggleListener featureToggleName="resources_page">
                <PropsRoute
                  path="/account-settings/resources"
                  component={Resources}
                />
              </FeatureToggleListener>
            </Col>
          </Row>
        </PageInner>
      </PageWrapper>
    );
  }
}

class AccountSettingsWithContextAndData extends React.Component {
  render() {
    const { queryResponse } = this.props;
    return (
      <FirebaseContext.Consumer>
        {firebase => {
          // Check cachedPageData. If we have everything in the cache to render
          // the page itself, render the page itself even if our request is still
          // executing and getting more data for the page (as is the case for the
          // Account page)
          let cachedPageData;
          try {
            cachedPageData = queryResponse.client.readQuery({
              query:
                AccountSettingsApi.queries.ACCOUNT_SETTINGS_GENERAL_PAGE_QUERY
            });
          } catch {}

          if (queryResponse.loading && !cachedPageData) {
            return <LoadingIcon />;
          }

          return (
            <AccountSettings
              firebase={firebase}
              cachedPageData={cachedPageData}
              queryResponse={queryResponse}
              {...this.props}
            />
          );
        }}
      </FirebaseContext.Consumer>
    );
  }
}
export default AccountSettingsWithContextAndData;
