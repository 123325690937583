import React, { Component } from 'react';
import { withFirebase } from 'services/firebase';
import NoMatch from 'components/routing/NoMatch';
import { CenteredContainer, Tile } from 'components/layouts';
import ResetPasswordForm from './ResetPasswordForm';

const errorMessages = {
  'auth/invalid-action-code': 'This password reset link is invalid.',
  'auth/expired-action-code':
    'This password reset link has expired. Please try resting your password again.'
};

class ResetPassword extends Component {
  state = {
    errorMessage: null,
    displayComponent: null
  };

  async componentDidMount() {
    const { firebase, code } = this.props;

    try {
      const email = await firebase.verifyPasswordResetCode(code);
      this.setState({
        displayComponent: (
          <ResetPasswordForm
            email={email}
            completePasswordReset={this._completePasswordReset}
          />
        )
      });
    } catch (ex) {
      const errorMessage = errorMessages[ex.code];
      if (errorMessage)
        this.setState({
          errorMessage,
          displayComponent: <Tile withPadding>{errorMessage}</Tile>
        });
      else this.setState({ displayComponent: <NoMatch /> });
    }
  }

  _completePasswordReset = async newPassword => {
    const { firebase, code } = this.props;

    try {
      await firebase.completePasswordReset(code, newPassword);
      this.setState({
        displayComponent: (
          <Tile withPadding>
            Your password has been reset. You may now use your new password to
            login.
          </Tile>
        )
      });
    } catch (ex) {}
  };

  render() {
    const { displayComponent } = this.state;
    return <CenteredContainer>{displayComponent}</CenteredContainer>;
  }
}

export default withFirebase(ResetPassword);
