import React, { Fragment, Component } from 'react';
import { Mutation } from 'react-apollo';

import {
  UPDATE_CHURCH_LOGO,
  REMOVE_CHURCH_LOGO
} from 'services/graphql/mutations';

import { ToastContext } from 'components/common/ToastContainer';
import { FirebaseContext } from 'services/firebase';
import { IconCamera } from 'components/common/icons';
import { Tile } from 'components/layouts';
import ProfilePicture from 'components/common/ProfilePicture';
import { Button, ButtonGroup } from 'components/common/buttons';

import acctSettingsStyles from '../account-settings.module.css';
import appearanceStyles from './appearance.module.css';

const AppearanceWrapper = props => {
  return (
    <FirebaseContext.Consumer>
      {firebase => (
        <ToastContext.Consumer>
          {({ showToast }) => (
            <Mutation mutation={UPDATE_CHURCH_LOGO}>
              {updateChurchLogo => (
                <Mutation mutation={REMOVE_CHURCH_LOGO}>
                  {removeChurchLogo => (
                    <Appearance
                      {...props}
                      firebase={firebase}
                      updateChurchLogo={updateChurchLogo}
                      removeChurchLogo={removeChurchLogo}
                      showToast={showToast}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </ToastContext.Consumer>
      )}
    </FirebaseContext.Consumer>
  );
};

export class Appearance extends Component {
  constructor(props) {
    super(props);

    this._onFileSelect = this._onFileSelect.bind(this);
    this._onRemoveLogo = this._onRemoveLogo.bind(this);
  }

  async _onFileSelect(e) {
    const { churchId: id, updateChurchLogo, showToast, firebase } = this.props;

    const file = e.target.files[0];
    if (file) {
      const fileName = 'logo';
      const imageRef = firebase.churchStorageRef.child(fileName);
      const urlSchema = 'HTTP_LINK';
      const uploadTask = imageRef.put(file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
        },
        error => {
          // Handle unsuccessful uploads
          showToast({
            type: 'error',
            content:
              "An error occurred when updating your church's logo. Please try again later."
          });
        },
        async () => {
          // Handle successful uploads on complete
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          const { error } = await updateChurchLogo({
            variables: {
              id,
              fileName,
              url,
              urlSchema
            }
          });
          if (error) {
            showToast({
              type: 'error',
              content:
                "An error occurred when updating your church's logo. Please try again later."
            });
          } else {
            showToast({
              type: 'success',
              content: 'Logo updated!'
            });
          }
        }
      );
    }
  }

  async _onRemoveLogo() {
    const { churchId: id, removeChurchLogo, showToast } = this.props;
    try {
      await removeChurchLogo({ variables: { id } });

      showToast({
        type: 'success',
        content: 'Logo removed!'
      });
    } catch (err) {
      showToast({
        type: 'error',
        content: "An error occurred when removing your church's logo"
      });
    }
  }

  render() {
    const { logo } = this.props;
    return (
      <Tile withPadding={true} className={acctSettingsStyles.accountSection}>
        <h2 className={acctSettingsStyles.h2}>Appearance</h2>
        <div className={acctSettingsStyles.content}>
          <p className={acctSettingsStyles.contentDescription}>
            Adapt your bulletins to match your church’s brand.
          </p>
          <div className={appearanceStyles.avatarRowLayout}>
            <ProfilePicture
              size={77}
              logo={logo}
              className={appearanceStyles.churchLogo}
            />
            <div className={appearanceStyles.avatarColumnLayout}>
              <ButtonGroup>
                <input
                  type="file"
                  accept="image/jpeg,image/png"
                  style={{ display: 'none' }}
                  onChange={this._onFileSelect}
                  ref={ele => (this.fileInput = ele)}
                />
                <Button
                  btnStyle="secondary"
                  onClick={() => this.fileInput.click()}
                >
                  <Fragment>
                    <IconCamera
                      height="17"
                      width="17"
                      viewBox="0 0 17 17"
                      fill="#0179A7"
                    />
                    Upload new picture
                  </Fragment>
                </Button>
                <Button btnStyle="tertiary" onClick={this._onRemoveLogo}>
                  Remove
                </Button>
              </ButtonGroup>
              <p
                className={acctSettingsStyles.contentDescription}
                style={{ marginTop: '10px' }}
              >
                Logos should be in either .jpg or .png format, and should be at
                least 300x300px
              </p>
            </div>
          </div>
        </div>
      </Tile>
    );
  }
}

export default AppearanceWrapper;
