import React from 'react';
import { Mutation } from 'react-apollo';

import { UPDATE_CHURCH_NAME_MUTATION } from 'services/graphql/mutations';

import AccountForm from '../AccountForm';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import InputDynamic from 'components/common/form/inputs/dynamic/InputDynamic';
import Input from 'components/common/form/inputs/Input';
import { ToastContext } from 'components/common/ToastContainer';

import accountStyles from '../../account.module.css';

export class ChurchNameForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    let name = this.churchNameInput.value;
    let id = this.props.acctData.adminOf.id;
    let updateResponse;

    try {
      updateResponse = await this.props.updateChurchNameMutation({
        variables: { id, name },
        optimisticResponse: {
          updateChurch: {
            __typename: 'Church',
            id,
            name
          }
        }
      });

      this.props.showToast({
        type: 'success',
        content: 'Church Name successfully updated!'
      });
    } catch (e) {
      updateResponse = e;
      this.props.showToast({
        type: 'error',
        content: 'There was an issue saving. Please try again later'
      });
    }

    return updateResponse;
  }

  render() {
    return (
      <AccountForm
        handleSubmit={this.handleSubmit}
        render={({ editable, handleMount }) => (
          <FormControl labelTop={true} className={accountStyles.inputContainer}>
            <Label htmlFor="churchName">Church Name</Label>
            <InputDynamic
              displayInput={editable}
              value={this.props.acctData.adminOf.name}
            >
              <Input
                defaultValue={this.props.acctData.adminOf.name}
                id="churchName"
                refFn={input => {
                  this.churchNameInput = input;
                }}
                mountFn={() => handleMount(this.churchNameInput)}
              />
            </InputDynamic>
          </FormControl>
        )}
      />
    );
  }
}

export default class ChurchNameFormWithData extends React.Component {
  render() {
    return (
      <ToastContext.Consumer>
        {({ showToast }) => (
          <Mutation mutation={UPDATE_CHURCH_NAME_MUTATION}>
            {updateChurchNameMutation => (
              <ChurchNameForm
                updateChurchNameMutation={updateChurchNameMutation}
                showToast={showToast}
                {...this.props}
              />
            )}
          </Mutation>
        )}
      </ToastContext.Consumer>
    );
  }
}
