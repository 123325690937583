import gql from 'graphql-tag';

export const GET_CREATE_BULLETIN_MODAL_STATE = gql`
  {
    createBulletinModal @client {
      isOpen
      replicate
      originalBulletinId
    }
  }
`;
