// This file provides a place to easily change certain parameters(e.g. API endpoint)

const env = process.env.REACT_APP_ENVIRONMENT || 'development';

const dev = env.toLowerCase() === 'development';
const staging = env.toLowerCase() === 'staging';
const prod = env.toLowerCase() === 'production';

const CONFIG = {
  STRIPE_KEY: prod
    ? 'pk_live_S6XWv8fwlNHuB4LyDgcNZ0dW'
    : 'pk_test_0Sqo830OpcuBsPktgm5ij21C',
  SIGNUP_ENABLED: dev || staging || prod,
  LOGIN_ENABLED: dev || staging || prod,
  TEMPLATES_ENABLED: dev,
  PAST_BULLETINS_ENABLED: dev || staging || prod,
  RESPONSES_ENABLED: dev,
  COPY_TO_PLANNED_ENABLED: dev,
  BULLETIN_VIEW_ENABLED: dev || staging || prod,
  BULLETIN_REPLICATE_ENABLED: dev,
  RESPONSE_CARDS_ENABLED: dev,
  INSERT_IMAGE_ENABLED: dev,
  APPEARANCE_ENABLED: dev || staging || prod
};

export default CONFIG;
