import { resolvers, typeDefs, defaults } from 'resolvers';
import ApolloClient from 'apollo-boost';

function createApolloClient(firebase) {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    request: async operation => {
      const token = await firebase.getIdToken();
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : null
        }
      });
    },
    clientState: {
      resolvers,
      typeDefs,
      defaults
    }
  });

  return client;
}

export default createApolloClient;
