import React from 'react';
import Tile from 'components/layouts/Tile';
import PlannedBulletinsHeader from './PlannedBulletinsHeader';
import PlannedBulletinsTable from './PlannedBulletinsTable';
import LoadMoreBulletins from 'components/bulletins/LoadMoreBulletins';
import ViewBulletinModal from 'components/bulletins/ViewBulletinModal';

class PlannedBulletins extends React.Component {
  render() {
    const { church, loading, selectedService, fetchMore } = this.props;
    return (
      <Tile>
        <ViewBulletinModal />
        <PlannedBulletinsHeader
          services={church.services}
          selectedServiceIndex={selectedService.index}
        />
        <PlannedBulletinsTable
          plannedBulletins={
            church.services[selectedService.index].plannedBulletins
          }
          selectedServiceIndex={selectedService.index}
        />
        <LoadMoreBulletins
          fetchMore={fetchMore}
          church={church}
          loading={loading}
          bulletinListName="plannedBulletins"
        />
      </Tile>
    );
  }
}

export default PlannedBulletins;
