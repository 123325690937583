import Autosave from 'components/common/Autosave';
import Loading from 'components/common/Loading';
import TileButton from 'components/common/TileButton';
import CONFIG from 'config';
import moment from 'moment-timezone';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Col, Row } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import FadeIn from 'react-fade-in';
import {
  UPDATE_BULLETIN_DATE_MUTATION,
  UPDATE_BULLETIN_MUTATION
} from 'services/graphql/mutations';
import AutoSavingMessage from './AutoSavingMessage';
import BulletinEditor from './BulletinEditorSlate';
import BulletinInfo from './BulletinInfo';
import styles from './EditBulletin.module.css';

/**
 * EditBulletin gets wrapped with Autosave. Each of the three components that edit info about the Bulletin
 * (title, date, body) will use data retrieved from the Bulletin query as their seed data. On any change,
 * EditBulletinWithAutosave will manage the data until Autosave executes a save. Each of the three Bulletin
 * info components will call the onAutoSaveFieldsChange function every time their data changes. This will tell
 * Autosave that changes were made that need to be saved, and update EditBulletinWithAutosave's data
 */
class EditBulletin extends React.Component {
  render() {
    const { title, body } = this.props.autoSaveFields;

    const {
      updatedAt,
      id,
      headerImage,
      date
    } = this.props.bulletinQuery.data.bulletin;

    return (
      <FadeIn>
        <div className={styles.root}>
          <AutoSavingMessage
            autosaving={this.props.autosaving}
            updatedAt={updatedAt}
          />
          <Row>
            <Col xs={3} className="leftConsole">
              <TileButton
                icon="leftArrow"
                label="Back"
                destination="/dashboard/bulletins"
              />
              <BulletinInfo
                date={date}
                title={title}
                onAutoSaveFieldsChange={this.props.onAutoSaveFieldsChange}
                restrictDateSelection={this.props.restrictDateSelection}
                onDateSelectionChange={this.props.onDateSelectionChange}
              />
            </Col>
            <Col xs={6}>
              <BulletinEditor
                body={body}
                headerImage={headerImage}
                onHeaderImageUpload={this._onHeaderImageUpload}
                refetch={this.props.bulletinQuery.refetch}
                onAutoSaveFieldsChange={this.props.onAutoSaveFieldsChange}
              />
            </Col>
            <Col xs={3}>
              <TileButton
                icon="save"
                label="Save bulletin"
                onClick={this.props.manualSave}
              />
              {CONFIG.RESPONSE_CARDS_ENABLED ? (
                <TileButton
                  icon="newIcon"
                  label="Response Cards"
                  destination={`/dashboard/bulletins/edit/${id}/response-card`}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </FadeIn>
    );
  }
}

export class EditBulletinWithAutosave extends React.Component {
  constructor(props) {
    super(props);

    this.restrictDateSelection = this.restrictDateSelection.bind(this);
    this.saveBulletin = this.saveBulletin.bind(this);
    this.onDateSelectionChange = this.onDateSelectionChange.bind(this);
  }

  restrictDateSelection(current) {
    const yesterday = moment()
      .tz('UTC')
      .subtract(1, 'day');
    const {
      day,
      plannedBulletins
    } = this.props.bulletinQuery.data.bulletin.service;
    const existingBulletin = plannedBulletins.find(bulletin => {
      console.log(
        moment(bulletin.date)
          .tz('UTC')
          .format('MM DD YYYY')
      );
      return (
        moment(bulletin.date)
          .tz('UTC')
          .format('MM DD YYYY') === current.format('MM DD YYYY')
      );
    });
    return (
      current.day() ===
        moment()
          .day(`${day}`)
          .day() &&
      current.isAfter(yesterday) &&
      !existingBulletin
    );
  }

  onDateSelectionChange = async calendarDate => {
    const { id } = this.props.bulletinQuery.data.bulletin;
    const date = moment(calendarDate._d).format('YYYY-MM-DD');
    try {
      await this.props.updateBulletinDateMutation({
        variables: { id, date },
        optimisticResponse: {
          __typename: 'Mutation',
          editBulletin: {
            __typename: 'Bulletin',
            id: id,
            date: calendarDate._d
          }
        }
      });
    } catch (e) {}
  };

  /**
   * Saves the bulletin. It will only save if the length of haveChanged (populated by Autosave when it executes
   * this function as a callback) is >= 1. It only sends data that needs to be updated
   * @param {Array} changes Object representing changed values
   */
  saveBulletin(changes) {
    const { id } = this.props.bulletinQuery.data.bulletin;
    this.props.updateBulletin({
      variables: { id, ...changes },
      optimisticResponse: {
        __typename: 'Mutation',
        editBulletin: {
          __typename: 'Bulletin',
          id: id,
          ...changes
        }
      }
    });
  }

  render() {
    let { title, body } = this.props.bulletinQuery.data.bulletin;

    return (
      <Autosave
        executeSave={this.saveBulletin}
        autoSaveFields={{
          title,
          body
        }}
        frequency={10000}
        render={({ handleChange, autosaving, autoSaveFields, manualSave }) => (
          <EditBulletin
            autosaving={autosaving}
            manualSave={manualSave}
            onAutoSaveFieldsChange={handleChange}
            restrictDateSelection={this.restrictDateSelection}
            autoSaveFields={autoSaveFields}
            onDateSelectionChange={this.onDateSelectionChange}
            {...this.props}
          />
        )}
      />
    );
  }
}

export default class EditBulletinWithDataAndAutosave extends React.Component {
  render() {
    const { queryResponse } = this.props;
    return (
      <Mutation mutation={UPDATE_BULLETIN_DATE_MUTATION}>
        {updateBulletinDateMutation => (
          <Mutation mutation={UPDATE_BULLETIN_MUTATION}>
            {updateBulletin => {
              if (queryResponse.loading) {
                return <Loading />;
              }

              return (
                <EditBulletinWithAutosave
                  bulletinQuery={queryResponse}
                  updateBulletin={updateBulletin}
                  updateBulletinDateMutation={updateBulletinDateMutation}
                />
              );
            }}
          </Mutation>
        )}
      </Mutation>
    );
  }
}
