import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const ModalFooter = props => {
  const { className } = props;
  return <div className={`${styles.footer} ${className}`}>{props.children}</div>
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired
};

ModalFooter.defaultProps = {
  className: ''
};

export default ModalFooter;