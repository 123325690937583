import gql from 'graphql-tag';

import AccountApi from './Account/AccountApi';

export const ACCOUNT_SETTINGS_GENERAL_PAGE_QUERY = gql`
  query ACCOUNT_SETTINGS_GENERAL_PAGE_QUERY {
    loggedInUser {
      id
      role
      adminOf {
        id
        name
        logo {
          id
          url
          urlSchema
        }
      }
    }
  }
`;

export const ACCOUNT_PAGE_QUERY = gql`
  query ACCOUNT_PAGE_QUERY {
    loggedInUser {
      id
      role
      adminOf {
        id
        name
        ...Account
        logo {
          id
          url
          urlSchema
        }
      }
    }
    activePlanTiers {
      ...PlanTierFormTiers
    }
  }
  ${AccountApi.fragments.church}
  ${AccountApi.fragments.activePlanTiers}
`;

export const getPageQuery = ({ location }) => {
  const { pathname } = location;
  let query = '';
  if (pathname.match('account-settings/account')) {
    query = ACCOUNT_PAGE_QUERY;
  } else if (
    pathname.match('account-settings/password') ||
    pathname.match('account-settings/appearance') ||
    pathname.match('account-settings/payment') ||
    pathname.match('account-settings/resources')
  ) {
    query = ACCOUNT_SETTINGS_GENERAL_PAGE_QUERY;
  }

  return query ? { query } : null;
};

export default {
  getPageQuery,
  queries: {
    ACCOUNT_SETTINGS_GENERAL_PAGE_QUERY,
    ACCOUNT_PAGE_QUERY
  }
};
