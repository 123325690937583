import React from 'react';

import { Button } from 'components/common/buttons';
import { IconDownload } from 'components/common/icons';
import styles from './Resources.module.css';

const ResourceRow = props => {
  const { name, type, path } = props;

  return (
    <div className={styles.resourceRow}>
      <p className={styles.resourceName}>{`${name} (.${type})`}</p>
      <Button
        btnStyle="secondary"
        btnSize="small"
        type="button"
        href={path}
        download={name}
        target="_blank"
      >
        <IconDownload />
        Download
      </Button>
    </div>
  );
};

export default ResourceRow;
