import React from 'react';
import moment from 'moment';

import styles from './auto-saving-message.module.css';

export default function AutoSavingMessage(props) {
  const { autosaving, updatedAt } = props;
  return (
    <p className={styles.root}>
      {autosaving
        ? 'Autosaving...'
        : `Bulletin last saved on ${moment(updatedAt).format(
            'M/D/YYYY'
          )} at ${moment(updatedAt).format('h:mm A')}`}
    </p>
  );
}
