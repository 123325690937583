import { BLOCK_TAGS } from '../../HtmlSerializer';
import { hasBlockType } from '../SlateUtils';

export const handleBlockChange = (event, editor, type) => {
  event.preventDefault();
  const { value } = editor;

  // Handle everything but list buttons.
  if (type !== BLOCK_TAGS.ul && type !== BLOCK_TAGS.ol) {
    const isActive = hasBlockType(type, editor);
    const isList = hasBlockType(BLOCK_TAGS.li, editor);

    if (isList) {
      editor
        .setBlocks(isActive ? BLOCK_TAGS.p : type)
        .unwrapBlock(BLOCK_TAGS.ul)
        .unwrapBlock(BLOCK_TAGS.ol);
    } else {
      editor.setBlocks(isActive ? BLOCK_TAGS.p : type);
    }
  } else {
    // Handle the extra wrapping required for list buttons.
    const isList = hasBlockType(BLOCK_TAGS.li, editor);
    const isType = value.blocks.some(block => {
      return !!value.document.getClosest(
        block.key,
        parent => parent.type === type
      );
    });

    if (isList && isType) {
      editor
        .setBlocks(BLOCK_TAGS.p)
        .unwrapBlock(BLOCK_TAGS.ul)
        .unwrapBlock(BLOCK_TAGS.ol);
    } else if (isList) {
      editor
        .unwrapBlock(type === BLOCK_TAGS.ul ? BLOCK_TAGS.ol : BLOCK_TAGS.ul)
        .wrapBlock(type);
    } else {
      editor.setBlocks(BLOCK_TAGS.li).wrapBlock(type);
    }
  }
};

export const handleMarkChange = (event, editor, type) => {
  event.preventDefault();
  editor.toggleMark(type);
};
