import gql from 'graphql-tag';

import PastBulletinsTableApi from './PastBulletinsTable/PastBulletinsTableApi';

export const fragments = {
  service: gql`
    fragment PastBulletinsService on Service {
      ...PastBulletinsTableService @include(if: $shouldIncludePastBulletins)
    }
    ${PastBulletinsTableApi.fragments.service}
  `
};

export default {
  fragments
};
