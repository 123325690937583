import React from 'react';

import { AddLinkPluginContext } from '../AddLinkPlugin';
import { EditorTooltip } from '../../components/EditorTooltip';

import EditLinkForm from './EditLinkForm';
import LinkActions from './LinkActions';
import styles from '../AddLinkPlugin.module.css';

export class LinkTooltip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      range: this.getRange(),
      linkData: this.props.addLinkPlugin.getCurrentLinkData()
    };

    this.getRange = this.getRange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.closeOnEscKey = this.closeOnEscKey.bind(this);
    this.closeOnClick = this.closeOnClick.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closeOnEscKey);
    document.removeEventListener('mousedown', this.closeOnClick);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.closeOnEscKey);
    document.addEventListener('mousedown', this.closeOnClick);
  }

  closeOnEscKey(e) {
    if (e.key === 'Escape') {
      this.props.addLinkPlugin.onLinkFormBlur(e);
    }
  }

  closeOnClick(e) {
    if (!this.tooltip.contains(e.target)) {
      this.props.addLinkPlugin.onLinkFormBlur(e, false);
    }
  }

  getRange() {
    const coords = window
      .getSelection()
      .getRangeAt(0)
      .getBoundingClientRect();
    return {
      coords,
      scrollY: window.scrollY
    };
  }

  getAdjustedCoords() {
    const currentScrollY = window.scrollY;
    const { coords, scrollY } = this.state.range;
    return {
      bottom: coords.bottom,
      height: coords.height,
      left: coords.left,
      right: coords.right,
      top: coords.top - (currentScrollY - scrollY),
      width: coords.width,
      x: coords.x,
      y: coords.y
    };
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({
      linkData: {
        ...this.state.linkData,
        [name]: value
      }
    });
  }

  render() {
    const { linkData } = this.state;
    return (
      <EditorTooltip
        className={styles.root}
        triggerRect={this.getAdjustedCoords()}
      >
        <div ref={tooltip => (this.tooltip = tooltip)}>
          {this.props.addLinkPlugin.showEditLinkView ? (
            <EditLinkForm
              linkData={linkData}
              onChange={this.onChange}
              addLinkPlugin={this.props.addLinkPlugin}
            />
          ) : (
            <LinkActions
              linkData={linkData}
              addLinkPlugin={this.props.addLinkPlugin}
            />
          )}
        </div>
      </EditorTooltip>
    );
  }
}

export default class LinkTooltipWithProvider extends React.Component {
  render() {
    return (
      <AddLinkPluginContext.Consumer>
        {({ addLinkPlugin }) => {
          return addLinkPlugin.linkTooltipActive ? (
            <LinkTooltip {...this.props} addLinkPlugin={addLinkPlugin} />
          ) : null;
        }}
      </AddLinkPluginContext.Consumer>
    );
  }
}
