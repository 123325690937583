import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter, matchPath } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import './Breadcrumbs.css';

class Breadcrumbs extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.location !== nextProps.location;
  }

  render() {
    const breadcrumbItems = this.props.location.pathname
      .replace(/\/$/, '')
      .split('/')
      .map(item => {
        switch (item) {
          case 'dashboard':
            return (
              <Breadcrumb.Item active key="Dashboard">
                Dashboard
              </Breadcrumb.Item>
            );
          case 'bulletins':
            return (
              <LinkContainer
                key="LinkContainerPlannedBulletins"
                to="/dashboard/bulletins"
              >
                <Breadcrumb.Item key="PlannedBulletins">
                  Planned Bulletins
                </Breadcrumb.Item>
              </LinkContainer>
            );
          case 'pastBulletins':
            return (
              <LinkContainer
                key="LinkContainerPlannedBulletins"
                to="/dashboard/pastBulletins"
              >
                <Breadcrumb.Item key="PastBulletins">
                  Past Bulletins
                </Breadcrumb.Item>
              </LinkContainer>
            );
          case 'templates':
            return (
              <LinkContainer
                key="LinkContainerTemplates"
                to="/dashboard/templates"
              >
                <Breadcrumb.Item key="Templates">Templates</Breadcrumb.Item>
              </LinkContainer>
            );
          case 'edit':
            const { title, id } = this.props;
            return (
              <LinkContainer
                key={`LinkContainer${id}`}
                to={`/dashboard/bulletins/edit/${id}`}
              >
                <Breadcrumb.Item key={id}>{title}</Breadcrumb.Item>
              </LinkContainer>
            );
          case 'response-card':
            return (
              <Breadcrumb.Item key="ResponseCard">
                Response Card
              </Breadcrumb.Item>
            );
          default:
            return null;
        }
      });
    return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
  }
}

class BreadcrumbsWithData extends Component {
  render() {
    const match = matchPath(this.props.location.pathname, {
      path: '/dashboard/bulletins/edit/:id'
    });
    if (match) {
      return (
        <Query
          query={gql`
            query bulletinTitle($id: ID!) {
              bulletin(id: $id) {
                id
                title
              }
            }
          `}
          variables={{ id: match.params.id }}
          skip={!match}
        >
          {({ loading, data }) => {
            if (loading) return null;

            const { title, id } = data.bulletin;

            return <Breadcrumbs {...this.props} id={id} title={title} />;
          }}
        </Query>
      );
    } else {
      return <Breadcrumbs {...this.props} />;
    }
  }
}

export default withRouter(BreadcrumbsWithData);
