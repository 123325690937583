import React from 'react';

import ErrorMessage from '../../ErrorMessage';
import styles from './input.module.css';

const html5Validations = ['required', 'min', 'max', 'pattern', 'maxlength'];

class Input extends React.Component {
  componentDidMount() {
    if (this.props.mountFn) {
      this.props.mountFn();
    }
  }

  render() {
    let {
      className,
      handleChange,
      mountFn,
      refFn,
      size,
      formField,
      inputStyle,
      ...otherProps
    } = this.props;

    if (formField) {
      // Allow for uncontrolled inputs
      let valueProp = this.props.hasOwnProperty('defaultValue')
        ? { defaultValue: this.props.defaultValue }
        : { value: formField.value };

      // Apply the html5 validation fields to the input
      let validations = formField.validations.reduce((ret, validation) => {
        if (
          html5Validations.find(
            html5Validation => html5Validation === validation.type
          )
        ) {
          ret[validation.type] = validation.value;
        }
        return ret;
      }, {});

      const validationClass = formField.valid === false ? styles.invalid : '';
      return (
        <div
          className={`${styles[size]} ${validationClass}`}
          aria-live="assertive"
          aria-relevant="additions"
        >
          <input
            className={`${styles.input} ${styles[inputStyle]} ${className}`}
            onChange={handleChange}
            ref={refFn}
            aria-invalid={formField.valid === false}
            {...validations}
            {...valueProp}
            {...otherProps}
            // put the aria-describedby attribute at the end because it's the easiest way to access the property
            // and append any additional aria-describedby values
            aria-describedby={`${otherProps.id}-error ${
              otherProps['aria-describedby']
                ? ' ' + otherProps['aria-describedby']
                : ''
            }`}
          />
          {formField.valid === false && (
            <ErrorMessage
              id={`${otherProps.id}-error`}
              message={formField.errorMessage}
            />
          )}
        </div>
      );
    } else {
      return (
        <input
          className={`${styles.input} ${styles[inputStyle]} ${className} ${styles[size]}`}
          onChange={handleChange}
          ref={refFn}
          {...otherProps}
        />
      );
    }
  }
}

Input.defaultProps = {
  type: 'text',
  className: '',
  size: '',
  'aria-describedby': '',
  inputStyle: 'bordered'
};

export default Input;
