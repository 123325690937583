import React from 'react';
import { compose } from 'react-apollo';

import AccountForm from '../AccountForm';
import Label from 'components/common/form/Label';
import FormControl from 'components/common/form/FormControl';
import InputDynamic from 'components/common/form/inputs/dynamic/InputDynamic';
import Input from 'components/common/form/inputs/Input';

import accountStyles from '../../account.module.css';
import { withFirebase } from 'services/firebase/context';
import { withToastMessages } from 'components/common/ToastContainer';

export class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = { email: '' };

  componentDidMount() {
    const { firebase } = this.props;
    this.listener = firebase.auth.onIdTokenChanged(user => {
      if (user) {
        this.setState({ email: user.email });
      } else {
        this.setState({ email: '' });
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  async handleSubmit() {
    const { requestReauth, firebase, showToast } = this.props;
    let email = this.emailInput.value;
    let updateResponse;

    try {
      updateResponse = await firebase.updateEmail(email);

      this.setState({ email });

      showToast({
        type: 'success',
        content: 'Church Email successfully updated!'
      });
    } catch (e) {
      if (e.code === 'auth/requires-recent-login') {
        requestReauth();
      } else {
        showToast({
          type: 'error',
          content: 'There was an issue saving. Please try again later'
        });
        updateResponse = e;
      }
    }

    return updateResponse;
  }

  render() {
    const { email } = this.state;
    return (
      <AccountForm
        handleSubmit={this.handleSubmit}
        render={({ editable, handleMount }) => (
          <FormControl labelTop={true} className={accountStyles.inputContainer}>
            <Label htmlFor="email">Email</Label>
            <InputDynamic displayInput={editable} value={email}>
              <Input
                defaultValue={email}
                id="email"
                refFn={input => {
                  this.emailInput = input;
                }}
                mountFn={() => handleMount(this.emailInput)}
              />
            </InputDynamic>
          </FormControl>
        )}
      />
    );
  }
}

export default compose(withFirebase, withToastMessages)(EmailForm);
