import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Mutation } from 'react-apollo';
import { UPDATE_SELECTED_SERVICE } from 'services/graphql/local-mutations';
import styles from '../ProfileAndServices.module.css';

class ServiceSelect extends Component {
  componentDidMount() {
    const {
      services,
      changeSelectedService,
      selectedServiceIndex
    } = this.props;
    const { id } = services[selectedServiceIndex];
    changeSelectedService({ variables: { index: selectedServiceIndex, id } });
  }

  render() {
    const {
      services,
      selectedServiceIndex,
      changeSelectedService
    } = this.props;
    return (
      <div className={styles.serviceSelect}>
        <DropdownButton
          id="serviceSelect"
          title={services[selectedServiceIndex].name}
          className={styles.serviceDropdown}
          onSelect={e => {
            const { index, id } = e;
            changeSelectedService({ variables: { index, id } });
          }}
        >
          {services.map((service, index) => (
            <MenuItem eventKey={{ index, id: service.id }} key={service.id}>
              {service.name}
            </MenuItem>
          ))}
        </DropdownButton>
      </div>
    );
  }
}

export default class ServiceSelectWithState extends Component {
  render() {
    const { services, selectedServiceIndex } = this.props;
    return (
      <Mutation mutation={UPDATE_SELECTED_SERVICE}>
        {changeSelectedService => (
          <ServiceSelect
            services={services}
            selectedServiceIndex={selectedServiceIndex}
            changeSelectedService={changeSelectedService}
          />
        )}
      </Mutation>
    );
  }
}
