import React from 'react';

import withTooltip from 'components/common/Tooltip';

import styles from './LinkTypeSelect.module.css';

const LinkTypeSelectOption = props => {
  const {
    valueId,
    selected,
    focused,
    handleClick,
    handleMouseEnter,
    optionConfig,
    refFn,
    ...rest
  } = props;
  const { Icon, colorAttr } = optionConfig;

  return (
    <li
      id={valueId}
      key={valueId}
      aria-selected={selected ? true : undefined}
      role="option"
      aria-label={optionConfig.helperText}
      className={`${styles.li} ${focused ? styles.focused : ''}`}
      onClick={handleClick}
      ref={refFn}
      {...rest}
    >
      <Icon {...{ [colorAttr]: '#6b8089' }} />
    </li>
  );
};

export default withTooltip(LinkTypeSelectOption, {
  showOnHover: true,
  placement: 'top',
  small: 'true',
  theme: 'dark'
});
