import gql from 'graphql-tag';

export const CREATE_CHURCH_WITH_ADMIN = gql`
  mutation CreateChurchWithAdmin(
    $name: String!
    $stripeToken: String!
    $planTierId: ID!
    $email: String!
    $password: String!
  ) {
    createChurchWithAdmin(
      name: $name
      stripeToken: $stripeToken
      planTierID: $planTierId
      email: $email
      password: $password
    ) {
      token
      church {
        id
        name
      }
    }
  }
`;

export const CREATE_BULLETIN_MUTATION = gql`
  mutation CreateBulletinMutation(
    $serviceId: ID!
    $title: String!
    $date: Date!
  ) {
    createBulletin(serviceID: $serviceId, title: $title, date: $date) {
      id
      title
      date
    }
  }
`;

export const UPDATE_BULLETIN_MUTATION = gql`
  mutation updateBulletinMutation(
    $id: ID!
    $body: Json
    $bodyHtml: String
    $title: String
  ) {
    editBulletin(id: $id, body: $body, bodyHtml: $bodyHtml, title: $title) {
      id
      body
      bodyHtml
      updatedAt
      title
    }
  }
`;

export const UPDATE_BULLETIN_DATE_MUTATION = gql`
  mutation updateBulletinInfoMutation($id: ID!, $date: Date!) {
    editBulletin(id: $id, date: $date) {
      id
      date
    }
  }
`;

export const UPDATE_BULLETIN_BODY_MUTATION = gql`
  mutation updateBulletinBodyMutation($id: ID!, $body: Json!) {
    editBulletin(id: $id, body: $body) {
      id
      body
      updatedAt
    }
  }
`;

export const UPDATE_BULLETIN_HEADER_IMAGE = gql`
  mutation updateBulletinHeaderImage(
    $id: ID!
    $fileName: String!
    $url: String!
    $urlSchema: FileUrlSchema!
  ) {
    attachBulletinHeaderImage(
      id: $id
      name: $fileName
      url: $url
      urlSchema: $urlSchema
    ) {
      id
      updatedAt
      headerImage {
        id
        url
        urlSchema
      }
    }
  }
`;

export const DELETE_BULLETIN_HEADER_IMAGE = gql`
  mutation deleteBulletinHeaderImage($id: ID!) {
    removeBulletinHeaderImage(id: $id) {
      id
      headerImage {
        id
        url
        urlSchema
        updatedAt
      }
    }
  }
`;

export const DELETE_BULLETIN_MUTATION = gql`
  mutation deleteBulletinMutation($id: ID!) {
    deleteBulletin(id: $id) {
      id
    }
  }
`;

export const UPDATE_SERVICE_MUTATION = gql`
  mutation updateServiceMutation(
    $id: ID!
    $name: String!
    $day: Day!
    $time: DateTime!
  ) {
    editService(id: $id, name: $name, day: $day, time: $time) {
      id
      name
      day
      time
    }
  }
`;

export const UPDATE_CHURCH_NAME_MUTATION = gql`
  mutation updateChurchNameMutation($id: ID!, $name: String!) {
    updateChurchName(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_CHURCH_WEBSITE_MUTATION = gql`
  mutation updateChurchWebsiteMutation($id: ID!, $website: String!) {
    updateChurchWebsite(id: $id, website: $website) {
      id
      website
    }
  }
`;

export const UPDATE_PLAN_TIER_MUTATION = gql`
  mutation updatePlanTierMutation($id: ID!, $planTierId: ID!) {
    updateChurchPlanTier(id: $id, planTierID: $planTierId) {
      id
      planTier {
        id
        displayName
        pricePerMonth
        congregantsMin
        congregantsMax
      }
    }
  }
`;

export const CREATE_ADDRESS_MUTATION = gql`
  mutation createAddressMutation(
    $churchId: ID!
    $city: String!
    $state: String!
    $zipCode: String!
    $street1: String!
  ) {
    addChurchLocation(
      city: $city
      state: $state
      zip: $zipCode
      street1: $street1
      churchID: $churchId
    ) {
      id
      street1
      city
      state
      zip
    }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation updateAddressMutation(
    $id: ID!
    $city: String!
    $state: String!
    $zipCode: String!
    $street1: String!
  ) {
    updateChurchLocation(
      id: $id
      city: $city
      state: $state
      zip: $zipCode
      street1: $street1
    ) {
      id
      street1
      city
      state
      zip
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePasswordMutation(
    $newPassword: String!
    $currentPassword: String!
  ) {
    changePassword(newPassword: $newPassword, oldPassword: $currentPassword)
  }
`;

export const UPDATE_CHURCH_PAYMENT_INFO = gql`
  mutation updateChurchPaymentInfo($id: ID!, $stripeToken: String!) {
    updateChurchPaymentInfo(id: $id, stripeToken: $stripeToken) {
      id
      last4
      name
      brand
      expiryMonth
      expiryYear
    }
  }
`;

export const REPLICATE_BULLETIN = gql`
  mutation replicateBulletin($id: ID!, $title: String!, $date: Date!) {
    duplicateBulletin(id: $id, title: $title, date: $date) {
      id
      title
      date
    }
  }
`;

export const UPDATE_CHURCH_LOGO = gql`
  mutation updateLogo(
    $id: ID!
    $fileName: String!
    $url: String!
    $urlSchema: FileUrlSchema!
  ) {
    updateChurchLogo(
      id: $id
      name: $fileName
      url: $url
      urlSchema: $urlSchema
    ) {
      id
      logo {
        id
        url
        urlSchema
      }
    }
  }
`;

export const REMOVE_CHURCH_LOGO = gql`
  mutation removeLogo($id: ID!) {
    removeChurchLogo(id: $id) {
      id
      logo {
        id
        url
        urlSchema
      }
    }
  }
`;

export const ADD_BULLETIN_VIEW = gql`
  mutation addBulletinView($bulletinId: ID!, $deviceUuid: String!) {
    addBulletinView(bulletinID: $bulletinId, deviceUuid: $deviceUuid)
  }
`;
