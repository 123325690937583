import React from 'react';
import { TableCell, TableHeader } from '../BulletinsTable';

import styles from './BulletinTableRow.module.css';

export const DateTableCell = props => {
  return <TableCell className={styles.column1}>{props.children}</TableCell>
};

export const TitleTableCell = props => {
  return <TableCell textColor="primary">{props.children}</TableCell>
}

export const DateTableHeader = props => {
  return <TableHeader className={styles.column1}>{props.children}</TableHeader>
};

export const ActionsTableHeader = props => {
  return <TableHeader className={styles.column3}>{props.children}</TableHeader>
};