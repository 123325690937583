import {
  Table,
  TableBody,
  TableHead,
  TableHeader
} from 'components/bulletins/BulletinsTable';

import {
  ActionsTableHeader,
  DateTableHeader
} from 'components/bulletins/BulletinTableRow';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import PlannedBulletinRow from './PlannedBulletinRow';

export default class PlannedBulletinsTable extends Component {
  render() {
    const { plannedBulletins, selectedServiceIndex } = this.props;
    if (!plannedBulletins || plannedBulletins.length === 0) return null;
    return (
      <Table>
        <TableHead>
          <DateTableHeader>Date</DateTableHeader>
          <TableHeader>Title</TableHeader>
          <ActionsTableHeader />
        </TableHead>
        <TableBody>
          {[]
            .concat(plannedBulletins)
            .sort(function(a, b) {
              return moment(a.date) - moment(b.date);
            })
            .map(bulletin => (
              <PlannedBulletinRow
                date={moment(bulletin.date).format('MMM D, YYYY')}
                name={bulletin.title}
                key={bulletin.id}
                id={bulletin.id}
                selectedServiceIndex={selectedServiceIndex}
              />
            ))}
        </TableBody>
      </Table>
    );
  }
}
