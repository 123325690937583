import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Mutation } from 'react-apollo';
import { Value } from 'slate';
import { Editor } from 'slate-react';
import { useSpring, animated as a } from 'react-spring';

import Loading from 'components/common/Loading';
import { withFirebase } from 'services/firebase';
import { ADD_BULLETIN_VIEW } from 'services/graphql/mutations';
import { SlatePluginsReadonly } from 'scenes/Console/EditBulletin/BulletinEditorSlate/SlatePlugins';

import BulletinHeader from './BulletinHeader';
import Error from './Error';
import NoLiveBulletin from './NoLiveBulletin';

import styles from './BulletinWebView.module.css';
import bulletinStyles from 'css/bulletin.module.css';

export const BulletinWebView = ({ addBulletinView, bulletin, firebase }) => {
  const headerRef = useRef(null);
  const [headerUrl, setHeaderUrl] = useState(null);

  // Logic for handling resizing of the window
  // When the user resizes the window, we want to make sure the padding-top
  // value of the <div> containing Editor updates to reflect the new size of
  // the header image. Since refs (used for image) don't cause re-renders, we
  // alias a state updating function as "resize", and call that with the updated
  // offsetHeight value of the image. If the values are different, a state update
  // will trigger, which will re-render BulletinWebView and pick up on the new
  // offsetHeight in our render method.
  const resize = useState(false)[1];
  const onResize = useCallback(() => {
    resize(headerRef.current.offsetHeight);
  }, [resize]);

  // Have the bulletin view hidden until the header image renders
  const [viewProps, setViewProps] = useSpring(() => ({ opacity: 0 }));

  // Reveals the bulletin to the user. This should be only either be called when the
  // headerImage loads or when it's determined there is no headerImage. This is done
  // to avoid jumpiness when the headerImage loads.
  const revealBulletin = useCallback(() => {
    resize();
    setViewProps({ opacity: 1 });
  }, [setViewProps, resize]);

  const logView = useCallback(() => {
    addBulletinView({
      variables: {
        bulletinId: bulletin.id,
        deviceUuid: firebase.getCurrentUser().uid
      }
    });
  }, [addBulletinView, bulletin.id, firebase]);

  // Effect that only runs after the first render.
  useEffect(() => {
    if (firebase.getCurrentUser().isAnonymous) {
      logView();
    }
    async function getHeaderUrl() {
      const headerUrl = await firebase.calculateImageUrl(bulletin.headerImage);
      setHeaderUrl(headerUrl);
    }

    // If there's a header image, get the url for the image. Otherwise, proceed
    // with revealing the bulletin
    if (bulletin.headerImage) {
      getHeaderUrl();
    } else {
      revealBulletin();
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [bulletin.headerImage, firebase, logView, onResize, revealBulletin]);

  return (
    <a.div className={styles.root} style={viewProps}>
      <BulletinHeader
        bulletin={bulletin}
        headerRef={headerRef}
        headerUrl={headerUrl}
        revealBulletin={revealBulletin}
      />
      <div
        className={styles.bulletinContainer}
        style={{
          paddingTop: headerRef.current ? headerRef.current.offsetHeight : 0
        }}
      >
        <Editor
          readOnly={true}
          plugins={SlatePluginsReadonly}
          value={Value.fromJSON(bulletin.body)}
          className={`${bulletinStyles.bulletin} ${styles.bulletin}`}
          onChange={() => {}}
        />
      </div>
    </a.div>
  );
};

export const BulletinWebViewWithData = ({ firebase, queryResponse }) => {
  const [authStatus, setAuthStatus] = useState({
    attempted: false,
    signedIn: false
  });

  useEffect(() => {
    // No other page needs a white background on the body. So, the global
    // body tag styling has been left and an ad-hoc style is added just
    // for the bulletin web view
    document.body.classList.add(styles.body);
    async function signIn() {
      try {
        if (!firebase.getCurrentUser()) {
          await firebase.auth.signInAnonymously();
        }
        setAuthStatus({ attempted: true, signedIn: true });
      } catch (e) {
        setAuthStatus({ attempted: true, signedIn: false });
      }
    }
    signIn();

    return () => {
      // Remove the bulletin web view body class name
      document.body.classList.remove(styles.body);
    };
  }, [firebase]);

  // checks based off firebase status
  if (!authStatus.signedIn && authStatus.attempted) return <Error />;
  if (!authStatus.signedIn)
    return <Loading alt="Loading this week's bulletin..." />;

  // checks based off bulletin query status
  const { data, loading, error } = queryResponse;
  if (loading) return <Loading alt="Loading this week's bulletin..." />;
  if (error) return <Error />;
  if (!data.liveBulletinForService) return <NoLiveBulletin />;

  return (
    <Mutation mutation={ADD_BULLETIN_VIEW}>
      {addBulletinView => (
        <BulletinWebView
          addBulletinView={addBulletinView}
          bulletin={data.liveBulletinForService}
          firebase={firebase}
        />
      )}
    </Mutation>
  );
};

export default withFirebase(BulletinWebViewWithData);
